import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import { Col, Row, Breadcrumb, Card, Container, Table } from "react-bootstrap";
import TextEditor from "../../../components/TextEditor";
import Icon from "../../../components/Icon";
import ApplyModal from "./ApplyModal";
import { CAREER } from "../../../routes/routerUrl";
import moment from "moment";
import "moment/locale/vi";
moment.locale("vi");

const CareerDetail = () => {
  let { slug } = useParams();

  const [career, setCareer] = useState({});
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [showModal, setshowModal] = useState(false);

  useEffect(() => {
    getDetailPost(slug);
  }, [slug]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDetailPost = async (postSlug) => {
    await axios
      .get(`/post/list`, {
        params: {
          postSlug: postSlug,
        },
      })
      .then(async (res) => {
        if (res.data.errCode === 0) {
          setCareer(res.data.post);
          getAllPosts(res.data.post.categoryId, res.data.post.id);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllPosts = async (categoryId, postId) => {
    await axios
      .get(`/post/list`, {
        params: {
          cateId: categoryId,
          limit: 11,
        },
      })
      .then((res) => {
        if (res.data.errCode === 0) {
          setRelatedPosts(res.data.posts.filter((post) => post.id !== postId));
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCloseModal = () => setshowModal(false);
  const handleShowModal = () => setshowModal(true);
  return (
    <>
      <ApplyModal show={showModal} handleClose={handleCloseModal} />
      <Container className="container-wrapper my-5">
        <Breadcrumb
          style={{
            backgroundColor: "var(--mute-bg-color)",
          }}
          className="p-3 pb-1 mb-2"
        >
          <Breadcrumb.Item active>Tuyển dụng</Breadcrumb.Item>
        </Breadcrumb>
        <Row className="mb-5">
          <Col xs="12" sm="12" md="8">
            <h2>{career?.postTitleVi}</h2>
            <Card
              style={{
                borderRadius: 0,
                borderStyle: "dotted",
                backgroundColor: "var(--sencondary-color)",
              }}
              key={career.id}
              className="p-2 mb-3"
            >
              <div className="text-black d-flex justify-content-between">
                <div
                  className="me-3  white-text"
                  style={{ alignSelf: "center" }}
                >
                  {"> Hết hạn: "}
                  {moment(Date.parse(career.postDescVi)).format("DD/MM/YYYY")}
                </div>
                <Link
                  className="button white-text uppercase"
                  style={{
                    border: " 1px solid",
                    padding: "6px",
                    margin: "3px",
                  }}
                  onClick={handleShowModal}
                >
                  {"Ứng tuyển ngay >"}
                </Link>
              </div>
            </Card>
            <div className="my-5">
              {career.postContentVi ? (
                <TextEditor
                  disable
                  hideToolbar={true}
                  showToolbar={false}
                  defaultValue={career.postContentVi}
                  onChange={() => {}}
                />
              ) : (
                ""
              )}
              <Link
                className="button white-text uppercase d-flex justify-content-center primary"
                style={{
                  fontSize: "120%",
                }}
                onClick={handleShowModal}
              >
                <Icon
                  icon="plus"
                  className="me-3"
                  style={{ alignSelf: "center" }}
                />
                Nhấn để ứng tuyển ngay
              </Link>
            </div>
          </Col>
          <Col xs="12" sm="12" md="4" className="pt-5">
            <div
              style={{
                position: "sticky",
                top: "var(--nav-height)",
              }}
            >
              <div className="fw-bold mb-3">Tin tuyển dụng khác</div>
              <div>
                <Table striped bordered>
                  <tbody>
                    {relatedPosts.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>
                            <Link
                              to={`/${CAREER}/${item.postSlug}`}
                              className="text-black"
                            >
                              {item.postTitleVi}
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
        </Row>

        {/* <Row>
        <h3 className="mb-3">Tin tuyển dụng khác</h3>
        {isLoading ? (
          <Loading />
        ) : relatedPosts?.length > 0 ? (
          relatedPosts?.map((post) => {
            return (
              <Col key={post.id} xs="12" sm="6" md="4" className="mb-5 mb-md-4">
                <Link to={`/${NEW}/${post.postSlug}/${post.postSlug}`}>
                  <div
                    style={{
                      aspectRatio: "16/9",
                      width: "100%",
                    }}
                  >
                    <div
                      key={post.id}
                      style={{
                        width: "100%",
                        height: "10rem",
                        backgroundSize: "cover",
                        backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}${post.imgURL})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                      className="youtube-parent d-flex justify-content-center align-items-center"
                    />
                  </div>
                </Link>
                <div className="fw-bold my-2">
                  <Link
                    to={`/${NEW}/${post.postSlug}/${post.postSlug}`}
                    className="text-black"
                  >
                    {post.postTitleVi}
                  </Link>
                </div>
              </Col>
            );
          })
        ) : (
          <NoData />
        )}
      </Row> */}
      </Container>
    </>
  );
};

export default CareerDetail;
