import axios from "axios";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const CallbackSocialite = () => {
  let { provider } = useParams();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");
    const scope = queryParams.get("scope");
    const authUser = queryParams.get("authuser");
    const prompt = queryParams.get("prompt");
    const callback = async () => {
      await axios
        .get(
          `/callback/${provider}?code=${code}&scope=${scope}&authuser=${authUser}&prompt=${prompt}`
        )
        .then((response) => {
          toast.success("Đăng nhập thành công");
          localStorage.setItem(
            "user_access_token",
            response.data.data.accessToken
          );
          window.location.href = "/";
        })
        .catch(() => navigate("/"));
    };

    callback();
  }, [provider]);
  return null;
};

export default CallbackSocialite;
