import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useEffect, useRef } from "react";
import TopBanner from "../../../assets/image/top-banner.jpg";
import RecruitmentProcess from "../../../assets/image/mau-quy-trinh-tuyen-dung-nhan-su.png";
import { Link } from "react-router-dom";

const CareerWrapper = ({ children }) => {
  useEffect(() => {
    document.title = "Tin tức";
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const chonviecRef = useRef(null);
  const chinhsachRef = useRef(null);
  const quytrinhRef = useRef(null);

  const scrollToChonviec = () =>
    chonviecRef.current.scrollIntoView({ behavior: "smooth" });
  const scrollToChinhsach = () =>
    chinhsachRef.current.scrollIntoView({ behavior: "smooth" });
  const scrollToQuytrinh = () =>
    quytrinhRef.current.scrollIntoView({ behavior: "smooth" });

  return (
    <>
      {/* Header */}
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "var(--top-banner-height)",
          backgroundSize: "cover",
          backgroundImage: `url(${TopBanner})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <p className="fill banner-fill d-flex justify-content-center">
          <div className="white-text " style={{ textAlign: "center" }}>
            <h2 className="mb-3 mt-5">
              <strong>TUYỂN DỤNG MỸ KAT</strong>
            </h2>
            <p className="mb-5">
              Mỹ Kat xây dựng môi trường làm việc chuyên nghiệp, minh bạch chế
              độ phúc lợi, đãi ngộ nhân tài.<br></br> Nếu bạn sẵn sàng, chào
              mừng bạn đến với Mỹ Kat ! Chúng tôi cần bạn!
            </p>
            <Row>
              <Col md="4" className="mb-3">
                <Link
                  onClick={scrollToChonviec}
                  class="button primary lowercase"
                >
                  <span>Chọn việc ngay</span>
                  <i class="icon-angle-right" aria-hidden="true"></i>
                </Link>
              </Col>
              <Col md="3" className="mb-3">
                <Link
                  onClick={scrollToChinhsach}
                  class="button white is-outline lowercase"
                >
                  <span>Chính sách</span>
                  <i class="icon-angle-right" aria-hidden="true"></i>
                </Link>
              </Col>
              <Col md="5" className="mb-3">
                <Link
                  onClick={scrollToQuytrinh}
                  class="button white is-outline lowercase"
                >
                  <span>Quy trình tuyển dụng</span>
                  <i class="icon-angle-right" aria-hidden="true"></i>
                </Link>
              </Col>
            </Row>
          </div>
        </p>
      </div>
      <Container className="container-wrapper my-5">
        {/* Tin tuyen dung */}
        <Row ref={chonviecRef} style={{ scrollMargin: "var(--nav-height)" }}>
          <Col xs="12" sm="12" md="12">
            {children}
          </Col>
        </Row>
        {/* Chinh sach */}
        <Row
          style={{
            height: "500px",
            alignContent: "center",
            scrollMargin: "var(--nav-height)",
          }}
          ref={chinhsachRef}
        >
          <Col xs="12" sm="12" md="12">
            <h4>CHÍNH SÁCH CHO NGƯỜI LAO ĐỘNG</h4>
            <p>
              Chúng tôi rất chú trọng đến chính sách đãi ngộ, quan tâm đến đời
              sống của cán bộ nhân viên với phương châm tạo cho cán bộ nhân viên
              có một cuộc sống “đầy đủ về vật chất và phong phú về tinh thần”.
            </p>
            <Tabs
              defaultActiveKey="treatment"
              style={{ borderColor: "var(--sencondary-color)" }}
            >
              <Tab
                tabClassName="text-black"
                eventKey="treatment"
                title={<div className="fw-bold">Hệ thống đãi ngộ</div>}
              >
                <ul className="mb-3">
                  <li class="bullet-arrow">Mức lương cạnh tranh;</li>
                  <li class="bullet-arrow">Thưởng lương tháng thứ 13;</li>
                  <li class="bullet-arrow">
                    Phụ cấp: gồm hỗ trợ đi lại, cước điện thoại di động, kiêm
                    nhiệm;
                  </li>
                  <li class="bullet-arrow">
                    Chế độ đặc biệt đối với cán bộ làm việc công trình xa: bao
                    gồm hỗ trợ ăn, ở, nghỉ phép định kỳ, cước điện thoại di
                    động, kiêm nhiệm;
                  </li>
                  <li class="bullet-arrow">
                    Thưởng theo hiệu quả kinh doanh và theo thành tích thực hiện
                    dự án/công việc;
                  </li>
                  <li class="bullet-arrow">
                    Khám sức khỏe định kỳ, chế độ nghỉ mát hàng năm, quà tặng lễ
                    tết sinh nhật, ốm đau..
                  </li>
                </ul>
              </Tab>
              <Tab
                tabClassName="text-black"
                eventKey="training"
                title={<div className="fw-bold">Chương trình đào tạo</div>}
              >
                <ul className="mb-3">
                  <li class="bullet-arrow">
                    Đạo tạo cho kỹ sư mới ra trường phù hợp với năng lực và
                    hướng phát triển cá nhân.
                  </li>
                  <li class="bullet-arrow">
                    Bồi dưỡng đội ngũ lãnh đạo kế cận
                  </li>
                  <li class="bullet-arrow">
                    Đào tạo lại theo từng chuyên môn gắn với quy định về thời
                    gian luân chuyển công tác giữa các vị trí làm việc.
                  </li>
                </ul>
              </Tab>
              <Tab
                tabClassName="text-black"
                eventKey="evaluate"
                title={<div className="fw-bold">Tiêu chí đánh giá</div>}
              >
                <li className="mb-3">
                  Đào tạo lại theo từng chuyên môn gắn với quy định về thời gian
                  luân chuyển công tác giữa các vị trí làm việc.
                </li>
              </Tab>
            </Tabs>
          </Col>
        </Row>
        {/* Quy trinh */}
        <Row>
          <Col
            xs="12"
            sm="12"
            md="12"
            ref={quytrinhRef}
            style={{ scrollMargin: "var(--nav-height)" }}
          >
            <h4>QUY TRÌNH TUYỂN DỤNG</h4>
            <p>
              Tìm hiểu cách mà Mỹ Kat tuyển dụng nhân sự, Tại Mỹ Kat, chính sách
              tuyển dụng hướng đến mục tiêu hỗ trợ lẫn nhau, chia sẻ kinh nghiệm
              và hợp tác chặt chẽ.
            </p>
            <img
              data-lazyloaded="1"
              src={RecruitmentProcess}
              data-src={RecruitmentProcess}
              class="attachment-large size-large entered litespeed-loaded"
              alt="Quy trình tuyển dụng"
              decoding="async"
              fetchpriority="high"
              data-ll-status="loaded"
              width={"100%"}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CareerWrapper;
