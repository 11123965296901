import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { SOLUTION } from "../../../routes/routerUrl";

import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import NoData from "../../../components/NoData";
import Loading from "../../../components/Loading";
import ImageURL from "../../../components/ImageURL";

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 468 },
		items: 3,
		slidesToSlide: 1,
	},
	mobile: {
		breakpoint: { max: 468, min: 0 },
		items: 1,
		slidesToSlide: 1,
	},
};

const SolutionType = () => {
	const [solutionTypes, setSolutionTypes] = useState([
		{ id: 0, solutions: [] },
	]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		document.title = "Quy trình - Giải pháp";
		getAllSolutionTypes();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// API
	const getAllSolutionTypes = async () => {
		setIsLoading(true);

		axios
			.get(`/category/list`, {
				params: {
					isType: "cPost",
				},
			})
			.then((res) => {
				if (res.data.errCode === 0) {
					getAllSolutions(
						res.data.categories.filter(
							(category) =>
								category.parentId !== null &&
								category.parentId ===
									Number(process.env.REACT_APP_SOLUTION_ID),
						),
					);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllSolutions = async (solutionTypes) => {
		await axios
			.get(`/post/list`, {
				params: {
					cateId: solutionTypes.map(
						(solutionType) => solutionType.id,
					),
					limit: 20,
				},
			})
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setSolutionTypes(
						solutionTypes.map((solutionType) => {
							return {
								...solutionType,
								solutions: res.data.posts.filter(
									(solution) =>
										solution.categoryId === solutionType.id,
								),
							};
						}),
					);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// API

	return (
		<Container className="container-wrapper mt-4">
			{isLoading ? (
				<Loading />
			) : solutionTypes?.length > 0 ? (
				solutionTypes?.map((solutionType) => {
					return (
						<div key={solutionType.id}>
							<div
								className="mx-2 pt-3 pb-5"
								style={{
									backgroundColor: "var(--mute-bg-color)",
									fontSize: "2.5rem",
								}}
							>
								<Link
									to={`/${SOLUTION}/${solutionType.cateSlug}`}
									style={{ textDecoration: "none" }}
									className="text-color"
								>
									{solutionType.cateNameVi}
								</Link>
								<div className="title-solution-line mt-1" />
							</div>

							<Row className="mx-0 solution-item-height">
								<Col
									xs="12"
									md="12"
									xl="6"
									className="d-flex align-items-center"
								>
									<div
										style={{ borderRadius: 0 }}
										className="p-3 w-100"
									>
										<Link
											to={`/${SOLUTION}/${solutionType.solutions[0]?.postSlug}/${solutionType.solutions[0]?.postSlug}`}
										>
											<ImageURL
												style={{
													height: "20rem",
													backgroundSize: "contain",
												}}
												className="my-3"
												imageURL={
													solutionType.solutions[0]
														?.imgURL
												}
											/>
										</Link>

										<Link
											to={`/${SOLUTION}/${solutionType.solutions[0]?.postSlug}/${solutionType.solutions[0]?.postSlug}`}
											style={{ textDecoration: "none" }}
										>
											<div className="text-center title-text my-3">
												{
													solutionType.solutions[0]
														?.postTitleVi
												}
											</div>
										</Link>

										<div className="">
											{
												solutionType.solutions[0]
													?.postDescVi
											}
										</div>
									</div>
								</Col>

								<Col xs="12" md="12" xl="6">
									<div
										className="h-100 w-100 d-none d-xl-block"
										style={{ rotate: "90deg" }}
									>
										<Carousel
											responsive={responsive}
											autoPlay
											infinite={true}
											className="h-100"
										>
											{solutionType.solutions
												.slice(1)
												.map((solution) => {
													return (
														<Card
															key={solution.id}
															style={{
																rotate: "-90deg",
																minWidth:
																	"39rem",
																position:
																	"absolute",
																bottom: "-4rem",
																right: "-14rem",
															}}
															className="p-2"
														>
															<Row>
																<Col xs="5">
																	<Link
																		to={`/${SOLUTION}/${solution.postSlug}/${solution.postSlug}`}
																	>
																		<ImageURL
																			style={{
																				height: "10rem",
																				backgroundSize:
																					"cover",
																			}}
																			imageURL={
																				solution?.imgURL
																			}
																		/>
																	</Link>
																</Col>

																<Col
																	xs="7"
																	className="d-flex flex-column justify-content-between"
																>
																	<Link
																		className="text-black solution-item-text"
																		to={`/${SOLUTION}/${solution.postSlug}/${solution.postSlug}`}
																	>
																		{
																			solution.postTitleVi
																		}
																	</Link>

																	<div className="d-flex">
																		<Link
																			to={`/${SOLUTION}/${solution.postSlug}/${solution.postSlug}`}
																		>
																			<Button
																				type="button"
																				size="sm"
																				className="primary-button px-3"
																			>
																				XEM
																				THÊM
																			</Button>
																		</Link>
																	</div>
																</Col>
															</Row>
														</Card>
													);
												})}
										</Carousel>
									</div>

									<Carousel
										responsive={responsive}
										autoPlay
										infinite={true}
										className="d-xl-none"
									>
										{solutionType.solutions
											.slice(1)
											.map((solution) => {
												return (
													<div
														key={solution.id}
														className="mx-3"
													>
														<ImageURL
															style={{
																height: "10rem",
																backgroundSize:
																	"cover",
															}}
															className="my-3"
															imageURL={
																solution?.imgURL
															}
														/>

														<div className="d-flex justify-content-center">
															<Link className="text-black">
																<div
																	className="solution-item-text"
																	style={{
																		fontSize:
																			"1rem",
																	}}
																>
																	{
																		solution.postTitleVi
																	}
																</div>
															</Link>
														</div>
													</div>
												);
											})}
									</Carousel>
								</Col>
							</Row>
						</div>
					);
				})
			) : (
				<NoData />
			)}
		</Container>
	);
};

export default SolutionType;
