import axios from "axios";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { toast } from "react-toastify";
import Loading from "../../../components/Loading";
import TextEditor from "../../../components/TextEditor";

const Introduction = () => {
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = "Giới thiệu";
    getDetailNew();
  }, []);

  const getDetailNew = () => {
    setIsLoading(true);
    axios
      .get(`/post/list`, {
        params: {
          postSlug: process.env.REACT_APP_INTRO_POST_SLUG,
        },
      })
      .then(async (res) => {
        setIsLoading(false);

        if (res.data.errCode === 0) {
          setContent(res.data.post.postContentVi);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Container className="container-wrapper my-5">
      <div className="title-layout mb-3">Giới thiệu</div>

      <div>
        {isLoading ? (
          <Loading />
        ) : content ? (
          <TextEditor
            disable
            hideToolbar={true}
            defaultValue={content}
            onChange={() => {}}
            style={{ border: "none" }}
          />
        ) : (
          ""
        )}
      </div>
    </Container>
  );
};

export default Introduction;
