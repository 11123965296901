export const SOLUTION = "quy-trinh-giai-phap";
export const SOLUTION_TYPE = "quy-trinh-giai-phap";
export const SOLUTION_DETAIL = "quy-trinh-giai-phap";
export const NEW_DETAIL = "tin-tuc";
export const MEDIA = "photo";

export const CONTACT = "lien-he";
export const CAREER = "tuyen-dung";
export const PROJECT = "du-an";
export const NEW = "tin-tuc";
export const PRODUCT = "du-an";
export const PRODUCT_DETAIL = "du-an";
export const INTRODUCTION = "gioi-thieu";
export const HISTORY = "lich-su";
export const LEADER = "lanh-dao";
export const ACTIVITY = "linh-vuc";

// URL
export const INTRODUCTION_URL = "gioi-thieu";
export const HISTORY_URL = "lich-su";
export const LEADER_URL = "lanh-dao";
export const ACTIVITY_URL = "linh-vuc";
export const ACTIVITY_DETAIL_URL = "linh-vuc/:slug";

export const EDUCATION_URL = "dao-tao";
export const COURSE_DETAIL_URL = "dao-tao/:slug";
export const DOCUMENT_URL = "tai-lieu";
export const DOCUMENT_BY_TYPE_URL = "tai-lieu/:typeId";
export const DOCUMENT_DETAIL_URL = "tai-lieu/:typeId/:slug";

export const PRODUCT_URL = "du-an";
export const PRODUCT_DETAIL_URL = "du-an/:slug";

export const SOLUTION_TYPE_URL = "quy-trinh-giai-phap";
export const SOLUTION_URL = "quy-trinh-giai-phap/:slug";
export const SOLUTION_DETAIL_URL = "quy-trinh-giai-phap/:slug/:slug";

export const TOPIC_URL = "tin-tuc";
export const NEW_URL = "tin-tuc/:slug";
export const NEW_DETAIL_URL = "tin-tuc/:slug/:slug";

export const MEDIA_URL = "photo";
export const MEDIA_DETAIL_URL = "photo/:slug";

export const CONTACT_URL = "lien-he";

export const CAREER_URL = "tuyen-dung";
export const CAREER_DETAIL_URL = "tuyen-dung/:slug";

export const CALLBACK_SOCIALITE_URL = "callback/:provider";
export const COMPANY_PERSONNEL_SLUG = "nhan-su-cong-ty";

