import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

import { Container } from "react-bootstrap";
import ListSolution from "../../../components/ListSolution";
import ImageURL from "../../../components/ImageURL";
import TextEditor from "../../../components/TextEditor";

const SolutionDetail = () => {
	let { slug } = useParams();

	const [solution, setSolution] = useState({});
	const [relatedPost, setRelatedPost] = useState([]);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		getDetailSolution(slug);
	}, [slug]); // eslint-disable-line react-hooks/exhaustive-deps

	const getDetailSolution = async (postSlug) => {
		setIsLoading(true);
		await axios
			.get(`/post/list`, {
				params: {
					postSlug: postSlug,
				},
			})
			.then(async (res) => {
				if (res.data.errCode === 0) {
					setSolution(res.data.post);
					getAllSolutions(res.data.post.categoryId, res.data.post.id);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllSolutions = async (categoryId, postId) => {
		await axios
			.get(`/post/list`, {
				params: {
					cateId: categoryId,
					limit: 11,
				},
			})
			.then((res) => {
				setIsLoading(false);
				if (res.data.errCode === 0) {
					setRelatedPost(
						res.data.posts.filter((post) => post.id !== postId),
					);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<Container className="container-wrapper my-5">
			<div className="title-layout">{solution.postTitleVi}</div>

			<ImageURL
				style={{
					width: "100%",
					aspectRatio: "3/1",
					backgroundSize: "contain",
				}}
				className="my-3"
				imageURL={solution?.imgURL}
			/>

			<div className="my-5">
				{solution.postContentVi ? (
					<TextEditor
						disable
						hideToolbar={true}
						setContents={solution.postContentVi}
						onChange={() => {}}
					/>
				) : (
					""
				)}
			</div>

			<div className="mt-5">
				<h3 className="mb-3">Xem thêm các bài viết cùng chuyên mục</h3>
				<ListSolution solutions={relatedPost} isLoading={isLoading} />
			</div>
		</Container>
	);
};

export default SolutionDetail;
