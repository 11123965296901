import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "../../../utils/yupGlobal";
import { toast } from "react-toastify";

import { Button, Form, Modal, Tab, Tabs } from "react-bootstrap";
import { ACCESS_PERMISSIONS } from "../../../constants";
import DataTables from "../../../components/DataTables";
import Loading from "../../../components/Loading";
import Select from "react-select";

const GrantAccessModal = ({ handleCloseModal, showModal, customer }) => {
  const [isSendForm, setIsSendForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [accessibleCourses, setAccessibleCourses] = useState([]);
  const [accessibleDocs, setAccessibleDocs] = useState([]);
  const [documentsFilttered, setDocumentsFilttered] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const accessTypes = [
    { id: 0, name: "All" },
    { id: 1, name: ACCESS_PERMISSIONS.VIP },
    { id: 2, name: ACCESS_PERMISSIONS.PREMIUM },
  ];

  useEffect(() => {
    getAllCourses();
    getAllDocuments();
  }, []);

  const {
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(Schema),
  });

  // Api
  const getAllCourses = async (keyword) => {
    setIsLoading(true);
    await axios
      .get(`/get-course-by-customer/${customer.id}`, {
        params: {
          keyword: keyword,
        },
      })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          const vipCourse = res.data.data?.filter(
            (element) => element.type === ACCESS_PERMISSIONS.VIP
          );
          const accessibleCourseIds = customer.accessibleCourses
            ? customer.accessibleCourses?.split(",")
            : [];
          setCourses(vipCourse);
          setAccessibleCourses(
            vipCourse.filter((course) =>
              accessibleCourseIds.find((id) => {
                return course.id === Number(id);
              })
            )
          );
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
          type: "error",
          autoClose: 1000,
        });
        console.log(err);
      });
  };

  const getAllDocuments = async () => {
    setIsLoading(true);
    await axios
      .get(`/get-document-by-customer/${customer.id}`)
      .then((res) => {
        setIsLoading(false);

        if (res.status === 200) {
          console.log(">>>>>>>>>doc,", res.data);
          const vipDocuments = res.data.data?.filter(
            (element) =>
              element.type === ACCESS_PERMISSIONS.VIP ||
              element.type === ACCESS_PERMISSIONS.PREMIUM
          );
          const accessibleDocumentIds = customer.accessibleDocs
            ? customer.accessibleDocs?.split(",")
            : [];
          setDocuments(vipDocuments);
          setDocumentsFilttered(vipDocuments);
          setAccessibleDocs(
            vipDocuments.filter((doc) =>
              accessibleDocumentIds.find((id) => {
                return doc.id === Number(id);
              })
            )
          );
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
          type: "error",
          autoClose: 1000,
        });
        console.log(err);
      });
  };

  const changeAccessiblePost = async () => {
    setIsSendForm(true);
    await axios
      .put(`/customer-mk/accessible-post`, {
        customerId: customer.id,
        accessibleCourseIds: accessibleCourses
          .map((course) => {
            return course.id;
          })
          .toString(),
        accessibleDocumentIds: accessibleDocs
          .map((document) => {
            return document.id;
          })
          .toString(),
      })
      .then((res) => {
        setIsSendForm(false);
        toast(res.data.message, {
          type: "success",
          autoClose: 1000,
        });
        handleCloseModal(true);
      })
      .catch((err) => {
        setIsSendForm(false);
        toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
          type: "error",
          autoClose: 1000,
        });
        console.log(err);
      });
  };
  // Api

  const handleAccessibleCourseChange = (course) => {
    const isSelected = accessibleCourses.find(
      (element) => element.id === course.id
    );

    if (isSelected) {
      setAccessibleCourses(
        accessibleCourses.filter((element) => element.id !== course.id)
      );
    } else {
      setAccessibleCourses([...accessibleCourses, course]);
    }
  };

  const handleAccessibleDocumentChange = (document) => {
    const isSelected = accessibleDocs.find(
      (element) => element.id === document.id
    );

    if (isSelected) {
      setAccessibleDocs(
        accessibleDocs.filter((element) => element.id !== document.id)
      );
    } else {
      setAccessibleDocs([...accessibleDocs, document]);
    }
  };

  const handleFilterDocType = (newType) => {
    if (newType === "All") {
      setDocumentsFilttered(documents);
      return;
    }
    setDocumentsFilttered(
      documents.filter((element) => element.type === newType)
    );
  };

  const columnsCourse = [
    {
      name: "Có thể truy cập",
      style: {
        display: "flex",
        justifyContent: "center",
      },
      width: "10rem",
      selector: (row) => {
        return (
          <Form.Check
            key={row.id}
            type="checkbox"
            checked={
              accessibleCourses.find((course) => course.id === row.id) !==
              undefined
            }
            onClick={() => handleAccessibleCourseChange(row)}
          />
        );
      },
    },
    {
      name: "Khóa học VIP",
      selector: (row) => row.name,
      wrap: true,
    },
  ];

  const columnsDocument = [
    {
      name: "Có thể truy cập",
      style: {
        display: "flex",
        justifyContent: "center",
      },
      width: "10rem",
      selector: (row) => {
        return (
          <Form.Check
            key={row.id}
            type="checkbox"
            checked={
              accessibleDocs.find((doc) => doc.id === row.id) !== undefined
            }
            onClick={() => handleAccessibleDocumentChange(row)}
          />
        );
      },
    },
    {
      name: "Quyền truy cập",
      selector: (row) => row.type,
      wrap: true,
      width: "150px",
      style: {
        display: "flex",
        justifyContent: "center",
      },
      sortable: true,
    },
    {
      name: "Tài liệu VIP và PREMIUM",
      selector: (row) => row.name,
      wrap: true,
    },
  ];

  return (
    <Modal
      show={showModal}
      onHide={() => handleCloseModal(false)}
      backdrop="static"
      fullscreen
    >
      <Modal.Header closeButton={!isSendForm}>
        <Modal.Title>Gán quyền truy cập khóa học và tài liệu VIP</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          id="add-courseType-hook-form"
          onSubmit={handleSubmit((_) => {
            changeAccessiblePost();
          })}
          className="mb-5"
        >
          <Tabs
            defaultActiveKey="courses"
            style={{ borderColor: "var(--sencondary-color)" }}
          >
            <Tab
              tabClassName="text-black"
              eventKey="courses"
              title={<div className="fw-bold">Khóa học</div>}
              className="tab-product-border p-3"
            >
              <div className="mb-3">
                {isLoading ? (
                  <Loading />
                ) : (
                  <DataTables data={courses} columns={columnsCourse} />
                )}
              </div>
            </Tab>
            <Tab
              tabClassName="text-black"
              eventKey="Tài liệu"
              title={<div className="fw-bold">Tài liệu</div>}
              className="tab-product-border p-3"
            >
              <div className="mb-3">
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    <div style={{ width: "220px" }}>
                      <Select
                        options={accessTypes}
                        placeholder={`Chọn quyền truy cập`}
                        noOptionsMessage={() => "Không có lựa chọn"}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        defaultValue={accessTypes[0]}
                        onChange={(choice) => {
                          handleFilterDocType(choice.name);
                        }}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        className="mb-3"
                      />
                    </div>
                    <DataTables
                      clearSelectedRows={toggleCleared}
                      data={documentsFilttered}
                      columns={columnsDocument}
                    />
                  </>
                )}
              </div>
            </Tab>
          </Tabs>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          variant="secondary"
          onClick={() => handleCloseModal(false)}
          disabled={isSendForm}
        >
          Đóng
        </Button>
        <Button
          type="submit"
          form="add-courseType-hook-form"
          variant="primary"
          disabled={isSendForm || isLoading}
        >
          Lưu
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Schema = yup.object().shape({});

export default GrantAccessModal;
