import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

import { Button, Form, Modal } from "react-bootstrap";

import yup from "../../../utils/yupGlobal";

const editFormItems = [
  {
    id: 1,
    title: "Header h1",
    placeholder: "Nhập nội dung cho div h1 của header",
    name: "header_h1",
    required: false,
  },
  {
    id: 2,
    title: "Header h2",
    placeholder: "Nhập nội dung cho div h2 của header",
    name: "header_h2",
    required: false,
  },
  {
    id: 3,
    title: "Footer h1",
    placeholder: "Nhập nội dung cho div h1 của footer",
    name: "footer_h1",
    required: false,
  },
  {
    id: 4,
    title: "Footer h2",
    placeholder: "Nhập nội dung cho div h2 của footer",
    name: "footer_h2",
    required: false,
  },
];

const BannerTextEditModal = ({ handleCloseModal, showModal }) => {
  const [shop, setShop] = useState();

  const [isSendForm, setIsSendForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(Schema),
  });

  useEffect(() => {
    getSetting();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Api
  const getSetting = async () => {
    setIsLoading(true);
    await axios
      .get(`/shop/detail`)
      .then((res) => {
        setIsLoading(false);
        if (res.data.errCode === 0) {
          setShop(res.data.shop);
          const headerScript = JSON.parse(res.data.shop.headerScript);
          const footerScript = JSON.parse(res.data.shop.footerScript);
          reset({
            header_h1: headerScript?.h1 ?? "",
            header_h2: headerScript?.h2 ?? "",
            footer_h1: footerScript?.h1 ?? "",
            footer_h2: footerScript?.h2 ?? "",
          });
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const updateSetting = async (data) => {
    setIsSendForm(true);
    await axios
      .put(`/shop/update`, {
        shopNameVi: shop.shopNameVi,
        shopEmail: shop.shopEmail,
        shopPhone: shop.shopPhone,
        shopAddressVi: shop.shopAddressVi,
        shopAddressEn: shop.shopAddressEn,
        shopDescVi: shop.shopDescVi,
        shopDescEn: shop.shopDescEn,
        headerScript: JSON.stringify({
          h1: data.header_h1,
          h2: data.header_h2,
        }),
        footerScript: JSON.stringify({
          h1: data.footer_h1,
          h2: data.footer_h2,
        }),
      })
      .then((res) => {
        setIsSendForm(false);
        if (res.data.errCode === 0) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });
          handleCloseModal(false);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        setIsSendForm(false);
        console.log(err);
      });
  };
  // Api

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={() => handleCloseModal(false)}
      backdrop="static"
    >
      <Modal.Header closeButton={!isSendForm}>
        <Modal.Title>Cập nhật text banner</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          id="edit-product-hook-form"
          onSubmit={handleSubmit((data) => {
            updateSetting(data);
          })}
          className="mb-3"
        >
          {editFormItems?.map((formItem) => {
            return (
              <div key={formItem.id} className="mb-3">
                <Form.Label>
                  {formItem.title}
                  {formItem.required ? <i className="text-danger">*</i> : ""}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={formItem.placeholder}
                  name={formItem.name}
                  {...register(formItem.name)}
                />

                {errors[formItem.name] && (
                  <p
                    style={{
                      fontSize: 13,
                      color: "red",
                      marginTop: 6,
                    }}
                  >
                    {errors[formItem.name].message}
                  </p>
                )}
              </div>
            );
          })}
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          variant="secondary"
          onClick={() => handleCloseModal(false)}
          disabled={isSendForm}
        >
          Đóng
        </Button>
        <Button
          type="submit"
          form="edit-product-hook-form"
          variant="primary"
          disabled={isSendForm || isLoading}
        >
          Cập nhật
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Schema = yup.object().shape({
  header_h1: yup
    .string()
    .test(
      "len",
      "Nội dung không dài quá 50 kí tự",
      (val) => val.length >= 0 && val.length <= 50
    ),
  header_h2: yup
    .string()
    .test(
      "len",
      "Nội dung không dài quá 150 kí tự",
      (val) => val.length >= 0 && val.length <= 150
    ),
  footer_h1: yup
    .string()
    .test(
      "len",
      "Nội dung không dài quá 50 kí tự",
      (val) => val.length >= 0 && val.length <= 50
    ),
  footer_h2: yup
    .string()
    .test(
      "len",
      "Nội dung không dài quá 150 kí tự",
      (val) => val.length >= 0 && val.length <= 150
    ),
});

export default BannerTextEditModal;
