import React from "react";
import "./App.css";
import Router from "./routes/router";
import axios from "axios";

axios.defaults.baseURL = `${
	process.env.REACT_APP_BACKEND_URL || "http://localhost:8086/"
}`;

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "*/*";
axios.defaults.headers.post["Access-Control-Allow-Origin"] =
	process.env.REACT_APP_CLIENT_URL || "*";
axios.defaults.headers["Authorization"] = localStorage.getItem("access_token")
	? "Bearer " + localStorage.getItem("access_token")
	: "";
axios.defaults.headers["Shop-Domain"] = process.env.REACT_APP_SHOP_DOMAIN_URL;

export default function App() {
	return <Router />;
}
