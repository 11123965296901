import MenuItems from "./MenuItems";
import { useLocation } from "react-router-dom";

const Dropdown = ({ submenus, dropdown, depthLevel }) => {
  const location = useLocation();
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";

  return (
    <ul
      className={`dropdown ${dropdownClass} ${dropdown ? "show" : ""} ${
        location.pathname === "/" && submenus.link === "/"
          ? "navbar-text-active text-uppercase"
          : location.pathname.startsWith(`/${submenus.link}`)
          ? "navbar-text-active text-uppercase"
          : "navbar-text text-uppercase"
      }`}
    >
      {submenus.map((submenu, index) => (
        <MenuItems items={submenu} key={index} depthLevel={depthLevel} />
      ))}
    </ul>
  );
};

export default Dropdown;
