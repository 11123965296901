import { Card, Col, Row } from "react-bootstrap";
import { DOCUMENT_URL } from "../routes/routerUrl";
import { Link } from "react-router-dom";
import Loading from "./Loading";
import NoData from "./NoData";
import ImageURL from "./ImageURL";
import MyPagination from "./MyPagination";
import CurrencyFormat from "react-currency-format";

import "./Style.css";
import { ACCESS_PERMISSIONS } from "../constants";

const ListDocument = ({
  showRow,
  isLoading,
  documents,
  itemsCount,
  itemsPerPage,
  currentPage,
  setCurrentPage,
  isPagiantion,
}) => {
  return (
    <Row>
      {isLoading ? (
        <Loading />
      ) : documents?.length > 0 ? (
        documents?.map((doc) => {
          return (
            <Col
              xs={12}
              sm={6}
              md={4}
              xl={12 / showRow}
              key={doc.id}
              className="mb-5"
            >
              <Link
                to={`/${DOCUMENT_URL}/${doc.id}/${doc.slug}`}
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <Card className="product-border">
                  <ImageURL
                    style={{ height: "12rem" }}
                    imageURL={`/${doc.image}`}
                  />
                  <Card.Body className="d-flex flex-column justify-content-between">
                    <Card.Title className="text-center">{doc.name}</Card.Title>
                  </Card.Body>
                  <Card.Footer>
                    {doc.type === ACCESS_PERMISSIONS.VIP ? (
                      <div
                        className="px-4 py-1"
                        style={{
                          backgroundColor: "orange",
                          color: "white",
                          width: "fit-content",
                          borderRadius: "16px",
                        }}
                      >
                        Vip
                      </div>
                    ) : doc.type === ACCESS_PERMISSIONS.PREMIUM ? (
                      <div
                        className="px-4 py-1"
                        style={{
                          backgroundColor: "orange",
                          color: "white",
                          width: "fit-content",
                          borderRadius: "16px",
                        }}
                      >
                        <CurrencyFormat
                          thousandSeparator={true}
                          value={doc.price}
                          suffix=" ₫"
                          displayType="text"
                        />
                      </div>
                    ) : (
                      <div
                        className="px-4 py-1"
                        style={{
                          backgroundColor: "blue",
                          color: "white",
                          width: "fit-content",
                          borderRadius: "16px",
                        }}
                      >
                        Free
                      </div>
                    )}
                  </Card.Footer>
                </Card>
              </Link>
            </Col>
          );
        })
      ) : (
        <NoData />
      )}

      {isPagiantion && (
        <MyPagination
          itemsCount={itemsCount}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          alwaysShown={false}
        />
      )}
    </Row>
  );
};

export default ListDocument;
