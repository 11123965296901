import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "../../../utils/yupGlobal";
import { toast } from "react-toastify";

import { Button, Form, Modal } from "react-bootstrap";

const addLessonFormItems = [
  {
    id: 1,
    title: "Tên bài học",
    placeholder: "Tên bài học",
    name: "title",
    required: true,
  },
  {
    id: 2,
    title: "Link video",
    placeholder: "Link video trên YouTube",
    name: "link",
    required: true,
  },
  {
    id: 3,
    title: "Mô tả",
    placeholder: "Mô tả bài học",
    name: "description",
    required: false,
  },
];

const LessonAddModal = ({ handleCloseModal, showModal, selectedCourse }) => {
  const [isSendForm, setIsSendForm] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(Schema),
  });

  useEffect(() => {}, []);

  // Api
  const createLesson = async (data) => {
    setIsSendForm(true);

    const content = JSON.parse(selectedCourse.content);
    content.push({
      name: data.title,
      link: data.link,
      description: data.description,
    });

    const bodyFormData = new FormData();
    bodyFormData.append("courseId", selectedCourse.id);
    bodyFormData.append("categoryId", selectedCourse.categoryId);
    bodyFormData.append("name", selectedCourse.name);
    bodyFormData.append("description", selectedCourse.description);
    bodyFormData.append("content", JSON.stringify(content));
    bodyFormData.append("postSlug", selectedCourse.slug);
    bodyFormData.append("isStatus", "S1");

    await axios({
      method: "put",
      url: "course-mk/update",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setIsSendForm(false);

        if (res.status === 200) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });
          handleCloseModal(true);
        } else {
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
          type: "error",
          autoClose: 1000,
        });
        setIsSendForm(false);
        console.log(err);
      });
  };
  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={() => handleCloseModal(false)}
      backdrop="static"
    >
      <Modal.Header closeButton={!isSendForm}>
        <Modal.Title>Thêm bài học</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          id="add-new-hook-form"
          onSubmit={handleSubmit((data) => {
            createLesson(data);
          })}
          className="mb-5"
        >
          {addLessonFormItems?.map((formItem) => {
            if (formItem.id >= 2) {
              return (
                <div key={formItem.id} className="mb-3">
                  <Form.Label className="fw-bold">
                    {formItem.title}
                    {formItem.required && <i className="text-danger">*</i>}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder={formItem.placeholder}
                    name={formItem.name}
                    rows={3}
                    {...register(formItem.name)}
                  />
                  {errors[formItem.name] && (
                    <p
                      style={{
                        fontSize: 13,
                        color: "red",
                        marginTop: 6,
                      }}
                    >
                      {errors[formItem.name].message}
                    </p>
                  )}
                </div>
              );
            }

            return (
              <div key={formItem.id} className="mb-3">
                <Form.Label className="fw-bold">
                  {formItem.title}
                  <i className="text-danger">*</i>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={formItem.placeholder}
                  name={formItem.name}
                  {...register(formItem.name)}
                />

                {errors[formItem.name] && (
                  <p
                    style={{
                      fontSize: 13,
                      color: "red",
                      marginTop: 6,
                    }}
                  >
                    {errors[formItem.name].message}
                  </p>
                )}
              </div>
            );
          })}
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          variant="secondary"
          onClick={() => handleCloseModal(false)}
          disabled={isSendForm}
        >
          Đóng
        </Button>
        <Button
          type="submit"
          form="add-new-hook-form"
          variant="primary"
          disabled={isSendForm}
        >
          Lưu
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Schema = yup.object().shape({
  title: yup
    .string()
    .required("Không được bỏ trống")
    .test(
      "len",
      "Tiêu đề không dài quá 255 kí tự",
      (val) => val.length >= 0 && val.length <= 255
    ),
  link: yup.string().required("Không được bỏ trống"),
});

export default LessonAddModal;
