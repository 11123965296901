import { Col, Row } from "react-bootstrap";
import "./Style.css";
import { Link } from "react-router-dom";
import { SOLUTION } from "../routes/routerUrl";
import Loading from "./Loading";
import NoData from "./NoData";
import MyPagination from "./MyPagination";

const ListSolution = ({
	isLoading,
	solutions,
	itemsCount,
	itemsPerPage,
	currentPage,
	setCurrentPage,
	isPagiantion,
}) => {
	return (
		<Row>
			{isLoading ? (
				<Loading />
			) : solutions?.length > 0 ? (
				solutions?.map((solution) => {
					return (
						<Col
							key={solution.id}
							xs="12"
							sm="12"
							md="3"
							className="mb-5 mb-md-4"
						>
							<Link
								to={`/${SOLUTION}/${solution.postSlug}/${solution.postSlug}`}
							>
								<div
									style={{
										aspectRatio: "16/9",
										width: "100%",
									}}
								>
									<div
										key={solution.id}
										style={{
											width: "100%",
											height: "10rem",
											backgroundSize: "cover",
											backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}${solution.imgURL})`,
											backgroundRepeat: "no-repeat",
											backgroundPosition: "center",
										}}
										className="youtube-parent d-flex justify-content-center align-items-center"
									></div>
								</div>
							</Link>
							<div>
								<div className="fw-bold my-2">
									<Link
										to={`/${SOLUTION}/${solution.postSlug}/${solution.postSlug}`}
										className="text-black"
									>
										{solution.postTitleVi}
									</Link>
								</div>
								<div className="text-overflow-4-line">
									{solution.postDescVi}
								</div>
							</div>
						</Col>
					);
				})
			) : (
				<NoData />
			)}

			{isPagiantion && (
				<MyPagination
					itemsCount={itemsCount}
					itemsPerPage={itemsPerPage}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					alwaysShown={false}
				/>
			)}
		</Row>
	);
};

export default ListSolution;
