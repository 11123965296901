import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import {
  Col,
  Container,
  Row,
  Tab,
  Tabs,
  Breadcrumb,
  Table,
} from "react-bootstrap";
import Carousel from "react-multi-carousel";

import ListProduct from "../../../components/ListProduct";
import ImageURL from "../../../components/ImageURL";
import Icon from "../../../components/Icon";
import { CAREER } from "../../../routes/routerUrl";

const responsiveProduct = {
  desktop: {
    breakpoint: { max: 3000, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const ProductDetail = () => {
  let { slug } = useParams();

  const [product, setProduct] = useState({ imgPaths: [] });
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [news, setNews] = useState([]);

  const itemsPerPage = 10;

  useEffect(() => {
    getProduct(slug);
    getAllNews(process.env.REACT_APP_CAREER_SUB_CATEROGY_SLUG, 1);
  }, [slug]); // eslint-disable-line react-hooks/exhaustive-deps

  // API
  const getProduct = async (slug) => {
    setIsLoading(true);

    await axios
      .get(`/product/list`, {
        params: {
          proSlug: slug,
        },
      })
      .then((res) => {
        if (res.data.errCode === 0) {
          document.title = res.data.product.proNameVi;

          const newProduct = res.data.product;
          newProduct.imgPaths = res.data.product.imgPaths.split(",");

          setProduct(newProduct);

          getAllProducts(newProduct.categoryId, newProduct.id);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllProducts = async (cateId, productId) => {
    await axios
      .get(`/product/list`, {
        params: {
          cateId: cateId || 0,
          limit: 11,
        },
      })
      .then((res) => {
        setIsLoading(false);

        if (res.data.errCode === 0) {
          setRelatedProducts(
            res.data.products
              .filter((product) => product.id !== productId)
              .map((product) => {
                return {
                  ...product,
                  imgPaths: product.imgPaths ? product.imgPaths.split(",") : [],
                };
              })
          );
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllNews = async (cateSlug, page) => {
    setIsLoading(true);
    await axios
      .get(`/post/list`, {
        params: {
          cateSlug: cateSlug,
          page: page,
          limit: itemsPerPage,
        },
      })
      .then((res) => {
        setIsLoading(false);

        if (res.data.errCode === 0) {
          setNews(res.data.posts);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // API

  return (
    <Container className="container-wrapper mb-5">
      <Breadcrumb
        style={{
          backgroundColor: "var(--mute-bg-color)",
        }}
        className="p-3 pb-1"
      >
        <Breadcrumb.Item active>Trang chủ</Breadcrumb.Item>
        <Breadcrumb.Item active>Dự án</Breadcrumb.Item>
        <Breadcrumb.Item active>{product.proNameVi}</Breadcrumb.Item>
      </Breadcrumb>

      <Row className="mb-5">
        <Col xs="12" sm="12" md="8">
          <Carousel
            responsive={responsiveProduct}
            infinite={true}
            className="mb-3 mt-3"
          >
            {product.imgPaths.map((image, index) => {
              return (
                <ImageURL
                  key={index}
                  style={{ height: "30rem" }}
                  imageURL={image}
                />
              );
            })}
          </Carousel>
          <Tabs
            defaultActiveKey="formula"
            style={{ borderColor: "var(--sencondary-color)" }}
          >
            <Tab
              tabClassName="text-black"
              eventKey="formula"
              title={
                <div className="fw-bold">
                  <Icon icon="magnifying-glass" /> Thông tin chi tiết dự án
                </div>
              }
              className="tab-product-border p-3"
            >
              <div>
                <Table striped bordered>
                  <tbody>
                    <tr>
                      <td>Tên dự án</td>
                      <td>{product.proNameVi}</td>
                    </tr>
                    <tr>
                      <td>Chủ đầu tư</td>
                      <td>{product.proInfoVi}</td>
                    </tr>
                    <tr>
                      <td>Giá trị</td>
                      <td>{product.proInfoEn}</td>
                    </tr>
                    <tr>
                      <td>Gói thầu</td>
                      <td>{product.proDescVi}</td>
                    </tr>
                    <tr>
                      <td>Khởi công</td>
                      <td>{product.proContentEn}</td>
                    </tr>
                    <tr>
                      <td>Địa điểm</td>
                      <td>{product.proContentVi}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Tab>
          </Tabs>
        </Col>
        <Col xs="12" sm="12" md="4" className="pt-5">
          <div
            style={{
              position: "sticky",
              top: "var(--nav-height)",
            }}
          >
            <div className="fw-bold mb-3">THÔNG TIN TUYỂN DỤNG</div>
            <div>
              <Table striped bordered>
                <tbody>
                  {news.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <Link
                            to={`/${CAREER}/${item.postSlug}`}
                            className="text-black"
                          >
                            {item.postTitleVi}
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
      </Row>

      <div className="mt-5">
        <h3 className="mb-3 fst-italic">Các dự án khác</h3>

        <ListProduct
          showRow={4}
          products={relatedProducts}
          isLoading={isLoading}
          isPagiantion={false}
        />
      </div>
    </Container>
  );
};

export default ProductDetail;
