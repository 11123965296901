import { useState, useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "../../utils/yupGlobal";

import { Button, Form, Modal } from "react-bootstrap";
import Input from "../../components/Input";
import Icon from "../../components/Icon";
import { ShopContext } from "../../utils/contextGlobal";

const RegisterDocumentOrCourse = ({ type, show, handleClose }) => {
  const shop = useContext(ShopContext);
  const [isSendForm, setIsSendForm] = useState(false);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(Schema),
  });

  // API
  const sendMail = async (data) => {
    setIsSendForm(true);

    await axios
      .post(`/sendMail`, {
        from: shop.shopEmail,
        to: shop.shopEmail,
        subject: `${type.toUpperCase()} MỸ KAT`,
        fullname: data.fullname,
        phonenumber: data.phonenumber,
        email: data.email || "",
      })
      .then((res) => {
        setIsSendForm(false);

        if (res.data.status) {
          reset({
            fullname: "",
            email: "",
            phonenumber: "",
          });
          toast("Đã gửi thông tin thành công, công ty sẽ liên hệ với bạn sau", {
            type: "success",
            autoClose: 3000,
          });
        } else {
          toast("Có lỗi xảy ra, vui lòng thử lại sau", {
            type: "error",
            autoClose: 2000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //API

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <strong>{type.toUpperCase()}</strong>
      </Modal.Header>
      <Form onSubmit={handleSubmit((data) => sendMail(data))}>
        <Modal.Body>
          <Input
            placeholder="Họ và tên"
            name="fullname"
            register={register("fullname")}
            errors={errors.fullname}
            className="mb-3"
          />

          <Input
            placeholder="Email"
            name="email"
            register={register("email")}
            errors={errors.email}
            className="mb-3"
          />

          <Input
            placeholder="Số điện thoại"
            name="phonenumber"
            register={register("phonenumber")}
            errors={errors.phonenumber}
            className="mb-3"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            size="sm"
            className="fw-bold primary-button px-3"
            style={{
              borderRadius: 0,
            }}
            disabled={isSendForm}
          >
            <Icon icon="envelope" /> Gửi
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const Schema = yup.object().shape({
  fullname: yup.string().required("Không được bỏ trống"),
  phonenumber: yup
    .string()
    .required("Vui lòng nhập số điện thoại")
    .phone("Số điện thoại không đúng địng dạng"),
  email: yup.string().email("Email không đúng định dạng"),
});

export default RegisterDocumentOrCourse;
