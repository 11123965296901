import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { Button, Container, Row, Col } from "react-bootstrap";

import Icon from "../../../components/Icon";
import Loading from "../../../components/Loading";
import DataTables from "../../../components/DataTables";
import DateFormat from "../../../components/DateFormat";

import GrantAccessModal from "./GrantAccessModal";
import CustomerAddModal from "./CustomerAddModal";

const Customer = () => {
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showGrantAccessModal, setShowGrantAccessModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [isDisplayCheckbox, setIsDisplayCheckbox] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedCustomerTable, setSelectedCustomerTable] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);

  const handleCloseGrantAccessModal = (isReload) => {
    setShowGrantAccessModal(false);
    if (isReload) {
      getAllCustomers();
    }
  };

  const handleShowGrantAccessModal = (customer) => {
    setShowGrantAccessModal(true);
    setSelectedCustomer(customer);
  };

  useEffect(() => {
    document.title = "Khách hàng";
    getAllCustomers();
  }, []);

  const handleShowCheckbox = () => {
    setIsDisplayCheckbox(true);
  };

  const handleHiddenCheckbox = () => {
    setIsDisplayCheckbox(false);
  };
  //#region API
  const getAllCustomers = async () => {
    setIsLoading(true);
    await axios
      .get(`/customer-mk/list`)
      .then((res) => {
        setIsLoading(false);
        setCustomers(res.data.data);
      })
      .catch((err) => {
        setIsLoading(false);
        toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
          type: "error",
          autoClose: 1000,
        });
        console.log(err);
      });
  };

  const deleteCustomer = async (customerId) => {
    await axios
      .delete(`/customer-mk/destroy/${customerId}`)
      .then((res) => {
        toast(res.data.message, {
          type: "success",
          autoClose: 1000,
        });
        getAllCustomers("");
      })
      .catch((err) => {
        toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
          type: "error",
          autoClose: 1000,
        });
        console.log(err);
      });
  };
  //#endregion

  const handleDeleteCustomer = (customerId) => {
    swal({
      title: `Bạn muốn xóa tài khoản khách hàng này?`,
      text: ` Lưu ý: Hành động không thể khôi phục lại`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        deleteCustomer(customerId);
      }
    });
  };

  const handleShowAddModal = (isReload) => {
    setShowAddModal(true);
    if (isReload) {
      getAllCustomers();
    }
  };
  const handleCloseAddModal = (isReload) => {
    setShowAddModal(false);
    if (isReload) {
      getAllCustomers();
    }
  };
  const columns = [
    {
      name: "Tên",
      selector: (row) => row.name,
      wrap: true,
    },

    {
      name: "Khóa đào tạo",
      selector: (row) => row.trainingCourse?.name,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      wrap: true,
    },
    {
      name: "Cập nhật",
      selector: (row) => <DateFormat date={row.updatedAt} />,
    },
    {
      name: "Phương thức",
      style: {
        display: "flex",
        justifyContent: "center",
      },
      selector: (row) => (
        <>
          <Button
            size="sm"
            variant="danger me-2"
            type="button"
            onClick={() => handleDeleteCustomer(row.id)}
          >
            <Icon icon="trash" />
          </Button>
          <Button
            size="sm"
            variant="primary"
            type="button"
            onClick={() => handleShowGrantAccessModal(row)}
          >
            <Icon icon="star" /> Gán quyền
          </Button>
        </>
      ),
    },
  ];
  const selectRow = (item) => {
    setSelectedRow(item);
  };

  const onSelectRowChange = (rows) => {
    setSelectedCustomerTable(rows);
  };
  return (
    <Container fluid>
      <div
        style={{
          backgroundColor: "#26B59C",
          fontSize: "1.5rem",
          color: "white",
        }}
        className="text-center w-100 mb-3 py-3 mt-3"
      >
        Danh sách khách hàng
      </div>
      <div className="d-flex justify-content-start mb-2">
        {isDisplayCheckbox ? (
          <div className="">
            <Button
              type="button"
              variant="secondary"
              className="me-2 btn-dels"
              onClick={() => handleHiddenCheckbox()}
            >
              <i className="fa-solid fa-users"></i> Hủy
            </Button>
            <Button
              type="button"
              variant="primary"
              className="me-2 btn-dels"
              onClick={handleShowAddModal}
              disabled={selectedCustomerTable.length < 1}
            >
              <i className="fa-solid fa-users"></i> Gán khoá
            </Button>
          </div>
        ) : (
          <Button
            variant="primary"
            className="mt-2"
            onClick={() => handleShowCheckbox({})}
          >
            Gán khoá đào tạo
          </Button>
        )}
      </div>

      {isLoading ? (
        <Loading />
      ) : (
        <DataTables
          data={customers}
          columns={columns}
          selectedRows={selectedRow}
          selectableRows={isDisplayCheckbox}
          onSelectedRowsChange={(item) => {
            onSelectRowChange(item.selectedRows);
          }}
        />
      )}
      {showAddModal ? (
        <CustomerAddModal
          handleCloseModal={handleCloseAddModal}
          showModal={showAddModal}
          customers={selectedCustomerTable}
        />
      ) : (
        ""
      )}

      {showGrantAccessModal ? (
        <GrantAccessModal
          handleCloseModal={handleCloseGrantAccessModal}
          showModal={showGrantAccessModal}
          customer={selectedCustomer}
        />
      ) : (
        ""
      )}
    </Container>
  );
};

export default Customer;
