import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import bannerImg from "./images/rti-careers.png";
import avatar0 from "./images/avatar_toandnh.png";
import avatar1 from "./images/avatar_sint.png";
import avatar2 from "./images/avatar_khanhpt.png";
import avatar3 from "./images/avatar_longnnh.png";
import avatar4 from "./images/avatar_linhbh.png";
import avatar5 from "./images/avatar_anhmvqr.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "./style.css";

const IndexCompanyPersonnel = () => {

  useEffect(() => {
    document.title = "Nhân sự công ty";
  }, []);

  const [people, setPeople] = useState([
    {
      position: 'Giám đốc',
      fullName: 'Ông Dương Nguyễn Hồng Toàn',
      describe:
        <p>
          <strong>Chuyên môn: </strong> Kỹ sư xây dựng, tư vấn thiết kế. <br />
          <strong>Kinh nghiệm: </strong> Hơn 20 năm trong lĩnh vực xây dựng. <br />
          <strong>Thời gian đồng hành với công ty: </strong> Gần 20 năm gắn bó.
        </p >
      ,
      avatar: avatar0,
      showModal: false,
    },
    {
      position: 'Quản lý',
      fullName: 'Ông Nguyễn Trí Sĩ',
      describe:
        <p>
          <strong>Chuyên môn: </strong> Cơ khí. <br />
          <strong>Kinh nghiệm: </strong> Hơn 20 năm trong lĩnh vực cơ khí. <br />
          <strong>Thời gian đồng hành với công ty: </strong> 15 năm gắn bó.
        </p >
      ,
      avatar: avatar1,
      showModal: false,
    },
    {
      position: 'Quản lý thi công',
      fullName: 'Ông Phạm Tuấn Khanh',
      describe:
        <p>
          <strong>Chuyên môn: </strong> Thi công - Xây dựng công trình. <br />
          <strong>Kinh nghiệm: </strong> Hơn 20 năm trong lĩnh vực thi công. <br />
          <strong>Thời gian đồng hành với công ty: </strong> 17 năm gắn bó.
        </p >
      ,
      avatar: avatar2,
      showModal: false,
    },
    {
      position: 'Kỹ sư xây dựng',
      fullName: 'Ông Ngô Nguyễn Hoàng Long',
      describe:
        <p>
          <strong>Chuyên môn: </strong> Kỹ sư xây dựng - Kĩ thuật thi công. <br />
          <strong>Kinh nghiệm: </strong> Hơn 6 năm trong lĩnh vực cơ khí. <br />
          <strong>Thời gian đồng hành với công ty: </strong> 2 năm gắn bó.
        </p >
      ,
      avatar: avatar3,
      showModal: false,
    },
    {
      position: 'Kỹ sư',
      fullName: 'Ông Bùi Hoài Linh',
      describe:
        <p>
          <strong>Chuyên môn: </strong> Kỹ thuật công trình xây dựng DD & CN. <br />
          <strong>Kinh nghiệm: </strong> 2 năm. <br />
          <strong>Thời gian đồng hành với công ty: </strong> 2 năm gắn bó.
        </p >
      ,
      avatar: avatar4,
      showModal: false,
    },
    {
      position: 'Nhân viên kỹ thuật',
      fullName: 'Ông Mạc Vũ Quốc Anh',
      describe:
        <p>
          <strong>Chuyên môn: </strong> Cơ khí. <br />
          <strong>Kinh nghiệm: </strong> 5 năm. <br />
          <strong>Thời gian đồng hành với công ty: </strong> 3 năm gắn bó.
        </p >
      ,
      avatar: avatar5,
      showModal: false,
    }
  ]);

  const ProfileItem = () => {
    return people.map((item, index) => {
      return (
        <Col className="mb-4" md={4}>
          <Card className="card-people">
            <Card.Img variant="top" src={item.avatar} />

            <Card.Body>
              <h6 className="text-danger">{item.position}</h6>
              <Card.Title>{item.fullName}</Card.Title>
              {/* <Card.Text>
                {item.describe}
              </Card.Text> */}

              <Link onClick={() => handleShow(index)} to={'#'} className="fw-bold text-dark">
                Xem thêm  <FontAwesomeIcon icon={faArrowRight} className="ms-2 text-danger" />
              </Link>
            </Card.Body>
          </Card>
        </Col>
      )
    });
  }

  const handleShow = (index) => {
    setPeople(prevPeople => {
      const newPeople = [...prevPeople];
      newPeople[index] = { ...newPeople[index], showModal: true };
      return newPeople;
    });
  };

  const handleClose = (index) => {
    setPeople(prevPeople => {
      const newPeople = [...prevPeople];
      newPeople[index] = { ...newPeople[index], showModal: false };
      return newPeople;
    });
  };

  const ProfileModal = () => {
    return people.map((item, index) => {
      return (
        <Modal
          className="modal-people"
          size="lg"
          show={item.showModal}
          onHide={() => handleClose(index)}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >

          <Modal.Body className="align-items-baseline position-relative" >
            <img className="position-absolute" src={item.avatar} />

            <div>
              <h6 className="text-danger">{item.position}</h6>
              <h5 >{item.fullName}</h5>

              {item.describe}
            </div>

            <FontAwesomeIcon onClick={() => handleClose(index)} size="lg" className="btn-close-modal text-white" icon={faTimes} />
          </Modal.Body>
        </Modal>
      )
    });
  }

  return (<>
    <img
      src={bannerImg}
      alt="Banner Mỹ Kat"
      style={{
        width: "100%",
        aspectRatio: "4 / 1",
        objectFit: "cover",
      }}
    />

    <Container className="container-wrapper my-5">

      <div className="title-layout mb-3">Nhân sự công ty</div>

      <Row>
        <ProfileItem />
      </Row>

      <ProfileModal />

    </Container>
  </>
  );
};

export default IndexCompanyPersonnel;
