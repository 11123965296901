import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { Button, Container, Form } from "react-bootstrap";

import SolutionAddModal from "./SolutionAddModal";
import SolutionEditModal from "./SolutionEditModal";
import Icon from "../../../components/Icon";
import Loading from "../../../components/Loading";
import DataTables from "../../../components/DataTables";
import DateFormat from "../../../components/DateFormat";
import ImageURL from "../../../components/ImageURL";

const Solution = () => {
	const [solutions, setSolutions] = useState([]);
	const [showAddModal, setShowAddModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [selectedSolution, setSelectedSolution] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [keyword, setKeyword] = useState("");

	useEffect(() => {
		document.title = "Giải pháp";
		getAllSolutions("");
	}, []);

	// API
	const getAllSolutions = async (keyword) => {
		setIsLoading(true);
		await axios
			.get(`/post/show`, {
				params: {
					cateParentId: process.env.REACT_APP_SOLUTION_ID,
					keyword: keyword,
				},
			})
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setSolutions(res.data.posts);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const deleteSolution = async (solutionIdId) => {
		await axios
			.delete(`/post/destroy`, {
				params: {
					id: solutionIdId,
				},
			})
			.then((res) => {
				if (res.data.errCode === 0) {
					toast(res.data.message, {
						type: "success",
						autoClose: 1000,
					});

					getAllSolutions("");
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// Api

	const handleDeleteSolution = (solutionIdId) => {
		swal({
			title: `Bạn muốn xóa giải pháp này`,
			text: ` Lưu ý: Hành động không thể khôi phục lại`,
			icon: "warning",
			buttons: ["Đóng", "Xóa"],
			successMode: true,
		}).then((ok) => {
			if (ok) {
				deleteSolution(solutionIdId);
			}
		});
	};

	const handleCloseAddModal = (isReload) => {
		setShowAddModal(false);
		if (isReload) {
			getAllSolutions("");
		}
	};
	const handleShowAddModal = () => {
		setShowAddModal(true);
		setSelectedSolution();
	};

	const handleCloseEditModal = (isReload) => {
		setShowEditModal(false);
		if (isReload) {
			getAllSolutions("");
		}
	};
	const handleShowEditModal = (solutionId) => {
		setShowEditModal(true);
		setSelectedSolution(solutionId);
	};

	const columns = [
		{
			name: "Hình ảnh",
			style: {
				display: "flex",
				justifyContent: "center",
			},
			selector: (row) => (
				<ImageURL
					style={{
						aspectRatio: "900/600",
						height: "4rem",
						backgroundSize: "cover",
					}}
					imageURL={row.imgURL}
				/>
			),
		},
		{
			name: "Tiêu đề",
			selector: (row) => row.postTitleVi,
			wrap: true,
		},
		{
			name: "Người đăng",
			selector: (row) => row.creatorName,
			wrap: true,
		},
		{
			name: "Cập nhật",
			selector: (row) => <DateFormat date={row.updatedAt} />,
		},
		{
			name: "Phương thức",
			style: {
				display: "flex",
				justifyContent: "center",
			},
			selector: (row) => (
				<>
					<Button
						size="sm"
						variant="warning me-2"
						type="button"
						onClick={() => handleShowEditModal(row.id)}
					>
						<Icon icon="pencil" />
					</Button>

					<Button
						size="sm"
						variant="danger"
						type="button"
						onClick={() => handleDeleteSolution(row.id)}
					>
						<Icon icon="trash" />
					</Button>
				</>
			),
		},
	];

	return (
		<Container fluid>
			<div
				style={{
					backgroundColor: "#26B59C",
					fontSize: "1.5rem",
					color: "white",
				}}
				className="text-center w-100 mb-3 py-3 mt-3"
			>
				Giải pháp cấy trồng
			</div>

			<div className="d-block d-md-flex justify-content-between mb-3">
				<Form
					className="d-flex"
					onSubmit={(e) => {
						e.preventDefault();
						getAllSolutions(keyword);
					}}
				>
					<Form.Control
						type="search"
						placeholder={"Nhập tiêu đề"}
						className="me-2"
						aria-label="Search"
						value={keyword}
						onChange={(e) => setKeyword(e.target.value)}
					/>
					<Button variant="outline-secondary" type="submit">
						<Icon icon="magnifying-glass" />
					</Button>
				</Form>
				<div className="mt-3 mt-md-0">
					<Button
						variant="primary"
						className="mt-2 mt-md-0"
						onClick={() => handleShowAddModal()}
					>
						<Icon icon="plus" /> {"Thêm mới"}
					</Button>
				</div>
			</div>

			{isLoading ? (
				<Loading />
			) : (
				<DataTables data={solutions} columns={columns} />
			)}

			{showAddModal ? (
				<SolutionAddModal
					handleCloseModal={handleCloseAddModal}
					showModal={showAddModal}
				/>
			) : (
				""
			)}

			{showEditModal ? (
				<SolutionEditModal
					handleCloseModal={handleCloseEditModal}
					showModal={showEditModal}
					selectedSolution={selectedSolution}
				/>
			) : (
				""
			)}
		</Container>
	);
};

export default Solution;
