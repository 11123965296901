import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "../../utils/yupGlobal";
import Input from "../../components/Input";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const settingFormItems = [
  {
    id: 1,
    placeholder: "Tên công ty",
    name: "name",
    error: "shopNameVi",
  },
  {
    id: 2,
    placeholder: "Email",
    name: "email",
    error: "shopEmail",
  },
  {
    id: 3,
    placeholder: "Số điện thoại",
    name: "phonenumber",
    error: "shopPhone",
  },
  {
    id: 4,
    placeholder: "Địa chỉ",
    name: "address",
    error: "shopAddressVi",
  },
  {
    id: 5,
    placeholder: "Facebook",
    name: "facebook",
    error: "shopDescVi",
  },
  {
    id: 6,
    placeholder: "Zalo",
    name: "zalo",
    error: "shopDescEn",
  },
  {
    id: 7,
    placeholder: "Youtube",
    name: "youtube",
    error: "shopAddressEn",
  },
  // {
  // 	id: 11,
  // 	placeholder: "Script in Header",
  // 	placeholderEn: "Script in Header",
  // 	name: "headerScript",
  // },
  // {
  // 	id: 12,
  // 	placeholder: "Script in Footer",
  // 	placeholderEn: "Script in Footer",
  // 	name: "footerScript",
  // },
];

const Setting = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(Schema),
  });

  useEffect(() => {
    getSetting();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [errMessage, setErrMessage] = useState();

  // API
  const getSetting = async () => {
    await axios
      .get(`/shop/detail`)
      .then((res) => {
        if (res.data.errCode === 0) {
          reset({
            name: res.data.shop.shopNameVi,
            email: res.data.shop.shopEmail,
            phonenumber: res.data.shop.shopPhone,
            address: res.data.shop.shopAddressVi,
            facebook: res.data.shop.shopDescVi,
            zalo: res.data.shop.shopDescEn,
            youtube: res.data.shop.shopAddressEn,
            headerScript: res.data.shop.headerScript,
            footerScript: res.data.shop.footerScript,
          });
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateSetting = async (data) => {
    await axios
      .put(`/shop/update`, {
        shopNameVi: data.name,
        shopEmail: data.email,
        shopPhone: data.phonenumber,
        shopAddressVi: data.address,
        shopAddressEn: data.youtube,
        shopDescVi: data.facebook,
        shopDescEn: data.zalo,
        headerScript: data.headerScript,
        footerScript: data.footerScript,
      })
      .then((res) => {
        if (res.data.errCode === 0) {
          setErrMessage();
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });
        } else {
          setErrMessage(res.data.message);
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // API

  return (
    <Container fluid className="mt-3">
      <div
        style={{
          backgroundColor: "#26B59C",
          fontSize: "1.5rem",
          color: "white",
        }}
        className="text-center w-100 mb-3 py-3 mt-3"
      >
        Cài đặt công ty
      </div>
      <Card>
        <Card.Body>
          <Form
            onSubmit={handleSubmit((data) => {
              updateSetting(data);
            })}
          >
            {settingFormItems?.map((formItem) => {
              return (
                <Form.Group key={formItem.id} as={Row}>
                  <Form.Label column sm="3" className="fw-bold">
                    {formItem.placeholder}
                  </Form.Label>
                  <Col sm="6">
                    <Input
                      placeholder={formItem.placeholder}
                      name={formItem.name}
                      register={register(formItem.name)}
                      errors={errors[formItem.name]}
                      className="mb-3"
                      as={
                        formItem.id === 11 || formItem.id === 12
                          ? "textarea"
                          : "input"
                      }
                      rows={3}
                    />
                    {errMessage && errMessage[formItem.error] && (
                      <p
                        style={{
                          fontSize: 13,
                          color: "red",
                          marginTop: 6,
                        }}
                      >
                        {errMessage[formItem.error]}
                      </p>
                    )}
                  </Col>
                </Form.Group>
              );
            })}

            <Button
              type="submit"
              size="lg"
              style={{
                backgroundColor: "var(--primary-color)",
                border: 0,
              }}
              className="my-3 w-100"
            >
              Cập nhật
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

const Schema = yup.object().shape({
  name: yup.string().required("Không được bỏ trống"),
  email: yup.string().required("Không được bỏ trống"),
  phonenumber: yup
    .string()
    .required("Không được bỏ trống")
    .phone("Sai định dạng số điện thoại"),
  zalo: yup.string().phone("Sai định dạng số điện thoại"),
});

export default Setting;
