import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "../../../utils/yupGlobal";
import { toast } from "react-toastify";
import Select from "react-select";

import { Button, Form, Modal } from "react-bootstrap";
import TextEditor from "../../../components/TextEditor";
import convertToSlug from "../../../utils/slugFormatter";
import { ACCESS_PERMISSIONS } from "../../../constants";
import CurrencyFormat from "react-currency-format";

const addDocumentFormItems = [
  {
    id: 1,
    title: "Tên tài liệu",
    placeholder: "Nhập tên tài liệu",
    name: "title",
  },
  {
    id: 2,
    title: "Link tài liệu",
    placeholder: "Nhập liên kết của tài liệu đã được upload lên Google Drive",
    name: "description",
  },
];

const DocumentAddModal = ({ handleCloseModal, showModal }) => {
  const [slug, setSlug] = useState("");
  const [content, setContent] = useState("");
  const [isSendForm, setIsSendForm] = useState(false);
  const [errMessage, setErrMessage] = useState({
    postSlug: undefined,
  });
  const [bannerImage, setBannerImage] = useState("");
  const [selectedCategory, setSelectedCategory] = useState();
  const [categoryOptions, setCategoryOptions] = useState([
    { id: 0, cateNameVi: "" },
  ]);
  const [selectedAccessType, setSelectedAccessType] = useState(
    ACCESS_PERMISSIONS.NORMAL
  );
  const accessTypes = [
    { id: 1, name: ACCESS_PERMISSIONS.NORMAL },
    { id: 2, name: ACCESS_PERMISSIONS.VIP },
    { id: 3, name: ACCESS_PERMISSIONS.PREMIUM },
  ];

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(Schema),
  });

  useEffect(() => {
    getAllOptionTopics();
    register("type");
    register("price");
    setValue("price", "");
    setValue("type", selectedAccessType);
  }, []);

  // Api
  const getAllOptionTopics = async () => {
    axios
      .get(`/category-mk/list`)
      .then((res) => {
        if (res.status === 200) {
          setCategoryOptions(
            res.data.data.filter(
              (category) =>
                category.type === process.env.REACT_APP_CATEGORY_TYPE_DOCUMENT
            )
          );
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createDocument = async (data) => {
    setIsSendForm(true);

    var bodyFormData = new FormData();
    bodyFormData.append("categoryId", selectedCategory);
    bodyFormData.append("name", data.title);
    bodyFormData.append("link", data.description);
    bodyFormData.append("type", selectedAccessType);
    bodyFormData.append("content", content);
    bodyFormData.append("slug", slug);
    bodyFormData.append("image", bannerImage);
    bodyFormData.append("price", data.price);

    await axios({
      method: "post",
      url: "document-mk/create",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setIsSendForm(false);
        if (res.status === 200) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });
          handleCloseModal(true);
        } else {
          setErrMessage(res.data.message);
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        setIsSendForm(false);
        setErrMessage(err.response.data.messages);
        console.log(err);
      });
  };
  // Api
  const handleUploadImage = (event) => {
    try {
      if (
        !event.target.files[0].type.match(/image.*/) ||
        event.target.files[0].size > 2097152
      ) {
        toast("File ảnh phải nhỏ hơn bằng 2MB", {
          type: "error",
          autoClose: 1000,
        });

        return;
      }

      setBannerImage(event.target.files[0]);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={() => handleCloseModal(false)}
      backdrop="static"
    >
      <Modal.Header closeButton={!isSendForm}>
        <Modal.Title>Thêm tài liệu</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="fw-bold mb-2">
          Loại tài liệu
          <i className="text-danger">*</i>
        </div>
        <Select
          options={categoryOptions}
          placeholder={`Chọn loại tài liệu`}
          noOptionsMessage={() => "Không có lựa chọn"}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          value={categoryOptions.filter(
            (category) => category.id === selectedCategory
          )}
          onChange={(choice) => {
            setSelectedCategory(choice.id);
          }}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
          className="mb-3"
        />
        <div className="fw-bold mb-2">
          Quyền truy cập
          <i className="text-danger">*</i>
        </div>
        <Select
          options={accessTypes}
          placeholder={`Chọn quyền truy cập`}
          noOptionsMessage={() => "Không có lựa chọn"}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.name}
          value={accessTypes.filter((type) => type.name === selectedAccessType)}
          onChange={(choice) => {
            setSelectedAccessType(choice.name);
            setValue("type", choice.name);
            setValue("price", "");
          }}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
          className="mb-3"
        />
        <Form
          id="add-new-hook-form"
          onSubmit={handleSubmit((data) => {
            if (!selectedCategory) {
              toast("Vui lòng chọn loại tài liệu", {
                type: "error",
                autoClose: 1000,
              });
              return;
            }
            if (!bannerImage) {
              toast("Vui lòng chọn file ảnh", {
                type: "error",
                autoClose: 1000,
              });
              return;
            }
            createDocument(data);
          })}
          className="mb-5"
        >
          {selectedAccessType === ACCESS_PERMISSIONS.PREMIUM ? (
            <div key={"price"} className="mb-3">
              <Form.Label className="fw-bold">
                Phí
                <i className="text-danger">*</i>
              </Form.Label>
              <CurrencyFormat
                className="form-control"
                thousandSeparator={true}
                suffix=" ₫"
                displayType="input"
                onValueChange={(e) => {
                  setValue("price", e.value);
                }}
                placeholder="Nhập số tiền"
              />
              {errors["price"] && (
                <p
                  style={{
                    fontSize: 13,
                    color: "red",
                    marginTop: 6,
                  }}
                >
                  {errors["price"].message}
                </p>
              )}
            </div>
          ) : null}
          {addDocumentFormItems?.map((formItem) => {
            if (formItem.id === 2) {
              return (
                <div key={formItem.id} className="mb-3">
                  <Form.Label className="fw-bold">
                    {formItem.title}
                    <i className="text-danger">*</i>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder={formItem.placeholder}
                    name={formItem.name}
                    rows={3}
                    {...register(formItem.name)}
                  />
                  {errors[formItem.name] && (
                    <p
                      style={{
                        fontSize: 13,
                        color: "red",
                        marginTop: 6,
                      }}
                    >
                      {errors[formItem.name].message}
                    </p>
                  )}
                </div>
              );
            }

            return (
              <div key={formItem.id} className="mb-3">
                <Form.Label className="fw-bold">
                  {formItem.title}
                  <i className="text-danger">*</i>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={formItem.placeholder}
                  name={formItem.name}
                  {...register(formItem.name, {
                    onChange: () => {
                      setSlug(convertToSlug(getValues("title")));
                    },
                  })}
                />

                {errors[formItem.name] && (
                  <p
                    style={{
                      fontSize: 13,
                      color: "red",
                      marginTop: 6,
                    }}
                  >
                    {errors[formItem.name].message}
                  </p>
                )}
              </div>
            );
          })}

          {/* Content */}
          <div className="mb-3">
            <Form.Label className="fw-bold">Mô tả</Form.Label>
            <TextEditor
              setContents={content}
              onChange={(value) => setContent(value)}
              placeholder={"Viết mô tả về tài liệu"}
              style={{ border: "1px solid #dadada", padding: 16 }}
            />
            {errMessage.content && (
              <p
                style={{
                  fontSize: 13,
                  color: "red",
                  marginTop: 6,
                }}
              >
                {errMessage.content}
              </p>
            )}
          </div>

          <div className="mb-3">
            <Form.Floating>
              <Form.Control
                type="text"
                placeholder="Slug"
                name="slug"
                disabled
                value={slug}
              />

              <Form.Label>
                Slug
                <i className="text-danger">*</i>
              </Form.Label>
            </Form.Floating>
            {errMessage.newSlug && (
              <p
                style={{
                  fontSize: 13,
                  color: "red",
                  marginTop: 6,
                }}
              >
                {errMessage.newSlug}
              </p>
            )}
          </div>
        </Form>
        {/* Images */}
        <div>
          <Form.Label className="mb-3 fw-bold">
            Ảnh
            <i className="text-danger">*</i>
          </Form.Label>
          <br></br>
          {bannerImage ? (
            <div className="d-flex justify-content-center">
              <div
                style={{
                  aspectRatio: "1600/500",
                  backgroundImage: `url("${URL.createObjectURL(bannerImage)}")`,
                  backgroundSize: "cover",
                  width: "100%",
                }}
                className="image-default position-relative mb-3"
              />
            </div>
          ) : (
            ""
          )}

          <input
            type="file"
            accept="image/x-png,image/gif,image/jpeg"
            onChange={(e) => handleUploadImage(e)}
            required
          />
          <div>
            <div className="d-inline fw-bold">Note: </div>
            Chọn ảnh với tỉ lệ 1600:500 để được chất lượng tốt nhất.
          </div>
        </div>
        {/* End Images */}
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          variant="secondary"
          onClick={() => handleCloseModal(false)}
          disabled={isSendForm}
        >
          Đóng
        </Button>
        <Button
          type="submit"
          form="add-new-hook-form"
          variant="primary"
          disabled={isSendForm}
        >
          Lưu
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Schema = yup.object().shape({
  title: yup
    .string()
    .required("Không được bỏ trống")
    .test(
      "len",
      "Tiêu đề không dài quá 200 kí tự",
      (val) => val.length >= 0 && val.length <= 200
    ),
  description: yup.string().required("Không được bỏ trống"),
  price: yup.string().when("type", (type) => {
    return yup
      .string()
      .test(
        "len",
        "Phải là số nguyên trong khoảng 0 đến 999999999",
        (value) => {
          if (type[0] !== ACCESS_PERMISSIONS.PREMIUM) {
            return true;
          }
          const priceNum = parseInt(value);
          return (
            !Number.isNaN(priceNum) &&
            value.length > 0 &&
            priceNum <= 999999999 &&
            priceNum >= 0
          );
        }
      );
  }),
});

export default DocumentAddModal;
