import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Icon from "../../components/Icon";
import { Link } from "react-router-dom";
import Logo from "../../assets/image/logo.png";

import React from "react";
import UserButton from "../../components/UserButton";

import MenuItems from "../../components/NavItem/MenuItems";

function UserNavBar({ setShowSideBar, showSideBar, navbarItems }) {
  const depthLevel = 0;

  return (
    <>
      <Navbar expand="lg" className="nav-height w-100 px-0">
        <div className="desktop-nav" style={{ margin: "0 auto" }}>
          <Link
            to="/"
            style={{
              textDecoration: "none",
            }}
            className="align-items-center mx-3"
          >
            <img src={Logo} alt="Mỹ Kat" style={{ height: "3rem" }} />
          </Link>

          <div className="d-flex justify-content-center align-items-center">
            <Navbar.Collapse id="navbarScroll">
              <Nav className="my-2 my-lg-0 align-items-center" navbarScroll>
                {navbarItems.map((item, index) => {
                  return (
                    <MenuItems
                      items={item}
                      key={index}
                      depthLevel={depthLevel}
                    />
                  );
                })}
                <UserButton />
              </Nav>
            </Navbar.Collapse>
          </div>
        </div>

        <Container className="mobile-nav justify-content-between align-items-center w-100">
          <Link
            to="/"
            style={{
              textDecoration: "none",
            }}
          >
            <img src={Logo} alt="Mỹ Kat" style={{ height: "3rem" }} />
          </Link>

          <div className="d-flex align-items-center">
            <div
              onClick={() => {
                setShowSideBar(!showSideBar);
              }}
              className="toggle-button"
            >
              <Icon icon="bars" />
            </div>
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default UserNavBar;
