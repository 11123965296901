import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Components
import UserLayout from "../layouts/user/MasterLayout";
import Login from "../pages/user/Auth/Login";
import CallbackSocialite from "../pages/user/Auth/CallbackSocialite";

import Home from "../pages/user/Home";
import Introduction from "../pages/user/Intro/Introduction";
// import History from "../pages/user/Intro/History";
// import Leader from "../pages/user/Intro/Leader";
import Activity from "../pages/user/Intro/Activity";
import ActivityDetail from "../pages/user/Intro/ActivityDetail";
import Product from "../pages/user/Product/Product";
import ProductDetail from "../pages/user/Product/ProductDetail";
import Contact from "../pages/user/Contact";
import New from "../pages/user/New/New";
import NewDetail from "../pages/user/New/NewDetail";
import Topic from "../pages/user/New/Topic";
import SolutionType from "../pages/user/Solution/SolutionType";
import Solution from "../pages/user/Solution/Solution";
import SolutionDetail from "../pages/user/Solution/SolutionDetail";
import MediaDetail from "../pages/user/Media/MediaDetail";
import Media from "../pages/user/Media/Media";
import Career from "../pages/user/Career/Career";
import CareerDetail from "../pages/user/Career/CareerDetail";
import Document from "../pages/user/Document/Document";
import DocumentDetail from "../pages/user/Document/DocumentDetail";
import Course from "../pages/user/Course/Course";
import CourseDetail from "../pages/user/Course/CourseDetail";

import AdminLayout from "../layouts/admin/MasterLayout";
import Dashboard from "../pages/admin/Dashboard";
import Setting from "../pages/admin/Setting";

import IntroductionAdmin from "../pages/admin/Intro/Introduction";
import HistoryAdmin from "../pages/admin/Intro/History";
import ActivityAdmin from "../pages/admin/Intro/Activity";
import LeaderAdmin from "../pages/admin/Intro/Leader";
import CareerAdmin from "../pages/admin/Career/Career";

import ProductAdmin from "../pages/admin/Product/Product";
import CategoryAdmin from "../pages/admin/Category/Category";
import TopicAdmin from "../pages/admin/Topic/Topic";
import NewAdmin from "../pages/admin/New/New";
import SolutionTypeAdmin from "../pages/admin/SolutionType/SolutionType";
import SolutionAdmin from "../pages/admin/Solution/Solution";
import MediaAdmin from "../pages/admin/Media/Media";
import BannerAdmin from "../pages/admin/Banner/Banner";
import MemberAdmin from "../pages/admin/Member/Member";

import DocumentTypeAdmin from "../pages/admin/DocumentType/DocumentType";
import DocumentAdmin from "../pages/admin/Document/Document";
import CourseTypeAdmin from "../pages/admin/CourseType/CourseType";
import TrainingCourseAdmin from "../pages/admin/TrainingCourse/TrainingCourse";
import CourseAdmin from "../pages/admin/Course/Course";
import Customer from "../pages/admin/Customer/Customer";
import IndexCompanyPersonnel from "../pages/user/Intro/CompanyPersonnel/IndexCompanyPersonnel";

// URLs
import {
  CONTACT_URL,
  INTRODUCTION_URL,
  HISTORY_URL,
  ACTIVITY_URL,
  ACTIVITY_DETAIL_URL,
  MEDIA_DETAIL_URL,
  MEDIA_URL,
  NEW_DETAIL_URL,
  NEW_URL,
  PRODUCT_DETAIL_URL,
  PRODUCT_URL,
  SOLUTION_DETAIL_URL,
  SOLUTION_TYPE_URL,
  SOLUTION_URL,
  TOPIC_URL,
  CAREER_URL,
  CAREER_DETAIL_URL,
  DOCUMENT_URL,
  DOCUMENT_BY_TYPE_URL,
  DOCUMENT_DETAIL_URL,
  EDUCATION_URL,
  COURSE_DETAIL_URL,
  CALLBACK_SOCIALITE_URL,
  COMPANY_PERSONNEL_SLUG,
} from "./routerUrl";
import NotFound from "../pages/NotFound";
import IndexHistory from "../pages/user/Intro/CompanyHistory/IndexHistory";

export default function router() {
  return (
    <>
      <BrowserRouter>
        <ToastContainer position="top-right" />

        <ScrollToTop />
        <Routes>
          <Route path="/" element={<UserLayout />}>
            <Route index element={<Home />} />

            <Route path={"dang-nhap"} element={<Login />} />
            <Route path={INTRODUCTION_URL} element={<Introduction />} />
            <Route path={ACTIVITY_URL} element={<Activity />} />
            <Route path={ACTIVITY_DETAIL_URL} element={<ActivityDetail />} />
            <Route path={HISTORY_URL} element={<IndexHistory />} />
            {/* <Route path={LEADER_URL} element={<Leader />} /> */}
            <Route path={COMPANY_PERSONNEL_SLUG} element={<IndexCompanyPersonnel />} />
            <Route path={PRODUCT_URL} element={<Product />} />
            <Route path={PRODUCT_DETAIL_URL} element={<ProductDetail />} />
            <Route path={TOPIC_URL} element={<Topic />} />
            <Route path={NEW_URL} element={<New />} />
            <Route path={NEW_DETAIL_URL} element={<NewDetail />} />

            <Route path={CAREER_URL} element={<Career />} />
            <Route path={CAREER_DETAIL_URL} element={<CareerDetail />} />

            <Route path={SOLUTION_TYPE_URL} element={<SolutionType />} />
            <Route path={SOLUTION_URL} element={<Solution />} />
            <Route path={SOLUTION_DETAIL_URL} element={<SolutionDetail />} />

            <Route path={MEDIA_URL} element={<Media />} />
            <Route path={MEDIA_DETAIL_URL} element={<MediaDetail />} />

            <Route path={CONTACT_URL} element={<Contact />} />

            <Route path={DOCUMENT_URL} element={<Document />} />
            <Route path={DOCUMENT_BY_TYPE_URL} element={<Document />} />
            <Route path={DOCUMENT_DETAIL_URL} element={<DocumentDetail />} />
            <Route path={EDUCATION_URL} element={<Course />} />
            <Route path={COURSE_DETAIL_URL} element={<CourseDetail />} />
            <Route
              path={CALLBACK_SOCIALITE_URL}
              element={<CallbackSocialite />}
            />

            <Route path="*" element={<NotFound path={"/"} />} />
          </Route>

          <Route path="/admin" element={<AdminLayout />}>
            <Route index element={<Dashboard />} />
            <Route path={`intro`} element={<IntroductionAdmin />} />
            <Route path={`activity`} element={<ActivityAdmin />} />
            <Route path={`history`} element={<HistoryAdmin />} />
            <Route path={`leader`} element={<LeaderAdmin />} />
            <Route path={`career`} element={<CareerAdmin />} />

            <Route path={`category`} element={<CategoryAdmin />} />
            <Route path={`product`} element={<ProductAdmin />} />
            <Route path={`topic`} element={<TopicAdmin />} />
            <Route path={`new`} element={<NewAdmin />} />

            <Route path={`typeSolution`} element={<SolutionTypeAdmin />} />
            <Route path={`solution`} element={<SolutionAdmin />} />
            <Route path={`media`} element={<MediaAdmin />} />
            <Route path={`banner`} element={<BannerAdmin />} />
            <Route path={`member`} element={<MemberAdmin />} />
            <Route path={`setting`} element={<Setting />} />

            <Route path={`typeDocument`} element={<DocumentTypeAdmin />} />
            <Route path={`document`} element={<DocumentAdmin />} />
            <Route path={`typeCourse`} element={<CourseTypeAdmin />} />
            <Route path={`trainingCourse`} element={<TrainingCourseAdmin />} />
            <Route path={`course`} element={<CourseAdmin />} />
            <Route path={`customer`} element={<Customer />} />

            <Route path="*" element={<NotFound path={"/admin"} />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
