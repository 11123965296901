import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Container } from "react-bootstrap";

import ListCourse from "../../../components/ListCourse";

const itemsPerPage = 1000;

const Course = () => {

  const [courses, setCourses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  // const [totalItems, setTotalItems] = useState(1);

  const [isLoading, setIsLoading] = useState(false);
  // const [keyword, setKeyword] = useState("");
  const location = useLocation();
  const lastHash = useRef("");

  useEffect(() => {
    document.title = "Đào tạo";
    // getAllCourses(0, "", 1);
    getAllCourseTypes();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   getAllCourses(selectedCourseType.id, keyword, currentPage);
  // }, [currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    scrollToHash(location);
  }, [location]);

  const scrollToHash = (location) => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    // if (lastHash.current && document.getElementById(lastHash.current)) {
    setTimeout(() => {
      document
        .getElementById(lastHash.current)
        ?.scrollIntoView({ behavior: "smooth", block: "start" });
      lastHash.current = "";
    }, 100);
    // }
  };

  // API
  const getAllCourses = async (type, keyword, page) => {
    setIsLoading(true);
    await axios
      .get(`/course-mk/list`, {
        params: {
          categoryId: type?.id === 0 ? 0 : type?.id,
          page: page,
          limit: itemsPerPage,
          keyword: keyword,
        },
      })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          // setTotalItems(res.data.itemCount);
          setCourses((previous) => [
            ...previous,
            { type: type, courses: res.data.data },
          ]);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCourseTypes = async () => {
    setIsLoading(true);
    await axios
      .get(`/category-mk/list`)
      .then(async (res) => {
        if (res.status === 200) {
          const listTypes = res.data.data.filter(
            (category) =>
              category.type === process.env.REACT_APP_CATEGORY_TYPE_COURSE
          );

          let typeIndex;
          for (typeIndex = 0; typeIndex < listTypes.length; typeIndex++) {
            const type = listTypes[typeIndex];
            await getAllCourses(type, "", 1);

            if (type.children?.length > 0) {
              let i;
              for (i = 0; i < type.children?.length; i++) {
                const subType = type.children[i];
                await getAllCourses(subType, "", 1);
              }
            }
          }

          scrollToHash(location);
          setIsLoading(false);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // API
  return (
    <Container className="container-wrapper">
      <ListCourse
        showRow={2}
        types={courses}
        isLoading={isLoading}
        // itemsCount={totalItems}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isPagiantion={false}
      />
    </Container>
  );
};

export default Course;
