import { Card } from "react-bootstrap";
import "./Style.css";
import { Link } from "react-router-dom";
import Icon from "./Icon";
import Loading from "./Loading";
import NoData from "./NoData";
import MyPagination from "./MyPagination";
import { CAREER } from "../routes/routerUrl";
import moment from "moment";
import "moment/locale/vi";
moment.locale("vi");

const ListCareers = ({
  isLoading,
  careers,
  itemsCount,
  itemsPerPage,
  currentPage,
  setCurrentPage,
  isPagiantion,
}) => {
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : careers?.length > 0 ? (
        careers?.map((career) => {
          return (
            <Card
              style={{
                borderRadius: 0,
                borderStyle: "dotted",
              }}
              key={career.id}
              className="p-2 mb-3"
            >
              <Link
                to={`/${CAREER}/${career.postSlug}`}
                className="text-black d-flex justify-content-between"
              >
                <div className="text-uppercase" style={{ alignSelf: "center" }}>
                  <Icon icon="plus" className="me-3" />
                  {career.postTitleVi}
                </div>
                <div className="d-flex">
                  <div className="me-3" style={{ alignSelf: "center" }}>
                    Hết hạn:
                    {moment(Date.parse(career.postDescVi)).format("DD/MM/YYYY")}
                  </div>
                  <div
                    style={{
                      border: " 1px solid",
                      padding: "3px",
                      margin: "3px",
                    }}
                  >
                    {"Xem chi tiết>"}
                  </div>
                </div>
              </Link>
            </Card>
          );
        })
      ) : (
        <NoData />
      )}

      {isPagiantion && (
        <MyPagination
          itemsCount={itemsCount}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          alwaysShown={false}
        />
      )}
    </>
  );
};

export default ListCareers;
