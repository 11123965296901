import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

import "./styles.css";

const isTokenExpired = (token) =>
  Date.now() >= JSON.parse(atob(token.split(".")[1])).exp * 1000;

function MasterLayout() {
  const sidenavToggle = () => {
    const sbNavFixed = document.getElementById("sb-nav-fixed");

    let testClass = sbNavFixed.className;

    if (
      testClass === "sb-nav-fixed sb-sidenav-toggled" &&
      window.innerWidth < 992
    ) {
      sbNavFixed.classList.remove("sb-sidenav-toggled");
    }
  };

  useEffect(() => {
    const isAuthenticated = !!localStorage.getItem("access_token");
    if (!isAuthenticated) return (window.location.href = "/dang-nhap");

    if (isTokenExpired(localStorage.getItem("access_token"))) {
      localStorage.removeItem("access_token");
      window.location.href = "/dang-nhap";
    }
  }, []);

  return (
    <div
      style={{ backgroundColor: "#f8f9fa" }}
      className="sb-nav-fixed"
      id="sb-nav-fixed"
    >
      <Navbar />

      <div id="layoutSidenav">
        <div id="layoutSidenav_nav">
          <Sidebar />
        </div>

        <div id="layoutSidenav_content" onClick={sidenavToggle}>
          <main>
            <Outlet />
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default MasterLayout;
