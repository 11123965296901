import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MEDIA } from "../routes/routerUrl";
import Loading from "./Loading";
import NoData from "./NoData";
import MyPagination from "./MyPagination";
import ReactPlayer from "react-player";

import "./Style.css";

const itemsPerPage = 16;

const ListMedia = ({ isPagiantion }) => {
	const [medias, setMedias] = useState([]);

	const [currentPage, setCurrentPage] = useState(1);
	const [totalItems, setTotalItems] = useState(1);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		getAllMedias();
	}, []);

	useEffect(() => {
		getAllMedias(currentPage);
	}, [currentPage]);

	const getAllMedias = async (page) => {
		setIsLoading(true);
		await axios
			.get(`/video/list`, {
				params: { isType: "vOther", limit: itemsPerPage, page },
			})
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setTotalItems(res.data.itemCount);
					setMedias(res.data.videos);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<Row>
			{isLoading ? (
				<Loading />
			) : medias?.length > 0 ? (
				medias?.map((media) => {
					return (
						<Col
							key={media.id}
							xs="12"
							sm="6"
							md="3"
							className="mb-5 mb-md-4"
						>
							<div
								style={{
									aspectRatio: "16/9",
									position: "relative",
									width: "100%",
								}}
							>
								<Link to={`/${MEDIA}/${media.id}`}>
									<div
										style={{
											position: "absolute",
											zIndex: "999",
											width: "100%",
											height: "100%",
										}}
									/>
								</Link>
								<ReactPlayer
									url={media.embedCode}
									width="100%"
									height="100%"
								/>
							</div>

							<div>
								<div className="fw-bold my-2">
									<Link
										to={`/${MEDIA}/${media.id}`}
										className="text-black"
									>
										{media.videoTitleVi}
									</Link>
								</div>
								<div>Chuyên mục</div>
							</div>
						</Col>
					);
				})
			) : (
				<NoData />
			)}

			{isPagiantion && (
				<MyPagination
					itemsCount={totalItems}
					itemsPerPage={itemsPerPage}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					alwaysShown={false}
				/>
			)}
		</Row>
	);
};

export default ListMedia;
