import React from "react";
import { Col, Container, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import Icon from "../../components/Icon";
import PhoneIcon from "../../assets/image/icon-phone.png";
import { Link } from "react-router-dom";
import { CONTACT_URL, CAREER, PROJECT } from "../../routes/routerUrl";
import {
  faFacebookF,
  faTwitter,
  faLinkedin,
  faPinterest,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const socialNetwork = [
  {
    id: 1,
    title: "facebook",
    lable: "Chia sẻ trên Facebook",
    link: "https://www.facebook.com/sharer.php?u=https://mykatcons.vn/",
    icon: faFacebookF,
  },
  {
    id: 2,
    title: "twitter",
    lable: "Chia sẻ trên Twitter",
    link: "https://twitter.com/share?url=https://mykatcons.vn/",
    icon: faTwitter,
  },
  {
    id: 3,
    title: "email",
    lable: "Email cho bạn bè",
    link: "mailto:?subject=Trang%20ch%E1%BB%A7&amp;body=H%C3%A3y%20ki%E1%BB%83m%20tra%20%C4%91i%E1%BB%81u%20n%C3%A0y%3A%20https%3A%2F%2Fmykatcons.vn%2F",
    icon: "envelope",
  },
  {
    id: 4,
    title: "pinterest",
    lable: "Ghim trên Pinterest",
    link: "https://pinterest.com/pin/create/button?url=https://mykatcons.vn//&amp;media&amp;description=Trang%20ch%E1%BB%A7",
    icon: faPinterest,
  },
  {
    id: 5,
    title: "linkedin",
    lable: "Chia sẻ trên LinkedIn",
    link: "https://www.linkedin.com/shareArticle?mini=true&amp;url=https://mykatcons.vn/&amp;title=Trang%20ch%E1%BB%A7",
    icon: faLinkedin,
  },
];

const links = [
  {
    id: 1,
    title: "Mỹ Kat Organic",
    link: "https://mykat.vn/",
  },
  {
    id: 2,
    title: "Thông tin tuyển dụng",
    link: CAREER,
  },
  {
    id: 3,
    title: "Chính sách bảo mật",
    link: PROJECT,
  },
  {
    id: 4,
    title: "Liên hệ",
    link: CONTACT_URL,
  },
];
const Footer = ({ shop }) => {
  return (
    <footer className="text-start bg-light">
      {/* Information */}
      <section className="py-4">
        <Container>
          <Row className="justify-content-center">
            <Col xs="11" md="3" className={"py-3"}>
              <div className="fw-bold d-inline">GIỚI THIỆU</div>
              <p>CÔNG TY TNHH THƯƠNG MẠI VÀ XÂY DỰNG MỸ KAT</p>
              <div className="d-flex">
                {socialNetwork.map((item) => {
                  return (
                    <OverlayTrigger
                      overlay={<Tooltip id={item.id}>{item.lable}</Tooltip>}
                    >
                      <a
                        href={item.link}
                        onclick="window.open(this.href,this.title,'width=500,height=500,top=300px,left=300px'); return false;"
                        target="_blank"
                        rel="noopener nofollow"
                        className={`icon-circle-outline ${item.title}`}
                      >
                        <Icon icon={item.icon} />
                      </a>
                    </OverlayTrigger>
                  );
                })}
              </div>
            </Col>
            <Col xs="11" md="3" className={"py-3"}>
              <div className="fw-bold d-inline">LIÊN KẾT</div>
              <Row>
                {links.map((item) => {
                  return (
                    <Link to={item.link} className="footer-menu-item">
                      {item.title}
                    </Link>
                  );
                })}
              </Row>
            </Col>
            <Col xs="11" md="3" className={"py-3"}>
              <div className="fw-bold d-inline">TRỤ SỞ CHÍNH</div>
              <div class="textwidget">
                <p>
                  {shop.shopAddressVi}
                  <br></br>
                  ĐT: {shop.shopPhone}
                  <br></br>
                  Email: {shop.shopEmail}
                </p>
              </div>
            </Col>
            <Col xs="11" md="3" className={"py-3"}>
              <iframe
                title="safe life"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4672.558635597106!2d105.78264060725392!3d10.005473860666!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31a063e2ed971a85%3A0x2e5c38e076c5da7b!2zQ8OUTkcgVFkgVE5ISCBUSMavxqBORyBN4bqgSSBWw4AgWMOCWSBE4buwTkcgTeG7uCBLQVQ!5e0!3m2!1sen!2s!4v1717665522670!5m2!1sen!2s"
                width="100%"
                height="200"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </Col>
          </Row>
        </Container>
      </section>

      {/* Copyright */}
      <section
        className="py-3"
        style={{
          borderTop: "1px solid #ebebeb",
          backgroundColor: "#2d3e4b",
          color: "hsla(0, 0%, 100%, .5)",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col
              md="3"
              xs="11"
              style={{
                textAlign: "center",
                alignSelf: "center",
              }}
            >
              <a href={`tel:${shop.shopPhone}`}>
                <img
                  data-lazyloaded="1"
                  src={PhoneIcon}
                  data-src={PhoneIcon}
                  data-ll-status="loaded"
                  style={{ width: "40px" }}
                />
              </a>
              <span className="text-line">Hotline: {shop.shopPhone}</span>
            </Col>
            <Col
              md="4"
              xs="11"
              className="d-flex justify-content-center mb-3 mt-3"
            >
              <span className="text-line" style={{ alignSelf: "center" }}>
                Developed by Katec
              </span>
            </Col>
            <Col
              md="3"
              xs="11"
              style={{
                textAlign: "center",
                alignSelf: "center",
              }}
            >
              <span className="text-line">Kết nối với Mỹ Kat :</span>
              <OverlayTrigger
                overlay={
                  <Tooltip id="facebook">Theo dõi trên Facebook</Tooltip>
                }
              >
                <a
                  href={shop.shopDescVi}
                  target="_blank"
                  rel="noopener nofollow"
                  className={"icon-circle facebook"}
                >
                  <Icon
                    icon={faFacebookF}
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </a>
              </OverlayTrigger>
              <OverlayTrigger
                overlay={
                  <Tooltip id={"youtube"}>Theo dõi trên YouTube</Tooltip>
                }
              >
                <a
                  href={shop.shopAddressEn}
                  target="_blank"
                  rel="noopener nofollow"
                  className={"icon-circle youtube"}
                >
                  <Icon
                    icon={faYoutube}
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </a>
              </OverlayTrigger>
            </Col>
          </Row>
        </Container>
      </section>
    </footer>
  );
};

export default Footer;
