import React from "react";
import { Link, useLocation } from "react-router-dom";
import Icon from "../../components/Icon";

const navbarItems = [
  {
    id: 0,
    label: "Tổng quan",
    icon: "gauge",
    link: "",
  },
  {
    id: 1,
    label: "Giới thiệu",
    icon: "circle-info",
    link: "intro",
  },
  // {
  //   id: 2,
  //   label: "Lịch sử",
  //   icon: "network-wired",
  //   link: "history",
  // },
  // {
  //   id: 3,
  //   label: "Nhân sự công ty",
  //   icon: "network-wired",
  //   link: "leader",
  // },
  {
    id: 4,
    label: "Lĩnh vực",
    icon: "network-wired",
    link: "activity",
  },
  {
    id: 5,
    label: "Dự án",
    icon: "building",
    link: "product",
  },
  {
    id: 6,
    label: "Tuyển dụng",
    icon: "newspaper",
    link: "career",
  },
  {
    id: 7,
    label: "Tài liệu",
    icon: "file-lines",
    link: "document",
  },
  {
    id: 8,
    label: "Khóa học",
    icon: "file-video",
    link: "course",
  },
  {
    id: 9,
    label: "Khách hàng",
    icon: "user",
    link: "customer",
  },
  {
    id: 10,
    label: "Tin tức",
    icon: "newspaper",
    link: "new",
  },
];

const settingItems = [
  {
    id: 0,
    label: "Công ty",
    icon: "house",
    link: "setting",
  },
  {
    id: 1,
    label: "Banner",
    icon: "image",
    link: "banner",
  },
  {
    id: 2,
    label: "Danh mục dự án",
    icon: "building",
    link: "category",
  },
  {
    id: 3,
    label: "Danh mục tài liệu",
    icon: "file-lines",
    link: "typeDocument",
  },
  {
    id: 4,
    label: "Danh mục khóa học",
    icon: "file-video",
    link: "typeCourse",
  },
  {
    id: 5,
    label: "Khóa đào tạo",
    icon: "school",
    link: "trainingCourse",
  },

  {
    id: 6,
    label: "Danh mục tin tức",
    icon: "newspaper",
    link: "topic",
  },
];

const Sidebar = () => {
  const location = useLocation();

  return (
    <nav
      className="sb-sidenav accordion sb-sidenav-light"
      id="sidenavAccordion"
    >
      <div className="sb-sidenav-menu">
        <div className="nav">
          {navbarItems.map((item) => {
            return (
              <Link
                key={item.id}
                to={`/admin/${item.link}`}
                className={`nav-link nav-link-hover ${item.id === 0
                  ? location.pathname === "/admin" ||
                    location.pathname === "/admin/"
                    ? "nav-link-on-hover active"
                    : ""
                  : location.pathname.startsWith(`/admin/${item.link}`)
                    ? "nav-link-on-hover active"
                    : ""
                  }`}
              >
                <div className="sb-nav-link-icon me-2">
                  <Icon icon={item.icon} />
                </div>
                {item.label}
              </Link>
            );
          })}

          <Link
            className="nav-link-hover nav-link collapsed"
            data-bs-toggle="collapse"
            data-bs-target={`#setting`}
            aria-expanded="false"
            aria-controls={`setting`}
          >
            <div className="sb-nav-link-icon">
              <Icon icon="gear" />
            </div>
            Cài đặt
            <div className="sb-sidenav-collapse-arrow">
              <Icon icon="chevron-down" />
            </div>
          </Link>
          <div id={`setting`} className="collapse" aria-labelledby="headingOne">
            <nav className="sb-sidenav-menu-nested nav">
              {settingItems.map((item) => {
                return (
                  <Link
                    key={item.id}
                    to={item.link}
                    className={`nav-link nav-link-hover ${location.pathname.startsWith(`/admin/${item.link}`)
                      ? "nav-link-on-hover active"
                      : ""
                      }`}
                  >
                    <div className="sb-nav-link-icon">
                      <Icon icon={item.icon} />
                    </div>
                    {item.label}
                  </Link>
                );
              })}
            </nav>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
