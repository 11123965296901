import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";

import CourseTypeAddModal from "./CourseTypeAddModal";
import CourseTypeEditModal from "./CourseTypeEditModal";
import Icon from "../../../components/Icon";
import Loading from "../../../components/Loading";
import DataTables from "../../../components/DataTables";
import DateFormat from "../../../components/DateFormat";

const CourseType = () => {
  const [courseTypes, setCourseTypes] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedCourseType, setSelectedCourseType] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [parentCate, setParentCate] = useState({});

  useEffect(() => {
    document.title = "Loại khóa học";
    getAllCourseTypes();
  }, []);

  // API
  const getAllCourseTypes = async () => {
    setIsLoading(true);
    axios
      .get(`/category-mk/list`)
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          setCourseTypes(
            res.data.data.filter(
              (category) =>
                category.type === process.env.REACT_APP_CATEGORY_TYPE_COURSE
            )
          );
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteCourseType = async (courseTypeId) => {
    await axios
      .delete(`/category/destroy/${courseTypeId}`)
      .then((res) => {
        if (res.status === 200) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });

          getAllCourseTypes();
        }
      })
      .catch((err) => {
        toast(err.response.data.message, {
          type: "error",
          autoClose: 1000,
        });
        console.log(err);
      });
  };
  // Api

  const handleDeleteCourseType = (courseTypeId) => {
    swal({
      title: `Bạn muốn xóa loại khóa học này`,
      text: ` Lưu ý: Hành động không thể khôi phục lại`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        deleteCourseType(courseTypeId);
      }
    });
  };

  const handleCloseAddModal = (isReload) => {
    setShowAddModal(false);
    if (isReload) {
      getAllCourseTypes();
    }
  };

  const handleShowAddModal = (parentCate) => {
    setShowAddModal(true);
    setSelectedCourseType();
    setParentCate(parentCate);
  };

  const handleCloseEditModal = (isReload) => {
    setShowEditModal(false);
    if (isReload) {
      getAllCourseTypes();
    }
  };
  const handleShowEditModal = (courseType) => {
    setShowEditModal(true);
    setParentCate(
      courseTypes.find((type) => type.id === courseType.cateParentId)
    );
    setSelectedCourseType(courseType);
  };

  const columns = [
    {
      name: "Tên loại khóa học",
      selector: (row) => row.name,
      wrap: true,
    },
    {
      name: "Cập nhật",
      selector: (row) => <DateFormat date={row.updatedAt} />,
    },
    {
      name: "Phương thức",
      style: {
        display: "flex",
        justifyContent: "center",
      },
      selector: (row) => {
        const disabledDeleteButton = row.children?.length > 0;
        return (
          <>
            <Button
              size="sm"
              variant="primary me-2"
              type="button"
              onClick={() => handleShowAddModal(row)}
            >
              <Icon icon="plus" />
            </Button>
            <Button
              size="sm"
              variant="warning me-2"
              type="button"
              onClick={() => handleShowEditModal(row)}
            >
              <Icon icon="pencil" />
            </Button>

            <Button
              size="sm"
              variant="danger"
              type="button"
              onClick={() => {
                if (disabledDeleteButton) {
                  toast("Không thể xóa", {
                    type: "error",
                    autoClose: 1000,
                  });
                } else {
                  handleDeleteCourseType(row.id);
                }
              }}
              disabled={disabledDeleteButton}
            >
              <Icon icon="trash" />
            </Button>
          </>
        );
      },
    },
  ];

  const subColumns = [
    {
      name: "Tên loại khóa học",
      selector: (row) => row.name,
      wrap: true,
    },
    {
      name: "Cập nhật",
      selector: (row) => <DateFormat date={row.updatedAt} />,
    },
    {
      name: "Phương thức",
      style: {
        display: "flex",
        justifyContent: "center",
      },
      selector: (row) => (
        <>
          <Button
            size="sm"
            variant="warning me-2"
            type="button"
            onClick={() => handleShowEditModal(row)}
          >
            <Icon icon="pencil" />
          </Button>

          <Button
            size="sm"
            variant="danger"
            type="button"
            onClick={() => handleDeleteCourseType(row.id)}
          >
            <Icon icon="trash" />
          </Button>
        </>
      ),
    },
  ];

  const SubCategory = ({ data }) => {
    if (!data) {
      return <i className="text-muted ms-5">Không có dữ liệu!</i>;
    }

    return (
      <DataTables className="ms-5" data={data.children} columns={subColumns} />
    );
  };

  return (
    <Container fluid>
      <div
        style={{
          backgroundColor: "#26B59C",
          fontSize: "1.5rem",
          color: "white",
        }}
        className="text-center w-100 mb-3 py-3 mt-3"
      >
        Loại khóa học
      </div>

      <div className="d-block d-md-flex justify-content-between  mb-3">
        <div className="mt-3 mt-md-0">
          <Button
            variant="primary"
            className="mt-2 mt-md-0"
            onClick={() => handleShowAddModal()}
          >
            <Icon icon="plus" /> {"Thêm mới"}
          </Button>
        </div>
      </div>

      {isLoading ? (
        <Loading />
      ) : (
        <DataTables
          data={courseTypes}
          columns={columns}
          expandableRows
          expandableRowsComponent={SubCategory}
        />
      )}

      {showAddModal ? (
        <CourseTypeAddModal
          handleCloseModal={handleCloseAddModal}
          showModal={showAddModal}
          parentCate={parentCate}
        />
      ) : (
        ""
      )}

      {showEditModal ? (
        <CourseTypeEditModal
          handleCloseModal={handleCloseEditModal}
          showModal={showEditModal}
          selectedCourseType={selectedCourseType}
          parentCate={parentCate}
        />
      ) : (
        ""
      )}
    </Container>
  );
};

export default CourseType;
