import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";

import DocumentTypeAddModal from "./DocumentTypeAddModal";
import DocumentTypeEditModal from "./DocumentTypeEditModal";
import Icon from "../../../components/Icon";
import Loading from "../../../components/Loading";
import DataTables from "../../../components/DataTables";
import DateFormat from "../../../components/DateFormat";

const DocumentType = () => {
  const [documentTypes, setDocumentTypes] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedDocumentType, setSelectedDocumentType] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = "Loại tài liệu";
    getAllDocumentTypes();
  }, []);

  // API
  const getAllDocumentTypes = async () => {
    setIsLoading(true);
    axios
      .get(`/category-mk/list`)
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          setDocumentTypes(
            res.data.data.filter(
              (category) =>
                category.type === process.env.REACT_APP_CATEGORY_TYPE_DOCUMENT
            )
          );
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteDocumentType = async (documentTypeId) => {
    await axios
      .delete(`/category/destroy/${documentTypeId}`)
      .then((res) => {
        if (res.status === 200) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });

          getAllDocumentTypes();
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Api

  const handleDeleteDocumentType = (documentTypeId) => {
    swal({
      title: `Bạn muốn xóa loại tài liệu này`,
      text: ` Lưu ý: Hành động không thể khôi phục lại`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        deleteDocumentType(documentTypeId);
      }
    });
  };

  const handleCloseAddModal = (isReload) => {
    setShowAddModal(false);
    if (isReload) {
      getAllDocumentTypes();
    }
  };

  const handleShowAddModal = () => {
    setShowAddModal(true);
    setSelectedDocumentType();
  };

  const handleCloseEditModal = (isReload) => {
    setShowEditModal(false);
    if (isReload) {
      getAllDocumentTypes();
    }
  };
  const handleShowEditModal = (documentType) => {
    setShowEditModal(true);
    setSelectedDocumentType(documentType);
  };

  const columns = [
    {
      name: "Tên loại tài liệu",
      selector: (row) => row.name,
      wrap: true,
    },
    {
      name: "Cập nhật",
      selector: (row) => <DateFormat date={row.updatedAt} />,
    },
    {
      name: "Phương thức",
      style: {
        display: "flex",
        justifyContent: "center",
      },
      selector: (row) => (
        <>
          <Button
            size="sm"
            variant="warning me-2"
            type="button"
            onClick={() => handleShowEditModal(row)}
          >
            <Icon icon="pencil" />
          </Button>

          <Button
            size="sm"
            variant="danger"
            type="button"
            onClick={() => handleDeleteDocumentType(row.id)}
          >
            <Icon icon="trash" />
          </Button>
        </>
      ),
    },
  ];

  return (
    <Container fluid>
      <div
        style={{
          backgroundColor: "#26B59C",
          fontSize: "1.5rem",
          color: "white",
        }}
        className="text-center w-100 mb-3 py-3 mt-3"
      >
        Loại tài liệu
      </div>

      <div className="d-block d-md-flex justify-content-between  mb-3">
        <div className="mt-3 mt-md-0">
          <Button
            variant="primary"
            className="mt-2 mt-md-0"
            onClick={() => handleShowAddModal()}
          >
            <Icon icon="plus" /> {"Thêm mới"}
          </Button>
        </div>
      </div>

      {isLoading ? (
        <Loading />
      ) : (
        <DataTables data={documentTypes} columns={columns} />
      )}

      {showAddModal ? (
        <DocumentTypeAddModal
          handleCloseModal={handleCloseAddModal}
          showModal={showAddModal}
        />
      ) : (
        ""
      )}

      {showEditModal ? (
        <DocumentTypeEditModal
          handleCloseModal={handleCloseEditModal}
          showModal={showEditModal}
          selectedDocumentType={selectedDocumentType}
        />
      ) : (
        ""
      )}
    </Container>
  );
};

export default DocumentType;
