import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Col, Container, Form } from "react-bootstrap";

import ListProduct from "../../../components/ListProduct";
import Select from "react-select";

const itemsPerPage = 16;

const Product = () => {
  const [categoryOptions, setCategoryOptions] = useState([
    { id: 0, cateNameVi: "Tất cả", cateSlug: "tat-ca" },
  ]);
  const [selectedCategory, setSelectedCategory] = useState({
    id: 0,
    cateNameVi: "Tất cả",
    cateSlug: "tat-ca",
  });

  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);

  const [isLoading, setIsLoading] = useState(false);
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    document.title = "Sản phẩm";
    getAllProducts("tat-ca", "", 1);
    getAllOptionCategories();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getAllProducts(selectedCategory.cateSlug, keyword, currentPage);
  }, [currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  // API
  const getAllProducts = async (slug, keyword, page) => {
    setIsLoading(true);
    await axios
      .get(`/product/list`, {
        params: {
          cateSlug: slug === "tat-ca" ? "" : slug,
          page: page,
          limit: itemsPerPage,
          keyword: keyword,
        },
      })
      .then((res) => {
        setIsLoading(false);

        if (res.data.errCode === 0) {
          setTotalItems(res.data.itemCount);
          setProducts(
            res.data.products.map((product) => {
              return {
                ...product,
                imgPaths: product.imgPaths ? product.imgPaths.split(",") : [],
              };
            })
          );
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllOptionCategories = () => {
    axios
      .get(`/category/list`, {
        params: {
          isType: "cPro",
          isTree: 1,
        },
      })
      .then((res) => {
        if (res.data.errCode === 0) {
          const categoryOptions = [];
          const categories = res.data.categories[0].children;

          categories.map((category) => {
            categoryOptions.push(category);

            return category.children.map((categorySub) => {
              categoryOptions.push({
                ...categorySub,
                cateNameVi: "- " + categorySub.cateNameVi,
              });

              return categorySub.children.map((categorySenSub) => {
                categoryOptions.push({
                  ...categorySenSub,
                  cateNameVi: "- - " + categorySenSub.cateNameVi,
                });

                return categorySenSub;
              });
            });
          });

          setCategoryOptions(
            [{ id: 0, cateNameVi: "Tất cả", cateSlug: "tat-ca" }].concat(
              categoryOptions
            )
          );
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // API

  console.log(categoryOptions);

  return (
    <Container className="container-wrapper">
      <div className="d-block d-md-flex justify-content-between my-3">
        <Col xs="12" md="4">
          <Select
            options={categoryOptions}
            placeholder={`Chọn danh mục dự án`}
            noOptionsMessage={() => "Không có lựa chọn"}
            getOptionLabel={(option) => option.cateNameVi}
            getOptionValue={(option) => option.id}
            value={selectedCategory}
            onChange={(choice) => {
              getAllProducts(choice.cateSlug, "", 1);

              setSelectedCategory(choice);
              setCurrentPage(1);
              setKeyword("");
            }}
            menuPortalTarget={document.body}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
              }),
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              option: (baseStyles, state) => ({
                ...baseStyles,
                paddingTop: 0,
                paddingBottom: 0,
              }),
            }}
          />
        </Col>

        <Form
          onSubmit={(e) => {
            e.preventDefault();
            getAllProducts(selectedCategory.cateSlug, keyword, 1);

            setCurrentPage(1);
          }}
        >
          <Form.Control
            placeholder="Tìm kiếm dự án"
            style={{ borderRadius: 0 }}
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
            className="mt-3 mt-md-0"
          />
        </Form>
      </div>

      <ListProduct
        showRow={4}
        products={products}
        isLoading={isLoading}
        itemsCount={totalItems}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isPagiantion={true}
      />
    </Container>
  );
};

export default Product;
