import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import yup from "../../../utils/yupGlobal";
import { yupResolver } from "@hookform/resolvers/yup";
import convertToSlug from "../../../utils/slugFormatter";
import axios from "axios";
import { toast } from "react-toastify";
const TrainingCourseAddModal = ({
  handleCloseModal,
  showModal,
  selectedTrainingCourse,
  isEdit = false,
}) => {
  const [isSendForm, setIsSendForm] = useState(false);
  const [slug, setSlug] = useState("");
  const [errMessage, setErrMessage] = useState({
    cateSlug: undefined,
  });

  const {
    reset,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(Schema),
  });

  useEffect(() => {
    if (isEdit) {
      reset({
        name: selectedTrainingCourse.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const createTrainingCourse = async (data) => {
    setIsSendForm(true);

    await axios
      .post(`/training-course/create`, {
        name: data.name,
      })
      .then((res) => {
        setIsSendForm(false);
        if (res.status === 200) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });
          handleCloseModal(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateTrainningCourse = async (data) => {
    setIsSendForm(true);
    await axios
      .put(`/training-course/update`, {
        traningCourseId: selectedTrainingCourse.id,
        name: data.name,
      })
      .then((res) => {
        setIsSendForm(false);
        if (res.status === 200) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });
          handleCloseModal(true);
        } else {
          setErrMessage(res.data.message);
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Modal show={showModal} onHide={() => handleCloseModal(false)}>
      <Modal.Header closeButton={!isSendForm}>
        <Modal.Title>
          {isEdit ? "Cập nhật khóa đào tạo" : "Thêm khóa đào tạo"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          id="add-tranningCourse"
          onSubmit={handleSubmit((data) => {
            isEdit ? updateTrainningCourse(data) : createTrainingCourse(data);
          })}
        >
          <div className="mb-3">
            <Form.Floating>
              <Form.Control
                type="text"
                placeholder={`Tên khóa`}
                name={`name`}
                {...register(`name`, {
                  onChange: () => {
                    setSlug(convertToSlug(getValues("name")));
                  },
                })}
              />
              <Form.Label>
                {`Tên khóa`}
                <i className="text-danger">*</i>
              </Form.Label>
            </Form.Floating>
            {errors[`name`] && (
              <p
                style={{
                  fontSize: 13,
                  color: "red",
                  marginTop: 6,
                }}
              >
                {errors[`name`].message}
              </p>
            )}
          </div>

          <div>
            <Form.Floating>
              <Form.Control
                type="text"
                placeholder="Slug"
                name="slug"
                disabled
                value={slug}
              />
              <Form.Label>
                Slug
                <i className="text-danger">*</i>
              </Form.Label>
            </Form.Floating>
            {errMessage.cateSlug && (
              <p style={{ fontSize: 13, color: "red", marginTop: 6 }}>
                {errMessage.cateSlug}
              </p>
            )}
          </div>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          variant="secondary"
          onClick={() => handleCloseModal(false)}
          disabled={isSendForm}
        >
          Đóng
        </Button>

        <Button
          type="submit"
          variant="primary"
          form="add-tranningCourse"
          disabled={isSendForm}
        >
          {isEdit ? "Cập nhật" : "Lưu"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Schema = yup.object().shape({
  name: yup
    .string()
    .required("Không được bỏ trống")
    .test(
      "len",
      "Tên khóa không dài quá 255 kí tự",
      (val) => val.length >= 0 && val.length <= 255
    ),
});

export default TrainingCourseAddModal;
