import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Button, Col, Container, Row } from "react-bootstrap";
import swal from "sweetalert";
import Dropdown from "react-bootstrap/Dropdown";
import DataTables from "../../../components/DataTables";
import Icon from "../../../components/Icon";
import CourseAddModal from "./CourseAddModal";
import CourseEditModal from "./CourseEditModel";
import LessonAddModal from "./LessonAddModal";
import LessonEditModal from "./LessonEditModal";
import Loading from "../../../components/Loading";
import DateFormat from "../../../components/DateFormat";
import { ACCESS_PERMISSIONS } from "../../../constants";
import StudentCourseModal from "./StudentCourseModal";
import ImageURL from "../../../components/ImageURL";

const Course = () => {
  const [courses, setCourses] = useState([]);
  const [showAddCourseModal, setShowAddCourseModal] = useState(false);
  const [showEditCourseModal, setShowEditCourseModal] = useState(false);
  const [showAddLessonModal, setShowAddLessonModal] = useState(false);
  const [showEditLessonModal, setShowEditLessonModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [courseId, setCourseId] = useState(null);
  const [isAddModal, setIsAddModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState({
    id: 0,
    parentId: 0,
  });
  const [selectedLesson, setSelectedLesson] = useState({
    id: 0,
    parentId: 0,
  });

  const [showStudentModal, setShowStudentModal] = useState(false);

  useEffect(() => {
    getAllCourses();
  }, []);

  // API
  const getAllCourses = async (keyword) => {
    setIsLoading(true);
    await axios
      .get(`/course-mk/list`, {
        params: {
          keyword: keyword,
        },
      })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          setCourses(res.data.data);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
          type: "error",
          autoClose: 1000,
        });
        console.log(err);
      });
  };

  const deleteCourse = async (courseId) => {
    await axios
      .delete(`/course/destroy/${courseId}`)
      .then((res) => {
        if (res.status === 200) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });

          getAllCourses();
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteLesson = async (lesson) => {
    const course = courses.find((e) => e.id === lesson.courseId);
    const content = JSON.parse(course.content);
    content.splice(lesson.index, 1);
    const bodyFormData = new FormData();
    bodyFormData.append("courseId", course.id);
    bodyFormData.append("categoryId", course.categoryId);
    bodyFormData.append("name", course.name);
    bodyFormData.append("description", course.description);
    bodyFormData.append("content", JSON.stringify(content));
    bodyFormData.append("postSlug", course.slug);
    bodyFormData.append("isStatus", "S1");

    await axios({
      method: "put",
      url: "course-mk/update",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setIsLoading(false);

        if (res.status === 200) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });

          getAllCourses();
        } else {
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
          type: "error",
          autoClose: 1000,
        });
        setIsLoading(false);
        console.log(err);
      });
  };
  // Api

  const handleDeleteCourse = (courseId) => {
    swal({
      title: `Bạn muốn xóa khóa học này`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        deleteCourse(courseId);
      }
    });
  };

  const handleCloseAddCourseModal = (isReload) => {
    setShowAddCourseModal(false);
    if (isReload) {
      getAllCourses();
    }
  };

  const handleShowAddCourseModal = () => {
    setShowAddCourseModal(true);
    setSelectedCourse();
  };

  const handleCloseEditCourseModal = (isReload) => {
    setShowEditCourseModal(false);
    if (isReload) {
      getAllCourses();
    }
  };

  const handleShowEditCourseModal = (course) => {
    setShowEditCourseModal(true);
    setSelectedCourse(course);
  };

  const handleCloseAddLessonModal = (isReload) => {
    setShowAddLessonModal(false);
    if (isReload) {
      getAllCourses();
    }
  };

  const handleShowAddLessonModal = (course) => {
    setShowAddLessonModal(true);
    setSelectedCourse(course);
  };

  const handleCloseEditLessonModal = (isReload) => {
    setShowEditLessonModal(false);
    if (isReload) {
      getAllCourses();
    }
  };

  const handleShowEditLessonModal = (lesson) => {
    setShowEditLessonModal(true);
    setSelectedLesson(lesson);
  };

  const handleDeleteLesson = (lesson) => {
    swal({
      title: `Bạn muốn xóa bài học này`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        deleteLesson(lesson);
      }
    });
  };

  const handleCloseStudentModal = (isReload) => {
    setShowStudentModal(false);
    if (isReload) getAllCourses();
  };

  const handleShowStudentModal = (courseId) => {
    setCourseId(courseId);
    setIsAddModal(false);
    setShowStudentModal(true);
  };

  const handleShowStudentAddModal = (courseId) => {
    setCourseId(courseId);
    setIsAddModal(true);
    setShowStudentModal(true);
  };

  const columns = [
    {
      name: "Quyền truy cập",
      selector: (row) =>
        row.type === ACCESS_PERMISSIONS.VIP
          ? ACCESS_PERMISSIONS.VIP
          : ACCESS_PERMISSIONS.NORMAL,
      wrap: true,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Ảnh",
      style: {
        display: "flex",
        justifyContent: "center",
      },
      selector: (row) => (
        <ImageURL
          style={{
            aspectRatio: "9/6",
            height: "4rem",
            backgroundSize: "cover",
          }}
          imageURL={`/${row.image}`}
        />
      ),
      wrap: true,
    },
    {
      name: "Tên khóa học",
      selector: (row) => row.name,
      wrap: true,
      sortable: true,
    },
    {
      name: "Cập nhật",
      selector: (row) => row.updatedAt,
      sortable: true,
      cell: (row) => <DateFormat date={row.updatedAt} />
    },
    {
      name: "Phương thức",
      center: true,
      cell: (row) => {
        const disabledDeleteButton = JSON.parse(row.content).length !== 0;
        return (
          <>
            <Button
              title="Thêm bài học"
              size="sm"
              variant="primary m-2"
              type="button"
              onClick={() => handleShowAddLessonModal(row)}
            >
              <Icon icon="plus" />
              {/* Thêm bài học */}
            </Button>
            <Button
              size="sm"
              variant="warning me-2"
              type="button"
              onClick={() => handleShowEditCourseModal(row)}
            >
              <Icon icon="pencil" />
            </Button>
            <Button
              size="sm"
              variant="danger"
              type="button"
              onClick={() => handleDeleteCourse(row.id)}
              disabled={disabledDeleteButton}
            >
              <Icon icon="trash" />
            </Button>

            <Dropdown>
              <Dropdown.Toggle
                variant="success ms-2"
                id="dropdown-basic"
                size="sm"
              >
                Thêm
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    handleShowStudentModal(row.id);
                  }}
                >
                  Danh sách học viên
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    handleShowStudentAddModal(row.id);
                  }}
                >
                  Thêm học viên
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        );
      },
    },
  ];

  const columnSenSubs = [
    {
      name: "Tên bài học",
      selector: (row) => row.name,
      wrap: true,
    },
    {
      name: "Link Youtube",
      selector: (row) => row.link,
    },
    {
      name: "Mô tả",
      selector: (row) => row.description,
    },
    {
      name: "Phương thức",
      center: true,
      cell: (row) => (
        <>
          <Button
            size="sm"
            variant="warning me-2"
            type="button"
            onClick={() => {
              return handleShowEditLessonModal(row);
            }}
          >
            <Icon icon="pencil" />
          </Button>
          <Button
            size="sm"
            variant="danger"
            type="button"
            onClick={() => handleDeleteLesson(row)}
          >
            <Icon icon="trash" />
          </Button>
        </>
      ),
    },
  ];

  const FirstSubCourse = ({ data }) => {
    let content = JSON.parse(data.content);
    if (content.length === 0) {
      return <i className="text-muted ms-5">Không có dữ liệu!</i>;
    }

    content = content.map((item, index) => {
      const newItem = {
        ...item,
        courseId: data.id,
        index: index,
      };

      return newItem;
    });

    return (
      <DataTables className="ms-5" data={content} columns={columnSenSubs} />
    );
  };

  return (
    <Container fluid>
      <div
        style={{
          backgroundColor: "#26B59C",
          fontSize: "1.5rem",
          color: "white",
        }}
        className="text-center w-100 mb-3 py-3 mt-3"
      >
        Danh sách khóa học
      </div>

      <Row className="mb-3">
        <Col xs="12" md="2">
          <Button
            variant="primary"
            className="mt-2 mt-md-0"
            onClick={() => handleShowAddCourseModal({})}
          >
            <Icon icon="plus" /> Thêm mới
          </Button>
        </Col>
      </Row>
      <Row>
        {isLoading ? (
          <Loading />
        ) : (
          <DataTables
            data={courses}
            columns={columns}
            expandableRows
            expandableRowsComponent={FirstSubCourse}
          />
        )}
      </Row>

      {showAddCourseModal ? (
        <CourseAddModal
          handleCloseModal={handleCloseAddCourseModal}
          showModal={showAddCourseModal}
        />
      ) : (
        ""
      )}

      {showEditCourseModal ? (
        <CourseEditModal
          handleCloseModal={handleCloseEditCourseModal}
          showModal={showEditCourseModal}
          selectedCourse={selectedCourse}
        />
      ) : (
        ""
      )}

      {showAddLessonModal ? (
        <LessonAddModal
          handleCloseModal={handleCloseAddLessonModal}
          showModal={showAddLessonModal}
          selectedCourse={selectedCourse}
        />
      ) : (
        ""
      )}

      {showEditLessonModal ? (
        <LessonEditModal
          handleCloseModal={handleCloseEditLessonModal}
          showModal={showEditLessonModal}
          courses={courses}
          selectedLesson={selectedLesson}
        />
      ) : (
        ""
      )}
      {showStudentModal ? (
        <StudentCourseModal
          closeModal={handleCloseStudentModal}
          showModal={handleShowStudentModal}
          courseId={courseId}
          isAdd={isAddModal}
        />
      ) : (
        ""
      )}
    </Container>
  );
};

export default Course;
