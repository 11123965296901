import axios from "axios";
import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import Loading from "../../../components/Loading";
import TextEditor from "../../../components/TextEditor";
import ImageURL from "../../../components/ImageURL";
import DefaultBanner from "../../../assets/image/banner-qhdt.png";
import { ACTIVITY_URL } from "../../../routes/routerUrl";
import { Link, useParams } from "react-router-dom";

const ActivityDetail = () => {
  let { slug } = useParams();

  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [careers, setCareers] = useState([]);

  useEffect(() => {
    document.title = "Giới thiệu";
    getDetailNew(slug);
    setImgUrl(DefaultBanner);
    // getAllPosts(process.env.REACT_APP_ACTIVITIES_SUB_CATEROGY_SLUG, 1);
  }, [slug]);

  const getDetailNew = (postSlug) => {
    setIsLoading(true);
    axios
      .get(`/post/list`, {
        params: {
          postSlug: postSlug,
        },
      })
      .then(async (res) => {
        setIsLoading(false);

        if (res.data.errCode === 0) {
          setContent(res.data.post.postContentVi);
          setTitle(res.data.post.postTitleVi);
          setImgUrl(res.data.post.imgURL);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const getAllPosts = async (cateSlug, page) => {
  //   setIsLoading(true);
  //   await axios
  //     .get(`/post/list`, {
  //       params: {
  //         cateSlug: cateSlug,
  //         page: page,
  //         limit: 10,
  //       },
  //     })
  //     .then((res) => {
  //       setIsLoading(false);
  //       if (res.data.errCode === 0) {
  //         setCareers(res.data.posts);
  //         if (res.data.posts.length > 0) {
  //           // setImgUrl(res.data.posts[0].imgURL);
  //         }
  //       } else {
  //         toast(res.data.message, {
  //           type: "error",
  //           autoClose: 1000,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  return (
    <div>
      {imgUrl !== DefaultBanner ? (
        <ImageURL
          style={{
            height: "var(--top-banner-height)",
            backgroundSize: "cover",
          }}
          imageURL={imgUrl}
        />
      ) : (
        <div
          style={{
            height: "var(--top-banner-height)",
            backgroundSize: "cover",
            backgroundImage: `url(${imgUrl})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "100%",
          }}
        />
      )}
      <Container className="container-wrapper my-5">
        <div>
          {isLoading ? (
            <Loading />
          ) : (
            <div>
              <h2>{title}</h2>
              {content ? (
                <TextEditor
                  disable
                  hideToolbar={true}
                  defaultValue={content}
                  onChange={() => {}}
                  style={{ border: "none" }}
                />
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default ActivityDetail;
