import { Card, Col, Row } from "react-bootstrap";
import { PRODUCT_DETAIL } from "../routes/routerUrl";
import { Link } from "react-router-dom";
import Icon from "./Icon";
import Loading from "./Loading";
import NoData from "./NoData";
import ImageURL from "./ImageURL";
import MyPagination from "./MyPagination";

import "./Style.css";

const ListProduct = ({
  showRow,
  isLoading,
  products,
  itemsCount,
  itemsPerPage,
  currentPage,
  setCurrentPage,
  isPagiantion,
}) => {
  return (
    <Row>
      {isLoading ? (
        <Loading />
      ) : products?.length > 0 ? (
        products?.map((product) => {
          return (
            <Col
              xs={12}
              sm={6}
              md={4}
              xl={12 / showRow}
              key={product.id}
              className="mb-5"
            >
              <Card className="product-border">
                <Link
                  to={`/${PRODUCT_DETAIL}/${product.proSlug}`}
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  <ImageURL
                    style={{ height: "20rem" }}
                    imageURL={product.imgPaths[0]}
                  />
                </Link>
                <Card.Body className="d-flex flex-column justify-content-between">
                  <Link
                    to={`/${PRODUCT_DETAIL}/${product.proSlug}`}
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <Card.Title className="text-center">
                      {product.proNameVi}
                    </Card.Title>
                  </Link>

                  {/* <div>
										<div
											style={{
												height: 1,
												backgroundColor:
													"var(--sencondary-color)",
											}}
											className="my-3"
										/>

										<div className="d-flex justify-content-between">
											<div>
												<Icon icon="cubes" /> Loại sản
												phẩm
											</div>
											<Link
												to={`/${PRODUCT_DETAIL}/${product.proSlug}`}
												style={{
													textDecoration: "none",
													color: "black",
												}}
											>
												<div>
													<Icon icon="list" /> Chi
													tiết
												</div>
											</Link>
										</div>
									</div> */}
                </Card.Body>
              </Card>
            </Col>
          );
        })
      ) : (
        <NoData />
      )}

      {isPagiantion && (
        <MyPagination
          itemsCount={itemsCount}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          alwaysShown={false}
        />
      )}
    </Row>
  );
};

export default ListProduct;
