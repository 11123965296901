import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { Button, Container, Form } from "react-bootstrap";

import NewAddModal from "./NewAddModal";
import NewEditModal from "./NewEditModal";
import Icon from "../../../components/Icon";
import Loading from "../../../components/Loading";
import DataTables from "../../../components/DataTables";
import DateFormat from "../../../components/DateFormat";
import ImageURL from "../../../components/ImageURL";

const New = () => {
  const [news, setNews] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedNew, setSelectedNew] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [masterCateId, setMasterCateId] = useState({});

  useEffect(() => {
    document.title = "Tin tức";
    getAllCategories();
  }, []);

  //#region API
  //#region Tìm id của master category
  const getAllCategories = () => {
    setIsLoading(true);
    axios
      .get(`/category/show`, {
        params: {
          isType: "cPost",
          isTree: 1,
        },
      })
      .then((res) => {
        if (res.data.errCode === 0) {
          const category = res.data.categories.find((element) => {
            return (
              element.cateSlug === process.env.REACT_APP_NEWS_CATEGORY_SLUG
            );
          });
          if (category === undefined) {
            createMasterCategory();
          } else {
            setMasterCateId(category.id);
            getAllNews("", category.id);
          }
          setIsLoading(false);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createMasterCategory = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("cateNameVi", "news");
    bodyFormData.append("cateSlug", process.env.REACT_APP_NEWS_CATEGORY_SLUG);
    bodyFormData.append("isType", "cPost");

    axios({
      method: "post",
      url: "category/create",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(async (res) => {
        if (res.data.errCode === 0) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });
          getAllCategories();
        } else {
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //#endregion

  const getAllNews = async (keyword, parentId) => {
    setIsLoading(true);
    await axios
      .get(`/post/show`, {
        params: {
          cateParentId: parentId,
          keyword: keyword,
        },
      })
      .then((res) => {
        setIsLoading(false);

        if (res.data.errCode === 0) {
          setNews(res.data.posts);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteNew = async (newIdId) => {
    await axios
      .delete(`/post/destroy`, {
        params: {
          id: newIdId,
        },
      })
      .then((res) => {
        if (res.data.errCode === 0) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });

          getAllNews("", masterCateId);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //#endregion

  const handleDeleteNew = (newIdId) => {
    swal({
      title: `Bạn muốn xóa tin tức này`,
      text: ` Lưu ý: Hành động không thể khôi phục lại`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        deleteNew(newIdId);
      }
    });
  };

  const handleCloseAddModal = (isReload) => {
    setShowAddModal(false);
    if (isReload) {
      getAllNews("", masterCateId);
    }
  };
  const handleShowAddModal = () => {
    setShowAddModal(true);
    setSelectedNew();
  };

  const handleCloseEditModal = (isReload) => {
    setShowEditModal(false);
    if (isReload) {
      getAllNews("", masterCateId);
    }
  };
  const handleShowEditModal = (newId) => {
    setShowEditModal(true);
    setSelectedNew(newId);
  };

  const columns = [
    {
      name: "Hình ảnh",
      style: {
        display: "flex",
        justifyContent: "center",
      },
      selector: (row) => (
        <ImageURL
          style={{
            aspectRatio: "900/600",
            height: "3.5rem",
            backgroundSize: "cover",
          }}
          imageURL={row.imgURL}
        />
      ),
    },
    {
      name: "Tiêu đề",
      selector: (row) => row.postTitleVi,
      wrap: true,
    },
    {
      name: "Người đăng",
      selector: (row) => row.creatorName,
      wrap: true,
    },
    {
      name: "Cập nhật",
      selector: (row) => <DateFormat date={row.updatedAt} />,
    },
    {
      name: "Phương thức",
      style: {
        display: "flex",
        justifyContent: "center",
      },
      selector: (row) => (
        <>
          <Button
            size="sm"
            variant="warning me-2"
            type="button"
            onClick={() => handleShowEditModal(row.id)}
          >
            <Icon icon="pencil" />
          </Button>

          <Button
            size="sm"
            variant="danger"
            type="button"
            onClick={() => handleDeleteNew(row.id)}
          >
            <Icon icon="trash" />
          </Button>
        </>
      ),
    },
  ];

  return (
    <Container fluid>
      <div
        style={{
          backgroundColor: "#26B59C",
          fontSize: "1.5rem",
          color: "white",
        }}
        className="text-center w-100 mb-3 py-3 mt-3"
      >
        Tin tức
      </div>

      <div className="d-block d-md-flex justify-content-between mb-3">
        <Form
          className="d-flex"
          onSubmit={(e) => {
            e.preventDefault();
            getAllNews(keyword, masterCateId);
          }}
        >
          <Form.Control
            type="search"
            placeholder={"Nhập tiêu đề"}
            className="me-2"
            aria-label="Search"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
          <Button variant="outline-secondary" type="submit">
            <Icon icon="magnifying-glass" />
          </Button>
        </Form>
        <div className="mt-3 mt-md-0">
          <Button
            variant="primary"
            className="mt-2 mt-md-0"
            onClick={() => handleShowAddModal()}
          >
            <Icon icon="plus" /> {"Thêm mới"}
          </Button>
        </div>
      </div>

      {isLoading ? <Loading /> : <DataTables data={news} columns={columns} />}

      {showAddModal ? (
        <NewAddModal
          handleCloseModal={handleCloseAddModal}
          showModal={showAddModal}
        />
      ) : (
        ""
      )}

      {showEditModal ? (
        <NewEditModal
          handleCloseModal={handleCloseEditModal}
          showModal={showEditModal}
          selectedNew={selectedNew}
        />
      ) : (
        ""
      )}
    </Container>
  );
};

export default New;
