import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import { Col, Container, Row, Breadcrumb, Table } from "react-bootstrap";

import ListCourse from "../../../components/ListCourse";
import TextEditor from "../../../components/TextEditor";
import Icon from "../../../components/Icon";
import UserContext from "../../../utils/UserContext";
import UserLoginModal from "../Auth/UserLoginModal";
import { ACCESS_PERMISSIONS } from "../../../constants";
import swal from "sweetalert";
import moment from "moment";
import "moment/locale/vi";
moment.locale("vi");

const CourseDetail = () => {
  let { slug } = useParams();

  const [course, setCourse] = useState({ imgPaths: [] });
  const [relatedCourses, setRelatedCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lessons, setLessons] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const user = useContext(UserContext);

  useEffect(() => {
    getCourse(slug);
  }, [slug]); // eslint-disable-line react-hooks/exhaustive-deps

  // star API
  const getCourse = async (slug) => {
    setIsLoading(true);

    await axios
      .get(`/course-mk/show`, {
        params: {
          slug: slug,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setCourse(res.data.data);
          setLessons(JSON.parse(res.data.data.content));
          getAllCourses(res.data.data.categoryId, res.data.data.id);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getAllCourses = async (cateId, courseId) => {
    await axios
      .get(`/course-mk/list`, {
        params: {
          categoryId: cateId,
          limit: 11,
        },
      })
      .then((res) => {
        setIsLoading(false);

        if (res.status === 200) {
          setRelatedCourses(
            res.data.data
              .filter((course) => course.id !== courseId)
              .map((course) => {
                return {
                  ...course,
                };
              })
          );
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  // end API

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const handleOpenLink = (link) => {
    if (!user) {
      handleShowModal();
    } else {
      if (
        course.type === ACCESS_PERMISSIONS.VIP &&
        user.type !== ACCESS_PERMISSIONS.VIP
      ) {
        swal({
          title: `Hãy liên hệ với admin để nâng cấp lên Vip`,
          icon: "warning",
          buttons: ["Đóng"],
          successMode: false,
        });
      } else {
        window.open(link, "_blank");
      }
    }
  };

  return (
    <>
      {showModal && (
        <UserLoginModal show={showModal} handleClose={handleCloseModal} />
      )}
      <Container className="container-wrapper mb-5">
        <Breadcrumb
          style={{
            backgroundColor: "var(--mute-bg-color)",
          }}
          className="p-3 pb-1 mb-3"
        >
          {/* <Breadcrumb.Item active>Trang chủ</Breadcrumb.Item> */}
          <Breadcrumb.Item active>Đào tạo</Breadcrumb.Item>
          <Breadcrumb.Item active>{course.name}</Breadcrumb.Item>
        </Breadcrumb>

        <Row className="mb-5">
          <Col xs="12" sm="12" md="8">
            <h4 className="mb-3 ">{course.name}</h4>
            <i>
              <Icon icon="rotate" />
              {`Cập nhật: ${moment(course.updatedAt * 1000).format(
                "dddd DD/MM/YYYY"
              )}`}
            </i>
            <hr></hr>
            <TextEditor
              disable
              hideToolbar={true}
              setContents={course.description}
              onChange={() => { }}
              style={{ border: "none" }}
            />
          </Col>
          <Col xs="12" sm="12" md="4">
            <div>
              <div className="fw-bold mb-3">DANH MỤC NỘI DUNG KHÓA HỌC</div>
              <div>
                <Table striped bordered>
                  <tbody>
                    {lessons.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <Link
                              onClick={() => handleOpenLink(item.link)}
                              style={{ textDecoration: " none" }}
                            >
                              {item.name}
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
        </Row>

        <div className="mt-5">
          <h3 className="mb-3 fst-italic">Các khóa học khác</h3>

          <ListCourse
            showRow={4}
            courses={relatedCourses}
            isLoading={isLoading}
            isPagiantion={false}
          />
        </div>
      </Container>
    </>
  );
};

export default CourseDetail;
