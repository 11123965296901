import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { Button, Container, Form } from "react-bootstrap";

import CareerAddModal from "./CareerAddModal";
import CareerEditModal from "./CareerEditModal";
import Icon from "../../../components/Icon";
import Loading from "../../../components/Loading";
import DataTables from "../../../components/DataTables";
import DateFormat from "../../../components/DateFormat";
import moment from "moment";
import "moment/locale/vi";
moment.locale("vi");

const Career = () => {
  const [careers, setCareers] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedCareer, setSelectedCareer] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [masterCateId, setMasterCateId] = useState({});

  useEffect(() => {
    document.title = "Tin tuyển dụng";
    getAllCategories();
  }, []);

  //#region API
  //#region Tìm id của master category
  const getAllCategories = () => {
    setIsLoading(true);
    axios
      .get(`/category/show`, {
        params: {
          isType: "cPost",
          isTree: 1,
        },
      })
      .then((res) => {
        if (res.data.errCode === 0) {
          const category = res.data.categories.find((element) => {
            return (
              element.cateSlug === process.env.REACT_APP_CAREER_CATEROGY_SLUG
            );
          });
          if (category === undefined) {
            createMasterCategory();
          } else {
            setMasterCateId(category.id);
            getAllCareers("", category.id);
          }
          setIsLoading(false);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createMasterCategory = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("cateNameVi", "career");
    bodyFormData.append("cateSlug", process.env.REACT_APP_CAREER_CATEROGY_SLUG);
    bodyFormData.append("isType", "cPost");

    axios({
      method: "post",
      url: "category/create",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(async (res) => {
        if (res.data.errCode === 0) {
          // toast(res.data.message, {
          //   type: "success",
          //   autoClose: 1000,
          // });
          getAllCategories();
        } else {
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //#endregion

  const getAllCareers = async (keyword, parentId) => {
    setIsLoading(true);
    await axios
      .get(`/post/show`, {
        params: {
          cateParentId: parentId,
          keyword: keyword,
        },
      })
      .then((res) => {
        setIsLoading(false);

        if (res.data.errCode === 0) {
          setCareers(res.data.posts);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteCareer = async (newIdId) => {
    await axios
      .delete(`/post/destroy`, {
        params: {
          id: newIdId,
        },
      })
      .then((res) => {
        if (res.data.errCode === 0) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });

          getAllCareers("", masterCateId);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //#endregion

  const handleDeleteCareer = (newIdId) => {
    swal({
      title: `Bạn muốn xóa tin tức này`,
      text: ` Lưu ý: Hành động không thể khôi phục lại`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        deleteCareer(newIdId);
      }
    });
  };

  const handleCloseAddModal = (isReload) => {
    setShowAddModal(false);
    if (isReload) {
      getAllCareers("", masterCateId);
    }
  };
  const handleShowAddModal = () => {
    setShowAddModal(true);
    setSelectedCareer();
  };

  const handleCloseEditModal = (isReload) => {
    setShowEditModal(false);
    if (isReload) {
      getAllCareers("", masterCateId);
    }
  };
  const handleShowEditModal = (newId) => {
    setShowEditModal(true);
    setSelectedCareer(newId);
  };

  const columns = [
    {
      name: "Vị trí tuyển dụng",
      selector: (row) => row.postTitleVi,
      wrap: true,
    },
    {
      name: "Ngày hết hạng",
      selector: (row) => {
        return moment(Date.parse(row.postDescVi)).format("DD/MM/YYYY");
      },
      wrap: true,
    },
    {
      name: "Người đăng",
      selector: (row) => row.creatorName,
      wrap: true,
    },
    {
      name: "Cập nhật",
      selector: (row) => <DateFormat date={row.updatedAt} />,
    },
    {
      name: "Phương thức",
      style: {
        display: "flex",
        justifyContent: "center",
      },
      selector: (row) => (
        <>
          <Button
            size="sm"
            variant="warning me-2"
            type="button"
            onClick={() => handleShowEditModal(row.id)}
          >
            <Icon icon="pencil" />
          </Button>

          <Button
            size="sm"
            variant="danger"
            type="button"
            onClick={() => handleDeleteCareer(row.id)}
          >
            <Icon icon="trash" />
          </Button>
        </>
      ),
    },
  ];

  return (
    <Container fluid>
      <div
        style={{
          backgroundColor: "#26B59C",
          fontSize: "1.5rem",
          color: "white",
        }}
        className="text-center w-100 mb-3 py-3 mt-3"
      >
        Tin tuyển dụng
      </div>

      <div className="d-block d-md-flex justify-content-between mb-3">
        <Form
          className="d-flex"
          onSubmit={(e) => {
            e.preventDefault();
            getAllCareers(keyword, masterCateId);
          }}
        >
          <Form.Control
            type="search"
            placeholder={"Nhập tiêu đề"}
            className="me-2"
            aria-label="Search"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
          <Button variant="outline-secondary" type="submit">
            <Icon icon="magnifying-glass" />
          </Button>
        </Form>
        <div className="mt-3 mt-md-0">
          <Button
            variant="primary"
            className="mt-2 mt-md-0"
            onClick={() => handleShowAddModal()}
          >
            <Icon icon="plus" /> {"Thêm mới"}
          </Button>
        </div>
      </div>

      {isLoading ? (
        <Loading />
      ) : (
        <DataTables data={careers} columns={columns} />
      )}

      {showAddModal ? (
        <CareerAddModal
          handleCloseModal={handleCloseAddModal}
          showModal={showAddModal}
        />
      ) : (
        ""
      )}

      {showEditModal ? (
        <CareerEditModal
          handleCloseModal={handleCloseEditModal}
          showModal={showEditModal}
          selectedCareer={selectedCareer}
        />
      ) : (
        ""
      )}
    </Container>
  );
};

export default Career;
