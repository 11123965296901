export const ACCESS_PERMISSIONS = {
  VIP: "Vip",
  NORMAL: "Normal",
  PREMIUM: "Premium",
};

export const DocumentOrCourse = {
  Document: "Đăng ký nhận tài liệu",
  Course: "Đăng ký khóa học",
};
