import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import ListSolution from "../../../components/ListSolution";

const itemsPerPage = 8;

const Solution = () => {
	let { slug } = useParams();

	const [solutions, setSolutions] = useState([]);

	const [currentPage, setCurrentPage] = useState(1);
	const [totalItems, setTotalItems] = useState(1);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		document.title = "Loại giải pháp";
		getAllSolutions(slug, 1);
	}, [slug]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		getAllSolutions(slug, currentPage);
	}, [currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

	const getAllSolutions = async (cateSlug, page) => {
		setIsLoading(true);
		await axios
			.get(`/post/list`, {
				params: {
					cateSlug: cateSlug,
					page: page,
					limit: itemsPerPage,
				},
			})
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setTotalItems(res.data.itemCount);
					setSolutions(res.data.posts);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<Container className="container-wrapper">
			<div
				className="text-color mx-2 pt-3 pb-5"
				style={{
					fontSize: "2.5rem",
				}}
			>
				Loại giải pháp
				<div className="title-solution-line mt-1" />
			</div>
			<ListSolution
				solutions={solutions}
				isLoading={isLoading}
				itemsCount={totalItems}
				itemsPerPage={itemsPerPage}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				isPagiantion={true}
			/>
		</Container>
	);
};

export default Solution;
