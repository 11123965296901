import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { Button, Container } from "react-bootstrap";

import BannerAddModal from "./BannerAddModal";
import BannerEditModal from "./BannerEditModal";
import BannerTextEditModal from "./BannerTextEditModal";
import Icon from "../../../components/Icon";
import Loading from "../../../components/Loading";
import DataTables from "../../../components/DataTables";
import DateFormat from "../../../components/DateFormat";
import ImageURL from "../../../components/ImageURL";

const Banner = () => {
  const [banners, setBanners] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showTextEditModal, setShowTextEditModal] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = "Banner";
    getAllBanners();
  }, []);

  // API
  const getAllBanners = async () => {
    setIsLoading(true);
    await axios
      .get(`/image/show`, {
        params: {
          isType: "iHome",
        },
      })
      .then((res) => {
        setIsLoading(false);

        if (res.data.errCode === 0) {
          setBanners(res.data.images);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteBanner = async (bannerIdId) => {
    await axios
      .delete(`/image/destroy`, {
        params: {
          id: bannerIdId,
        },
      })
      .then((res) => {
        if (res.data.errCode === 0) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });

          getAllBanners();
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Api

  const handleDeleteBanner = (bannerIdId) => {
    swal({
      title: `Bạn muốn xóa banner này`,
      text: ` Lưu ý: Hành động không thể khôi phục lại`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        deleteBanner(bannerIdId);
      }
    });
  };

  const handleCloseAddModal = (isReload) => {
    setShowAddModal(false);
    if (isReload) {
      getAllBanners();
    }
  };

  const handleShowAddModal = () => {
    setShowAddModal(true);
    setSelectedBanner();
  };

  const handleCloseEditModal = (isReload) => {
    setShowEditModal(false);
    if (isReload) {
      getAllBanners();
    }
  };

  const handleShowEditModal = (bannerId) => {
    setShowEditModal(true);
    setSelectedBanner(bannerId);
  };

  const handleShowTextEditModal = () => setShowTextEditModal(true);
  const handleCloseTextEditModal = () => setShowTextEditModal(false);

  const columns = [
    {
      name: "Hình ảnh",
      style: {
        display: "flex",
        justifyContent: "center",
      },
      selector: (row) => (
        <ImageURL
          style={{
            aspectRatio: "900/600",
            height: "3.5rem",
            backgroundSize: "cover",
          }}
          imageURL={row.imgURL}
        />
      ),
    },
    {
      name: "Cập nhật",
      selector: (row) => <DateFormat date={row.updatedAt} />,
    },
    {
      name: "Phương thức",
      style: {
        display: "flex",
        justifyContent: "center",
      },
      selector: (row) => (
        <>
          <Button
            size="sm"
            variant="warning me-2"
            type="button"
            onClick={() => handleShowEditModal(row.id)}
          >
            <Icon icon="pencil" />
          </Button>

          <Button
            size="sm"
            variant="danger"
            type="button"
            onClick={() => handleDeleteBanner(row.id)}
          >
            <Icon icon="trash" />
          </Button>
        </>
      ),
    },
  ];

  return (
    <Container fluid>
      <div
        style={{
          backgroundColor: "#26B59C",
          fontSize: "1.5rem",
          color: "white",
        }}
        className="text-center w-100 mb-3 py-3 mt-3"
      >
        Banner
      </div>

      <div className="d-block d-md-flex justify-content-between mb-3">
        <div className="mt-3 mt-md-0">
          <Button
            variant="warning"
            className="mt-2 mt-md-0"
            onClick={() => handleShowTextEditModal()}
          >
            <Icon icon="pencil" /> {"Cập nhât text banner"}
          </Button>
        </div>
      </div>
      <div className="d-block d-md-flex justify-content-between mb-3">
        <div className="mt-3 mt-md-0">
          <Button
            variant="primary"
            className="mt-2 mt-md-0"
            onClick={() => handleShowAddModal()}
          >
            <Icon icon="plus" /> {"Thêm mới"}
          </Button>
        </div>
      </div>

      {isLoading ? (
        <Loading />
      ) : (
        <DataTables data={banners} columns={columns} />
      )}

      {showAddModal ? (
        <BannerAddModal
          handleCloseModal={handleCloseAddModal}
          showModal={showAddModal}
        />
      ) : (
        ""
      )}

      {showEditModal ? (
        <BannerEditModal
          handleCloseModal={handleCloseEditModal}
          showModal={showEditModal}
          selectedBanner={selectedBanner}
        />
      ) : (
        ""
      )}

      {showTextEditModal ? (
        <BannerTextEditModal
          handleCloseModal={handleCloseTextEditModal}
          showModal={showTextEditModal}
        />
      ) : (
        ""
      )}
    </Container>
  );
};

export default Banner;
