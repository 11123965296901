import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Icon from "../../../components/Icon";
import swal from "sweetalert";
import DataTables from "../../../components/DataTables";
import UseCourses from "../../../hooks/UseCourses";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Form } from "react-bootstrap";
import removeAccents from "remove-accents";
const StudentDocumentModal = ({ closeModal, showModal, documentId, isAdd }) => {
  const [students, setStudents] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState();
  const [newStudent, setNewStudent] = useState([]);
  const [query, setQuery] = useState(null);
  const { courses } = UseCourses();
  const [isFilter, setIsFilter] = useState(false);

  useEffect(() => {
    isAdd ? getAllCustomers() : getStudents();
  }, []);

  let columns = [
    {
      name: "Tên",
      selector: (row) => row.name,
      wrap: true,
    },

    {
      name: "Email",
      selector: (row) => row.email,
      wrap: true,
    },
    {
      name: "Khóa",
      selector: (row) => row.trainingCourse?.name,
      wrap: true,
    },
  ];
  const addColumn = [
    ...columns,
    {
      name: "Phương thức",
      center: true,
      cell: (row) => {
        return (
          <Button
            size="sm"
            variant="danger me-2"
            type="button"
            onClick={() => handleDelete(row.id)}
          >
            <Icon icon="trash" />
          </Button>
        );
      },
    },
  ];
  const getStudents = async () => {
    axios
      .get(`/document-mk/show-customer/${documentId}`)
      .then((res) => {
        if (res.status === 200) {
          setStudents(res.data.data);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  // const getAllCustomers =
  const getAllCustomers = async () => {
    await axios
      .get(`/customer-mk/list`)
      .then((res) => {
        setCustomers(res.data.data);
      })
      .catch((err) => {
        toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
          type: "error",
          autoClose: 1000,
        });
        console.log(err);
      });
  };
  const deleteStudent = async (studentId) => {
    axios
      .delete(`/document-mk/remove-customer/${studentId}/${documentId}`)
      .then((res) => {
        if (res.status === 200) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });

          getStudents();
        }
      })
      .catch((err) => {
        toast(err.response.data.message, {
          type: "error",
          autoClose: 1000,
        });
        console.log(err);
      });
  };

  const assignCustomerToCourse = async () => {
    const customerIds = [];

    selectedStudent.map((val) => {
      return customerIds.push(val.id);
    });
    console.log(customerIds);

    await axios
      .post(`/assign-customer-to-docs`, {
        documentId: documentId,
        customerIds: customerIds,
      })
      .then((res) => {
        if (res.status === 200) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });
          closeModal(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onSelectRowChange = (rows) => {
    setSelectedStudent(rows);
  };

  const handleDelete = (studentId) => {
    swal({
      title: `Bạn muốn xóa khóa đào tạo này`,
      text: ` Lưu ý: Hành động không thể khôi phục lại`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        deleteStudent(studentId);
      }
    });
  };

  const handleFilterCourse = () => {
    let filteredStudents = isAdd ? [...customers] : [...students];

    if (query) {
      filteredStudents = filteredStudents.filter((student) =>
        removeAccents(student.name).toLowerCase().includes(query.toLowerCase())
      );
    }

    if (selectedCourseId && selectedCourseId !== "0") {
      filteredStudents = filteredStudents.filter(
        (student) => student.trainingCourse?.id == selectedCourseId
      );
    }

    setIsFilter(query || (selectedCourseId && selectedCourseId !== "0"));
    setNewStudent(filteredStudents);
  };
  useEffect(() => {
    const studentNew = isAdd ? [...customers] : [...students];
    handleFilterCourse(studentNew);
  }, [selectedCourseId, query]);

  return (
    <Modal backdrop="static" size="xl" show={showModal} onHide={closeModal}>
      <Modal.Header closeButton={true}>
        <Modal.Title>
          {isAdd ? "Danh sách khách hàng" : "Danh sách học viên"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className=" mb-3  ">
          <Row>
            <Col xs={12} md={4} className="mb-2">
              <Form.Control
                type="search"
                placeholder={"Nhập để tìm kiếm..."}
                className="me-2"
                aria-label="Search"
                onChange={(event) => setQuery(event.target.value || null)}
                // size="sm"
              />
            </Col>

            <Col xs={12} md={2} className="mb-2 ">
              <Form.Select
                aria-label="course"
                value={selectedCourseId}
                onChange={(event) => {
                  const id = event.target.value;
                  setSelectedCourseId(id);
                }}
              >
                {courses.map((course, index) => {
                  return (
                    <option key={index} value={course.id}>
                      {course.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>

            <Col xs={12} md={6}>
              <div className="d-flex justify-content-md-end justify-content-sm-start ">
                {isAdd ? (
                  <div className="d-flex justify-content-end  ">
                    <Button
                      type="button"
                      variant="primary"
                      className="me-2 btn-dels"
                      disabled={selectedStudent.length < 1}
                      onClick={assignCustomerToCourse}
                    >
                      <i className="fa-solid fa-users"></i> Thêm học viên
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
        </div>
        <div className="mb-2">
          <DataTables
            data={
              isFilter || (isFilter && isAdd)
                ? newStudent
                : isAdd
                ? customers
                : students
            }
            columns={isAdd ? columns : addColumn}
            selectableRows={isAdd}
            // selectedRow={selectedRow}
            onSelectedRowsChange={(item) => {
              onSelectRowChange(item.selectedRows);
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default StudentDocumentModal;
