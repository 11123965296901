import { useEffect, useState } from "react";
import ListCareers from "../../../components/ListCareers";
import CareerWrapper from "./CareerWrapper";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const itemsPerPage = 10;

const Career = () => {
  let { slug } = useParams();

  const [careers, setCareers] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = "Tuyển dụng";
    // getAllCareers(slug, 1);

    getAllCareers(process.env.REACT_APP_CAREER_SUB_CATEROGY_SLUG, 1);
  }, [slug]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // getAllCareers(slug, currentPage);
    getAllCareers(process.env.REACT_APP_CAREER_SUB_CATEROGY_SLUG, currentPage);
  }, [currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAllCareers = async (cateSlug, page) => {
    setIsLoading(true);
    await axios
      .get(`/post/list`, {
        params: {
          cateSlug: cateSlug,
          page: page,
          limit: itemsPerPage,
        },
      })
      .then((res) => {
        setIsLoading(false);

        if (res.data.errCode === 0) {
          setTotalItems(res.data.itemCount);
          setCareers(res.data.posts);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <CareerWrapper>
      <ListCareers
        careers={careers}
        isLoading={isLoading}
        itemsCount={totalItems}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isPagiantion={true}
      />
    </CareerWrapper>
  );
};

export default Career;
