import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Icon from "./Icon";
import { Col } from "react-bootstrap";

const DropdownMenu = ({ children, setShowSideBar, depthLevel }) => {
  return (
    <Col>
      {children.map((item) => {
        // return (
        //   <Row>
        //     <Link
        //       to={item.link}
        //       className="navbar-text menu-dropdown-item"
        //       onClick={() => setShowSideBar(false)}
        //     >
        //       {item.title}
        //     </Link>
        //   </Row>
        // );
        return (
          <SidebarItem
            item={item}
            setShowSideBar={setShowSideBar}
            depthLevel={depthLevel + 1}
          />
        );
      })}
    </Col>
  );
};

const SidebarItem = ({ item, setShowSideBar, depthLevel }) => {
  const location = useLocation();
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const toggleShowDropdown = () => setDropdownVisible((prev) => !prev);

  const paddingLeft = `${depthLevel * 1}rem`;

  if (item.children?.length > 0) {
    return (
      <div key={item.id}>
        <div
          style={{
            fontWeight: 600,
            textTransform: "uppercase",
          }}
          className="d-flex justify-content-between align-items-center mb-2"
        >
          <Link
            to={item.link}
            style={{ textDecoration: "none" }}
            onClick={() => setShowSideBar(false)}
          >
            <div
              className={`${location.pathname === "/" && item.link === "/"
                  ? "navbar-text-active text-uppercase"
                  : location.pathname.startsWith(`/${item.link}`)
                    ? "navbar-text-active text-uppercase"
                    : "navbar-text text-uppercase"
                }`}
              style={{
                paddingLeft: `${paddingLeft}`,
              }}
            >
              {item.title}
            </div>
          </Link>
          <div onClick={toggleShowDropdown} className="dropdown-icon">
            <Icon icon={isDropdownVisible ? "angle-up" : "angle-down"} />
          </div>
        </div>
        {isDropdownVisible && (
          <DropdownMenu
            children={item.children}
            setShowSideBar={setShowSideBar}
            depthLevel={depthLevel}
          />
        )}
      </div>
    );
  }
  return (
    <div key={item.id}>
      <div
        style={{
          fontWeight: 600,
          textTransform: "uppercase",
        }}
        className="d-flex justify-content-between align-items-center mb-2"
      >
        <Link
          to={item.link}
          style={{ textDecoration: "none" }}
          onClick={() => setShowSideBar(false)}
        >
          <div
            className={`${location.pathname === "/" && item.link === "/"
                ? "navbar-text-active text-uppercase"
                : location.pathname.startsWith(`/${item.link}`)
                  ? "navbar-text-active text-uppercase"
                  : "navbar-text text-uppercase"
              }`}
            style={{
              paddingLeft: `${paddingLeft}`,
            }}
          >
            {item.title}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default SidebarItem;
