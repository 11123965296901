import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Button, Col, Container, Row } from "react-bootstrap";
import swal from "sweetalert";

import DataTables from "../../../components/DataTables";
import Icon from "../../../components/Icon";
import CategoryAddModal from "./CategoryAddModal";
import CategoryEditModal from "./CategoryEditModal";
import Loading from "../../../components/Loading";
import DateFormat from "../../../components/DateFormat";

const Category = () => {
  const [categories, setCategories] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [parentCate, setParentCate] = useState({});
  const [selectedCategory, setSelectedCategory] = useState({
    id: 0,
    parentId: 0,
  });

  const [masterCateId, setMasterCateId] = useState({});

  useEffect(() => {
    getAllCategories();
  }, []);

  // Api
  const getAllCategories = async () => {
    setIsLoading(true);

    axios
      .get(`/category/show`, {
        params: {
          isType: "cPro",
          isTree: 1,
        },
      })
      .then((res) => {
        if (res.data.errCode === 0) {
          const category = res.data.categories.find((element) => {
            return (
              element.cateSlug === process.env.REACT_APP_PROJECT_CATEROGY_SLUG
            );
          });
          if (category === undefined) {
            createMasterCategory();
          } else {
            setMasterCateId(category.id);
            setCategories(category.children);
          }
          setIsLoading(false);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createMasterCategory = async () => {
    var bodyFormData = new FormData();
    bodyFormData.append("cateNameVi", "Dự án");
    bodyFormData.append(
      "cateSlug",
      process.env.REACT_APP_PROJECT_CATEROGY_SLUG
    );
    bodyFormData.append("isType", "cPro");

    await axios({
      method: "post",
      url: "category/create",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.data.errCode === 0) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });
          getAllCategories();
        } else {
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteCategory = async (categoryId) => {
    await axios
      .delete(`/category/destroy`, {
        params: {
          id: categoryId,
        },
      })
      .then((res) => {
        if (res.data.errCode === 0) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });

          getAllCategories();
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Api

  const handleDeleteCategory = (categoryId) => {
    swal({
      title: `Bạn muốn xóa danh mục dự án này`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        deleteCategory(categoryId);
      }
    });
  };

  const handleCloseAddModal = (isReload) => {
    setShowAddModal(false);
    setParentCate({});
    if (isReload) {
      getAllCategories();
    }
  };
  const handleShowAddModal = (cate) => {
    setShowAddModal(true);
    setSelectedCategory();
    setParentCate(cate);
  };

  const handleCloseEditModal = (isReload) => {
    setShowEditModal(false);
    if (isReload) {
      getAllCategories();
    }
  };
  const handleShowEditModal = (categoryId, parentId) => {
    setShowEditModal(true);
    setSelectedCategory({
      id: categoryId,
      parentId: parentId,
    });
  };

  const columns = [
    {
      name: "Tên danh mục",
      selector: (row) => row.cateNameVi,
      wrap: true,
    },
    {
      name: "Slug",
      selector: (row) => row.cateSlug,
    },
    {
      name: "Cập nhật",
      selector: (row) => <DateFormat date={row.updatedAt} />,
    },
    {
      name: "Phương thức",
      center: true,
      cell: (row) => (
        <>
          <Button
            size="sm"
            variant="primary me-2"
            type="button"
            onClick={() => handleShowAddModal(row)}
          >
            <Icon icon="plus" />
          </Button>
          <Button
            size="sm"
            variant="warning me-2"
            type="button"
            onClick={() => handleShowEditModal(row.id, row.parentId)}
          >
            <Icon icon="pencil" />
          </Button>
          <Button
            size="sm"
            variant="danger"
            type="button"
            onClick={() => handleDeleteCategory(row.id)}
          >
            <Icon icon="trash" />
          </Button>
        </>
      ),
    },
  ];

  const columnSenSubs = [
    {
      name: "Tên danh mục",
      selector: (row) => row.cateNameVi,
      wrap: true,
    },
    {
      name: "Slug",
      selector: (row) => row.cateSlug,
    },
    {
      name: "Cập nhật",
      selector: (row) => <DateFormat date={row.updatedAt} />,
    },
    {
      name: "Phương thức",
      center: true,
      cell: (row) => (
        <>
          <Button
            size="sm"
            variant="warning me-2"
            type="button"
            onClick={() => handleShowEditModal(row.id, row.parentId)}
          >
            <Icon icon="pencil" />
          </Button>
          <Button
            size="sm"
            variant="danger"
            type="button"
            onClick={() => handleDeleteCategory(row.id)}
          >
            <Icon icon="trash" />
          </Button>
        </>
      ),
    },
  ];

  const FirstSubCategory = ({ data }) => {
    if (!data) {
      return <i className="text-muted ms-5">Không có dữ liệu!</i>;
    }

    return (
      <DataTables
        className="ms-5"
        data={data.children}
        columns={columns}
        expandableRows
        expandableRowsComponent={SecondSubCategory}
      />
    );
  };

  const SecondSubCategory = ({ data }) => {
    if (!data) {
      return <i className="text-muted ms-5">Không có dữ liệu!</i>;
    }

    return (
      <DataTables
        className="ms-5"
        data={data.children}
        columns={columnSenSubs}
      />
    );
  };

  return (
    <Container fluid>
      <div
        style={{
          backgroundColor: "#26B59C",
          fontSize: "1.5rem",
          color: "white",
        }}
        className="text-center w-100 mb-3 py-3 mt-3"
      >
        Danh mục dự án
      </div>

      <Row className="mb-3">
        <Col xs="12" md="2">
          <Button
            variant="primary"
            className="mt-2 mt-md-0"
            onClick={() => handleShowAddModal({})}
          >
            <Icon icon="plus" /> Thêm mới
          </Button>
        </Col>
      </Row>
      <Row>
        {isLoading ? (
          <Loading />
        ) : (
          <DataTables
            data={categories}
            columns={columns}
            expandableRows
            expandableRowsComponent={FirstSubCategory}
          />
        )}
      </Row>

      {showAddModal ? (
        <CategoryAddModal
          handleCloseModal={handleCloseAddModal}
          showModal={showAddModal}
          parentCate={parentCate}
          masterCateId={masterCateId}
        />
      ) : (
        ""
      )}

      {showEditModal ? (
        <CategoryEditModal
          handleCloseModal={handleCloseEditModal}
          showModal={showEditModal}
          selectedCategory={selectedCategory}
          masterCateId={masterCateId}
        />
      ) : (
        ""
      )}
    </Container>
  );
};

export default Category;
