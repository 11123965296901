import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { NEW } from "../../../routes/routerUrl";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "../../../components/Loading";
import NoData from "../../../components/NoData";
import Icon from "../../../components/Icon";

const NewWrapper = ({ children }) => {
  const [topics, setTopics] = useState([{ posts: [] }]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = "Tin tức";
    getAllTopics();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // API
  const getAllTopics = async () => {
    setIsLoading(true);
    axios
      .get(`/category/list`, {
        params: {
          isType: "cPost",
          isTree: 1,
        },
      })
      .then((res) => {
        setIsLoading(false);
        if (res.data.errCode === 0) {
          const newsCategory = res.data.categories.find((element) => {
            return (
              element.cateSlug === process.env.REACT_APP_NEWS_CATEGORY_SLUG
            );
          });
          if (newsCategory !== undefined) {
            setTopics(
              newsCategory.children.filter(
                (category) =>
                  category.parentId !== null &&
                  category.parentId === newsCategory.id
              )
            );
          }
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Container className="container-wrapper my-5">
      <Row>
        <Col xs="12" sm="12" md="8">
          {children}
        </Col>
        <Col xs="12" sm="12" md="4">
          <div
            style={{
              position: "sticky",
              top: "var(--nav-height)",
            }}
          >
            {isLoading ? (
              <Loading />
            ) : topics.length !== 0 ? (
              topics?.map((topic) => {
                return (
                  <Card
                    style={{
                      borderRadius: 0,
                      borderStyle: "dotted",
                    }}
                    key={topic.id}
                    className="p-2 mb-3"
                  >
                    <Link
                      to={`/${NEW}/${topic.cateSlug}`}
                      className="text-black text-uppercase"
                    >
                      <Icon icon="plus" className="me-3" />
                      {topic.cateNameVi}
                    </Link>
                  </Card>
                );
              })
            ) : (
              <NoData />
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default NewWrapper;
