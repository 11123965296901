import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

import "./Style.css";
import axios from "axios";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookMessenger } from "@fortawesome/free-brands-svg-icons";
import ZaloLogo from "../../assets/image/zalo-logo.png";
import IconPhone from "../../assets/image/phone-ring.png";
import { UserContext, ShopContext } from "../../utils/contextGlobal";

import {
  INTRODUCTION,
  PRODUCT,
  CAREER,
  CONTACT,
  ACTIVITY,
  HISTORY,
  EDUCATION_URL,
  DOCUMENT_URL,
  TOPIC_URL,
  COMPANY_PERSONNEL_SLUG
} from "../../routes/routerUrl";

const isTokenExpired = (token) =>
  Date.now() >= JSON.parse(atob(token.split(".")[1])).exp * 1000;

function MasterLayout() {
  const [showSideBar, setShowSideBar] = useState(false);
  const [shop, setShop] = useState({});
  const [user, setUser] = useState();

  const [documentTypes, setDocumentTypes] = useState([]);
  const [courseTypes, setCourseTypes] = useState([]);
  const [activities, setActivities] = useState([]);
  const [navbarItems, setNavbarItems] = useState([
    {
      id: 1,
      title: "Trang chủ",
      link: "/",
    },
    {
      id: 2,
      title: "Giới thiệu",
      link: INTRODUCTION,
      children: [
        {
          id: "2-1",
          title: "Lịch sử",
          link: HISTORY,
        },
        {
          id: "2-2",
          title: "Nhân sự công ty",
          link: COMPANY_PERSONNEL_SLUG,
        },
        {
          id: "2-3",
          title: "Lĩnh vực",
          link: ACTIVITY,
        },
      ],
    },
    {
      id: 3,
      title: "Dự án",
      link: PRODUCT,
    },
    {
      id: 4,
      title: "Đào tạo",
      link: EDUCATION_URL,
    },
    {
      id: 5,
      title: "Tài liệu",
      link: DOCUMENT_URL,
    },
    {
      id: 6,
      title: "Liên hệ và tuyển dụng",
      children: [
        {
          id: "6-1",
          title: "Tuyển dụng",
          link: CAREER,
        },
        {
          id: "6-2",
          title: "Liên hệ",
          link: CONTACT,
        },
      ],
    },
    {
      id: 7,
      title: "Tin tức",
      link: TOPIC_URL,
    },
  ]);

  useEffect(() => {
    getSetting();
    getUserInfo();
  }, []);
  useEffect(() => {
    getAllDocumentTypes();
    getAllCourseTypes();
    getAllActivities();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //region API
  const getAllDocumentTypes = async () => {
    axios
      .get(`/category-mk/list`)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          const documentTypes = res.data.data.filter(
            (category) =>
              category.type === process.env.REACT_APP_CATEGORY_TYPE_DOCUMENT
          );
          setDocumentTypes(documentTypes);

          if (documentTypes.length > 0) {
            navbarItems[4].children = documentTypes.map((element, index) => ({
              id: `5-${index + 1}`,
              title: element.name,
              categoryId: element.id,
              link: `/${DOCUMENT_URL}/${element.id}`,
            }));
          }
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCourseTypes = async () => {
    axios
      .get(`/category-mk/list`)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          const courseTypes = res.data.data.filter(
            (category) =>
              category.type === process.env.REACT_APP_CATEGORY_TYPE_COURSE
          );
          setCourseTypes(courseTypes);
          if (courseTypes.length > 0) {
            navbarItems[3].children = courseTypes.map((type) => {
              let subTypes;
              if (type.children?.length > 0) {
                subTypes = type.children.map((subType) => {
                  return {
                    id: `4-${type.id}-${subType.id}`,
                    title: subType.name,
                    categoryId: subType.id,
                    link: `/${EDUCATION_URL}#course-${subType.cateParentId}-${subType.id}`,
                  };
                });
              }
              return {
                id: `4-${type.id}`,
                title: type.name,
                categoryId: type.id,
                link: `/${EDUCATION_URL}#course-${type.id}`,
                children: subTypes,
              };
            });
          }
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllActivities = async () => {
    await axios
      .get(`/post/list`, {
        params: {
          cateSlug: process.env.REACT_APP_ACTIVITIES_SUB_CATEROGY_SLUG,
          page: 1,
          limit: 10,
        },
      })
      .then((res) => {
        if (res.data.errCode === 0) {
          if (res.data.posts.length > 0) {
            const activites = res.data.posts;
            setActivities(activites);
            navbarItems[1].children[2].children = activites.map(
              (element, index) => ({
                id: `2-3-${index + 1}`,
                title: element.postTitleVi,
                link: `${ACTIVITY}/${element.postSlug}`,
              })
            );
          }
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //endregion API

  const getUserInfo = async () => {
    if (localStorage.getItem("user_access_token")) {
      if (isTokenExpired(localStorage.getItem("user_access_token"))) {
        localStorage.removeItem("user_access_token");
        setUser(null);
        return;
      }
      await axios
        .get(`/socialite-user`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "user_access_token"
            )}`,
          },
        })
        .then((res) => {
          setUser(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getSetting = async () => {
    await axios
      .get(`/shop/detail`)
      .then((res) => {
        if (res.data.errCode === 0) {
          setShop(res.data.shop);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <UserContext.Provider value={user}>
      <ShopContext.Provider value={shop}>
        <div>
          <Navbar
            setShowSideBar={setShowSideBar}
            showSideBar={showSideBar}
            shop={shop}
            navbarItems={navbarItems}
          />

          <Sidebar
            setShowSideBar={setShowSideBar}
            showSideBar={showSideBar}
            shop={shop}
            navbarItems={navbarItems}
          />

          <div onClick={() => setShowSideBar(false)}>
            <div className={`${showSideBar ? "curtain active" : "curtain"}`} />

            <Outlet />

            <div
              style={{
                display: "inline-block",
                position: "fixed",
                bottom: "50px",
                right: "2rem",
                opacity: 0.8,
                zIndex: 9999,
              }}
            >
              {shop.shopDescVi ? (
                <>
                  <Link
                    to={`https://www.facebook.com/messages/t/${shop.shopDescVi.substring(
                      shop.shopDescVi.indexOf("=") + 1,
                      shop.shopDescVi.lastIndexOf("&")
                    )}`}
                    target="_blank"
                    className="d-block"
                    title="Nhắn tin FaceBook"
                  >
                    <FontAwesomeIcon
                      icon={faFacebookMessenger}
                      size="2xl"
                      style={{
                        height: "40px",
                        width: "40px",
                        color: "blue !important",
                        opacity: 0.9,
                      }}
                      shake
                    />

                    <span></span>
                  </Link>
                  <br />{" "}
                </>
              ) : (
                ""
              )}

              <Link
                to={`https://zalo.me/${shop.shopDescEn}`}
                target="_blank"
                className="d-block"
                title="Nhắn tin FaceBook"
              >
                <img
                  src={ZaloLogo}
                  alt="MyKat"
                  style={{ width: "40px", height: "40px" }}
                />
              </Link>
              <br />
              {shop.shopPhone ? (
                <div class="fix_tel">
                  <div
                    class="ring-alo-phone ring-alo-green ring-alo-show"
                    id="ring-alo-phoneIcon"
                    style={{ right: "150px", bottom: "-12px" }}
                  >
                    <div class="ring-alo-ph-circle"></div>
                    <div class="ring-alo-ph-circle-fill"></div>
                    <div class="ring-alo-ph-img-circle">
                      <a href={`tel:${shop.shopPhone}`}>
                        <img class="lazy" src={IconPhone} />
                      </a>
                    </div>
                  </div>
                  <div class="tel">
                    <p class="fone">{shop.shopPhone}</p>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <Footer shop={shop} />
        </div>
      </ShopContext.Provider>
    </UserContext.Provider>
  );
}

export default MasterLayout;
