import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";
import Icon from "../../../components/Icon";
import Loading from "../../../components/Loading";
import DataTables from "../../../components/DataTables";
import DateFormat from "../../../components/DateFormat";
import TrainingCourseAddModal from "./TrainingCourseAddModal";

const TrainingCourse = () => {
  const [trainingCourses, setTrainingCourse] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedTrainingCourse, setSelectedTrainingCourse] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  //   const [parentCate, setParentCate] = useState({});

  useEffect(() => {
    document.title = "Khóa đào tạo";
    getTrainingCourse();
  }, []);

  // API
  const getTrainingCourse = async () => {
    setIsLoading(true);
    axios
      .get(`/training-course/list`)
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          setTrainingCourse(res.data.data);
          console.log(res.data);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // console.log(trainingCourses);
  };

  const deleteTrainingCourse = async (id) => {
    await axios
      .delete(`/training-course-destroy/${id}`)
      .then((res) => {
        if (res.status === 200) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });

          getTrainingCourse();
        }
      })
      .catch((err) => {
        toast(err.response.data.message, {
          type: "error",
          autoClose: 1000,
        });
        console.log(err);
      });
  };
  // Api

  const handleDelete = (id) => {
    swal({
      title: `Bạn muốn xóa khóa đào tạo này`,
      text: ` Lưu ý: Hành động không thể khôi phục lại`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        deleteTrainingCourse(id);
      }
    });
  };

  const handleCloseAddModal = (isReload) => {
    setShowAddModal(false);
    if (isReload) {
      getTrainingCourse();
    }
  };

  const handleShowAddModal = () => {
    setShowAddModal(true);
    // setSelectedCourseType();
    // setParentCate(parentCate);
  };

  const handleCloseEditModal = (isReload) => {
    setShowEditModal(false);
    if (isReload) {
      getTrainingCourse();
    }
  };
  const handleShowEditModal = (row) => {
    setShowEditModal(true);
    setSelectedTrainingCourse(row);
    // setParentCate(
    //   courseTypes.find((type) => type.id === courseType.cateParentId)
    // );
    // setSelectedCourseType(courseType);
  };

  const columns = [
    {
      name: "Khóa",
      selector: (row) => row.name,
      wrap: true,
    },
    {
      name: "Cập nhật",
      selector: (row) => <DateFormat date={row.updatedAt} />,
    },
    {
      name: "Phương thức",
      style: {
        display: "flex",
        justifyContent: "center",
      },
      selector: (row) => {
        const disabledDeleteButton = row.children?.length > 0;
        return (
          <>
            <Button
              size="sm"
              variant="warning me-2"
              type="button"
              onClick={() => handleShowEditModal(row)}
            >
              <Icon icon="pencil" />
            </Button>

            <Button
              size="sm"
              variant="danger"
              type="button"
              onClick={() => {
                handleDelete(row.id);
              }}
              //   disabled={disabledDeleteButton}
            >
              <Icon icon="trash" />
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <Container fluid>
      <div
        style={{
          backgroundColor: "#26B59C",
          fontSize: "1.5rem",
          color: "white",
        }}
        className="text-center w-100 mb-3 py-3 mt-3"
      >
        Khóa đào tạo
      </div>

      <div className="d-block d-md-flex justify-content-between  mb-3">
        <div className="mt-3 mt-md-0">
          <Button
            variant="primary"
            className="mt-2 mt-md-0"
            onClick={() => handleShowAddModal()}
          >
            <Icon icon="plus" /> {"Thêm mới"}
          </Button>
        </div>
      </div>

      {isLoading ? (
        <Loading />
      ) : (
        <DataTables
          data={trainingCourses}
          columns={columns}
          //   expandableRows
          //   expandableRowsComponent={SubCategory}
        />
      )}

      {showAddModal ? (
        <TrainingCourseAddModal
          handleCloseModal={handleCloseAddModal}
          showModal={showAddModal}
        />
      ) : (
        ""
      )}

      {showEditModal ? (
        <TrainingCourseAddModal
          handleCloseModal={handleCloseEditModal}
          showModal={showEditModal}
          selectedTrainingCourse={selectedTrainingCourse}
          isEdit={true}
        />
      ) : (
        ""
      )}
    </Container>
  );
};

export default TrainingCourse;
