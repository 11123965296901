import React from "react";
import DataTable from "react-data-table-component";

function DataTables(props) {
  const customStyles = {
    head: {
      style: {
        top: "-1px",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        borderLeft: "1px solid #dbdbd9",
        color: "white",
        fontSize: "14px",
        zIndex: "unset",
        backgroundColor: "#145f38",
        justifyContent: "center",
      },
    },
    rows: {
      style: {
        borderRight: "1px solid #dbdbd9",
      },
      highlightOnHoverStyle: {
        backgroundColor: "rgba(0, 0, 0, 0.175)",
        outline: "1px solid rgba(0, 0, 0, 0.12)",
        borderBottom: " 1px solid rgba(0, 0, 0, 0.12)",
      },
    },
    cells: {
      style: {
        borderLeft: "1px solid #dbdbd9",
        borderBottom: "1px solid #dbdbd9",
        padding: "0.5rem",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };

  return (
    <DataTable
      className="striped-rows"
      customStyles={customStyles}
      highlightOnHover
      fixedHeader={true}
      fixedHeaderScrollHeight={"568px"}
      pagination
      paginationPerPage={30}
      paginationRowsPerPageOptions={[30, 50, 100, 500]}
      paginationComponentOptions={{
        rowsPerPageText: "Hiển thị:",
        rangeSeparatorText: "của",
      }}
      noDataComponent="Không có dữ liệu..."
      {...props}
    />
  );
}

export default DataTables;
