import { useEffect } from "react";
import { Container } from "react-bootstrap";
import TimelineHistory from "./TimelineHistory";

import bannerMykat from "./images/2023.png";

const IndexHistory = () => {

  useEffect(() => {
    document.title = "Lịch sử";
  }, []);

  return (<>
    <img
      src={bannerMykat}
      alt="Banner Mỹ Kat"
      style={{
        width: "100%",
        aspectRatio: "4 / 1",
        objectFit: "cover",
      }}
    />

    <Container className="container-wrapper mt-5">
      <h1 style={{ fontSize: "24px" }}>CÔNG TY TNHH THƯƠNG MẠI VÀ XÂY DỰNG MỸ KAT (MYKAT)</h1>

      <p>
        <strong>Lịch sử hình thành:</strong> Tiền thân là CÔNG TY CỔ PHẦN TƯ VẤN ĐẦU TƯ VÀ XÂY DỰNG KIẾN HƯNG (2008), đến năm 2009 CÔNG TY TNHH THƯƠNG MẠI VÀ XÂY DỰNG MỸ KAT (MYKAT) ra đời.
      </p>

      <p>
        <strong>Ngày thành lập:</strong> 17-07-2019</p>

      <p>
        <strong>Số điện thoại:</strong> 0918376565</p>

      <p><strong>Email:</strong>  mykatcons@gmail.com</p>
      <p><strong>Website:</strong>  https://mykatcons.vn/</p>
      <p><strong>Lĩnh vực hoạt động:</strong></p>
      <ul>
        <li>Tư vấn thiết kế.</li>
        <li>Thi công các công trình nhà thép.</li>
        <li>Nhà phố.</li>
        <li>Xây dựng nhà để ở.</li>
        <li>Xây dựng nhà không để ở.</li>
        <li>Đại lý, môi giới.</li>
        <li>Bán buôn vật liệu, thiết bị lắp đặt khác trong xây dựng.</li>
        <li>Kho bãi và lưu giữ hàng hóa.</li>
        <li>Hệ thống cửa nhôm, sắt.</li>
      </ul>


      <p><strong>Đào tạo chuyên sâu về kết cấu trong xây dựng:</strong>  Công ty đang mở các lớp đào tạo, hệ thống hóa nhằm nâng cao trình độ cho cán bộ kĩ thuật và sinh viên về lĩnh vực thiết kế -xây dựng.</p>

      <h5 className="mt-4">NHỮNG CỘT MỐC ĐÁNH DẤU SỰ PHÁT TRIỂN CỦA CÔNG TY </h5>
    </Container >

    <TimelineHistory />

  </>);
};

export default IndexHistory;
