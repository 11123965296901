import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const UserLoginModal = ({ show, handleClose }) => {
  const [isLoading, setisLoading] = useState(false);
  const redirect = async (provider) => {
    setisLoading(true);
    await axios
      .get(`redirect/${provider}`)
      .then((response) => {
        setisLoading(false);
        window.location.href = response.data.data;
      })
      .catch(() =>
        toast.error("Có lỗi xảy ra vui lòng tải lại trang và thử lại")
      );
  };

  return (
    <Modal show={show} onHide={handleClose} size="sm">
      <Modal.Header closeButton>
        <strong>Đăng nhập</strong>
      </Modal.Header>
      <Modal.Body className="text-center d-block">
        <button
          className="login-with-google-btn my-3"
          onClick={() => redirect("google")}
          disabled={isLoading}
        >
          Đăng nhập với Google
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default UserLoginModal;
