import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Col, Container, Row } from "react-bootstrap";
import Loading from "../../components/Loading";
import Icon from "../../components/Icon";

const dashboardItems = [
  {
    id: 1,
    placeholder: "Dự án",
    icon: "layer-group",
    name: "totalProduct",
    color: "#26B59C",
  },
  {
    id: 2,
    placeholder: "Tin tức",
    icon: "newspaper",
    name: "totalNew",
    color: "#26b56b",
  },
];

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dashboard, setDashboard] = useState({
    totalProduct: 0,
    totalNew: 0,
  });

  useEffect(() => {
    document.title = "Tổng quan";
    getDashboard();
  }, []);

  const getDashboard = async () => {
    setIsLoading(true);
    await axios
      .get(`/home/admin`)
      .then((res) => {
        setIsLoading(false);
        if (res.data.errCode === 0) {
          setDashboard({
            totalProduct: res.data.productCount,
            totalNew: res.data.postCount,
          });
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Container className="pt-5 px-2 px-md-5">
      {isLoading ? (
        <Loading />
      ) : (
        <Row>
          {dashboardItems.map((item) => {
            return (
              <Col key={item.id} xs="12" sm="12" md="6" xl="4" className="mb-4">
                <div
                  style={{
                    backgroundColor: item.color,
                    color: "white",
                  }}
                  className="w-100 d-flex align-items-center p-3"
                >
                  <div style={{ fontSize: "3rem" }} className="m-3">
                    <Icon icon={item.icon} />
                  </div>
                  <div>
                    <div style={{ fontSize: "1.5rem" }}>{item.placeholder}</div>
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "2rem",
                      }}
                    >
                      {dashboard[item.name]}
                    </div>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      )}
    </Container>
  );
};

export default Dashboard;
