import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Breadcrumb, Button, Container, Form } from "react-bootstrap";
import TextEditor from "../../../components/TextEditor";

const Leader = () => {
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSendForm, setIsSendForm] = useState(false);
  const [postIntroId, setPostIntroId] = useState(null);
  const [masterCateId, setMasterCateId] = useState({});

  useEffect(() => {
    document.title = "Đội ngũ lãnh đạo";
    getAllCategories();
  }, []);

  const getAllCategories = async () => {
    setIsLoading(true);
    await axios
      .get(`/category/show`, {
        params: {
          isType: "cPost",
          isTree: 1,
        },
      })
      .then(async (res) => {
        if (res.data.errCode === 0) {
          const category = res.data.categories.find((element) => {
            return (
              element.cateSlug === process.env.REACT_APP_INTRO_CATEROGY_SLUG
            );
          });
          if (category === undefined) {
            await createMasterCategory();
          } else {
            setMasterCateId(category.id);
            getPostIntro();
          }
          setIsLoading(false);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createMasterCategory = async () => {
    var bodyFormData = new FormData();
    bodyFormData.append("cateNameVi", "intro");
    bodyFormData.append("cateSlug", process.env.REACT_APP_INTRO_CATEROGY_SLUG);
    bodyFormData.append("isType", "cPost");

    await axios({
      method: "post",
      url: "category/create",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(async (res) => {
        if (res.data.errCode === 0) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });
          await getAllCategories();
        } else {
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPostIntro = () => {
    setIsLoading(true);
    axios
      .get(`/post/list`, {
        params: {
          postSlug: process.env.REACT_APP_LEADER_POST_SLUG,
        },
      })
      .then(async (res) => {
        setIsLoading(false);

        if (res.data.errCode === 0) {
          if (res.data.post !== null) {
            setPostIntroId(res.data.post.id);
            setContent(res.data.post.postContentVi);
          }
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateIntro = async () => {
    setIsSendForm(true);

    var bodyFormData = new FormData();

    bodyFormData.append("id", postIntroId);
    bodyFormData.append("categoryId", masterCateId);
    bodyFormData.append("postTitleVi", "Đội ngũ lãnh đạo");
    bodyFormData.append("postDescVi", "");
    bodyFormData.append("postContentVi", content);
    bodyFormData.append("postSlug", process.env.REACT_APP_LEADER_POST_SLUG);
    bodyFormData.append("isStatus", "S1");

    await axios({
      method: "put",
      url: "post/update",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setIsSendForm(false);
        if (res.data.errCode === 0) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });
        } else {
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createIntro = async () => {
    setIsSendForm(true);

    var bodyFormData = new FormData();
    bodyFormData.append("categoryId", masterCateId);
    bodyFormData.append("postTitleVi", "Lãnh đạo");
    bodyFormData.append("postDescVi", "");
    bodyFormData.append("postContentVi", content);
    bodyFormData.append("postSlug", process.env.REACT_APP_LEADER_POST_SLUG);
    bodyFormData.append("isStatus", "S1");

    await axios({
      method: "post",
      url: "post/create",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setIsSendForm(false);
        if (res.data.errCode === 0) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });
          getPostIntro();
        } else {
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Container fluid>
      <Breadcrumb className="mt-2">
        <Breadcrumb.Item active>Tổng quan</Breadcrumb.Item>
        <Breadcrumb.Item active>Đội ngũ lãnh đạo</Breadcrumb.Item>
      </Breadcrumb>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          if (postIntroId === null) {
            createIntro();
          } else {
            updateIntro();
          }
        }}
      >
        <TextEditor
          setContents={content}
          onChange={(value) => setContent(value)}
          placeholder={"Nội dung"}
          style={{ border: "1px solid #dadada", padding: 16 }}
        />

        <Button
          type="submit"
          variant="primary"
          className="mt-3"
          disabled={isSendForm || isLoading}
        >
          Cập nhật
        </Button>
      </Form>
    </Container>
  );
};

export default Leader;
