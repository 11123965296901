import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "../../../utils/yupGlobal";
import { toast } from "react-toastify";
import { Button, Form, Modal, Table } from "react-bootstrap";
import Select from "react-select";

const CustomerAddModal = ({ handleCloseModal, showModal, customers }) => {
  const [isSendForm, setIsSendForm] = useState(false);

  const [trainingCourses, setTrainingCourses] = useState([]);

  // eslint-disable-next-line no-redeclare
  const [customerList, setCustomers] = useState(customers);

  useEffect(() => {
    getTrainingCourse();
  }, []);
  const getTrainingCourse = async () => {
    axios
      .get(`/training-course/list`)
      .then((res) => {
        if (res.status === 200) {
          setTrainingCourses(
            res.data.data?.map((e) => {
              return { value: e.id, label: e.name };
            })
          );
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const assignCourse = async () => {
    setIsSendForm(true);
    const data = [];
    customerList.map((customer) => {
      return data.push({
        customerId: customer.id,
        trainingCourseId: customer.trainingCourseId,
      });
    });

    await axios
      .post(`/assign-training-course-to-customer`, {
        payload: data,
      })
      .then((res) => {
        setIsSendForm(false);
        if (res.status === 200) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });
          handleCloseModal(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSelectCourse = (val, i) => {
    let selectedRows = [...customerList];
    selectedRows[i] = {
      ...selectedRows[i],
      trainingCourseId: val.value,
    };

    setCustomers(selectedRows);
  };
  const courses = trainingCourses;
  // console.log(roles);
  return (
    <Modal
      show={showModal}
      onHide={() => handleCloseModal(false)}
      backdrop="static"
      size="xl"
    >
      <Modal.Header closeButton={!isSendForm}>
        <Modal.Title>Gán khóa đào tạo</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="mb-2">
          <Table size="lg" bordered hover>
            <thead>
              <tr className="align-middle text-center">
                <th>Tên</th>
                <th>Email</th>
                <th>
                  Khóa<i className="text-danger">*</i>
                </th>
              </tr>
            </thead>
            <tbody>
              {customerList?.map((customer, index) => {
                return (
                  <tr key={index} className="align-middle text-center">
                    <td>
                      <Form.Control
                        type="text"
                        disabled
                        value={customer.name}
                      />
                    </td>

                    <td>
                      <Form.Control
                        type="text"
                        disabled
                        value={customer.email}
                      />
                    </td>

                    <td>
                      <Select
                        options={courses}
                        value={
                          courses.filter(
                            (val) => val.value === customer.trainingCourseId
                          )[0]
                        }
                        onChange={(val) => {
                          handleSelectCourse(val, index, customer.id);
                        }}
                      ></Select>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          variant="secondary"
          onClick={() => handleCloseModal(false)}
          disabled={isSendForm}
        >
          Đóng
        </Button>
        <Button
          type="submit"
          form="add-courseType-hook-form"
          variant="primary"
          // disabled={isSendForm}
          onClick={assignCourse}
        >
          Lưu
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Schema = yup.object().shape({
  name: yup
    .string()
    .required("Không được bỏ trống")
    .test(
      "len",
      "Tên loại giải pháp không dài quá 255 kí tự",
      (val) => val.length >= 0 && val.length <= 255
    ),
});

export default CustomerAddModal;
