import React, { useEffect, useRef } from 'react';
import './style.css';
import $ from "jquery";
import { Container } from 'react-bootstrap';
import Icon from '../../../../components/Icon';
import img2019 from './images/2019 (2).png';
import img2020 from './images/2020 (2).png';
import img2021 from './images/2021 (2).png';
import img2022 from './images/2022 (2).png';
import img2023 from './images/2023 (3).png';

$.fn.timelinr = function (options) {
    // default plugin settings
    let settings = $.extend({
        orientation: 'horizontal',		// value: horizontal | vertical, default to horizontal
        containerDiv: '#timeline',		// value: any HTML tag or #id, default to #timeline
        datesDiv: '#dates',			// value: any HTML tag or #id, default to #dates
        datesSelectedClass: 'selected',			// value: any class, default to selected
        datesSpeed: 'normal',			// value: integer between 100 and 1000 (recommended) or 'slow', 'normal' or 'fast'; default to normal
        issuesDiv: '#issues',			// value: any HTML tag or #id, default to #issues
        issuesSelectedClass: 'selected',			// value: any class, default to selected
        issuesSpeed: 'fast',				// value: integer between 100 and 1000 (recommended) or 'slow', 'normal' or 'fast'; default to fast
        issuesTransparency: 0,				// value: integer between 0 and 1 (recommended), default to 0.2
        issuesTransparencySpeed: 500,				// value: integer between 100 and 1000 (recommended), default to 500 (normal)
        prevButton: '#prev',			// value: any HTML tag or #id, default to #prev
        nextButton: '#next',			// value: any HTML tag or #id, default to #next
        arrowKeys: 'false',			// value: true | false, default to false
        startAt: 1,					// value: integer, default to 1 (first)
        autoPlay: 'false',			// value: true | false, default to false
        autoPlayDirection: 'forward',			// value: forward | backward, default to forward
        autoPlayPause: 2000				// value: integer (1000 = 1 seg), default to 2000 (2segs)
    }, options);

    $(function () {
        // setting variables... many of them
        var howManyDates = $(settings.datesDiv + ' li').length;
        var howManyIssues = $(settings.issuesDiv + ' li').length;
        var currentDate = $(settings.datesDiv).find('a.' + settings.datesSelectedClass);
        var currentIssue = $(settings.issuesDiv).find('li.' + settings.issuesSelectedClass);
        var widthContainer = $(settings.containerDiv).width();
        var heightContainer = $(settings.containerDiv).height();
        var widthIssues = $(settings.issuesDiv).width();
        var heightIssues = $(settings.issuesDiv).height();
        var widthIssue = $(settings.issuesDiv + ' li').width();
        var heightIssue = $(settings.issuesDiv + ' li').height();
        var widthDates = $(settings.datesDiv).width();
        var heightDates = $(settings.datesDiv).height();
        var widthDate = $(settings.datesDiv + ' li').width();
        var heightDate = $(settings.datesDiv + ' li').height();
        // set positions!
        if (settings.orientation == 'horizontal') {
            $(settings.issuesDiv).width(widthIssue * howManyIssues);
            $(settings.datesDiv).width(widthDate * howManyDates).css('marginLeft', widthContainer / 2 - widthDate / 2);
            var defaultPositionDates = parseInt($(settings.datesDiv).css('marginLeft').substring(0, $(settings.datesDiv).css('marginLeft').indexOf('px')));
        } else if (settings.orientation == 'vertical') {
            $(settings.issuesDiv).height(heightIssue * howManyIssues);
            $(settings.datesDiv).height(heightDate * howManyDates).css('marginTop', heightContainer / 2 - heightDate / 2);
            var defaultPositionDates = parseInt($(settings.datesDiv).css('marginTop').substring(0, $(settings.datesDiv).css('marginTop').indexOf('px')));
        }

        $(settings.datesDiv + ' a').click(function (event) {
            event.preventDefault();
            // first vars
            var whichIssue = $(this).text();
            var currentIndex = $(this).parent().prevAll().length;
            // moving the elements
            if (settings.orientation == 'horizontal') {
                $(settings.issuesDiv).animate({ 'marginLeft': -widthIssue * currentIndex }, { queue: false, duration: settings.issuesSpeed });
            } else if (settings.orientation == 'vertical') {
                $(settings.issuesDiv).animate({ 'marginTop': -heightIssue * currentIndex }, { queue: false, duration: settings.issuesSpeed });
            }
            $(settings.issuesDiv + ' li').animate(
                // { 'opacity': settings.issuesTransparency },
                { queue: false, duration: settings.issuesSpeed }).removeClass(settings.issuesSelectedClass).eq(currentIndex).addClass(settings.issuesSelectedClass).fadeTo(settings.issuesTransparencySpeed, 1
                );
            // prev/next buttons now disappears on first/last issue | bugfix from 0.9.51: lower than 1 issue hide the arrows | bugfixed: arrows not showing when jumping from first to last date
            if (howManyDates == 1) {
                $(settings.prevButton + ',' + settings.nextButton).fadeOut('fast');
            } else if (howManyDates == 2) {
                if ($(settings.issuesDiv + ' li:first-child').hasClass(settings.issuesSelectedClass)) {
                    $(settings.prevButton).fadeOut('fast');
                    $(settings.nextButton).fadeIn('fast');
                }
                else if ($(settings.issuesDiv + ' li:last-child').hasClass(settings.issuesSelectedClass)) {
                    $(settings.nextButton).fadeOut('fast');
                    $(settings.prevButton).fadeIn('fast');
                }
            } else {
                if ($(settings.issuesDiv + ' li:first-child').hasClass(settings.issuesSelectedClass)) {
                    $(settings.nextButton).fadeIn('fast');
                    $(settings.prevButton).fadeOut('fast');
                }
                else if ($(settings.issuesDiv + ' li:last-child').hasClass(settings.issuesSelectedClass)) {
                    $(settings.prevButton).fadeIn('fast');
                    $(settings.nextButton).fadeOut('fast');
                }
                else {
                    $(settings.nextButton + ',' + settings.prevButton).fadeIn('slow');
                }
            }
            // now moving the dates
            $(settings.datesDiv + ' a').removeClass(settings.datesSelectedClass);
            $(this).addClass(settings.datesSelectedClass);
            if (settings.orientation == 'horizontal') {
                $(settings.datesDiv).animate({ 'marginLeft': defaultPositionDates - (widthDate * currentIndex) }, { queue: false, duration: 'settings.datesSpeed' });
            } else if (settings.orientation == 'vertical') {
                $(settings.datesDiv).animate({ 'marginTop': defaultPositionDates - (heightDate * currentIndex) }, { queue: false, duration: 'settings.datesSpeed' });
            }
        });

        $(settings.nextButton).bind('click', function (event) {
            event.preventDefault();
            // bugixed from 0.9.54: now the dates gets centered when there's too much dates.
            var currentIndex = $(settings.issuesDiv).find('li.' + settings.issuesSelectedClass).index();
            if (settings.orientation == 'horizontal') {
                var currentPositionIssues = parseInt($(settings.issuesDiv).css('marginLeft').substring(0, $(settings.issuesDiv).css('marginLeft').indexOf('px')));
                var currentIssueIndex = currentPositionIssues / widthIssue;
                var currentPositionDates = parseInt($(settings.datesDiv).css('marginLeft').substring(0, $(settings.datesDiv).css('marginLeft').indexOf('px')));
                var currentIssueDate = currentPositionDates - widthDate;
                if (currentPositionIssues <= -(widthIssue * howManyIssues - (widthIssue))) {
                    $(settings.issuesDiv).stop();
                    $(settings.datesDiv + ' li:last-child a').click();
                } else {
                    if (!$(settings.issuesDiv).is(':animated')) {
                        // bugixed from 0.9.52: now the dates gets centered when there's too much dates.
                        $(settings.datesDiv + ' li').eq(currentIndex + 1).find('a').trigger('click');
                    }
                }
            } else if (settings.orientation == 'vertical') {
                var currentPositionIssues = parseInt($(settings.issuesDiv).css('marginTop').substring(0, $(settings.issuesDiv).css('marginTop').indexOf('px')));
                var currentIssueIndex = currentPositionIssues / heightIssue;
                var currentPositionDates = parseInt($(settings.datesDiv).css('marginTop').substring(0, $(settings.datesDiv).css('marginTop').indexOf('px')));
                var currentIssueDate = currentPositionDates - heightDate;
                if (currentPositionIssues <= -(heightIssue * howManyIssues - (heightIssue))) {
                    $(settings.issuesDiv).stop();
                    $(settings.datesDiv + ' li:last-child a').click();
                } else {
                    if (!$(settings.issuesDiv).is(':animated')) {
                        // bugixed from 0.9.54: now the dates gets centered when there's too much dates.
                        $(settings.datesDiv + ' li').eq(currentIndex + 1).find('a').trigger('click');
                    }
                }
            }
            // prev/next buttons now disappears on first/last issue | bugfix from 0.9.51: lower than 1 issue hide the arrows
            if (howManyDates == 1) {
                $(settings.prevButton + ',' + settings.nextButton).fadeOut('fast');
            } else if (howManyDates == 2) {
                if ($(settings.issuesDiv + ' li:first-child').hasClass(settings.issuesSelectedClass)) {
                    $(settings.prevButton).fadeOut('fast');
                    $(settings.nextButton).fadeIn('fast');
                }
                else if ($(settings.issuesDiv + ' li:last-child').hasClass(settings.issuesSelectedClass)) {
                    $(settings.nextButton).fadeOut('fast');
                    $(settings.prevButton).fadeIn('fast');
                }
            } else {
                if ($(settings.issuesDiv + ' li:first-child').hasClass(settings.issuesSelectedClass)) {
                    $(settings.prevButton).fadeOut('fast');
                }
                else if ($(settings.issuesDiv + ' li:last-child').hasClass(settings.issuesSelectedClass)) {
                    $(settings.nextButton).fadeOut('fast');
                }
                else {
                    $(settings.nextButton + ',' + settings.prevButton).fadeIn('slow');
                }
            }
        });

        $(settings.prevButton).click(function (event) {
            event.preventDefault();
            // bugixed from 0.9.54: now the dates gets centered when there's too much dates.
            var currentIndex = $(settings.issuesDiv).find('li.' + settings.issuesSelectedClass).index();
            if (settings.orientation == 'horizontal') {
                var currentPositionIssues = parseInt($(settings.issuesDiv).css('marginLeft').substring(0, $(settings.issuesDiv).css('marginLeft').indexOf('px')));
                var currentIssueIndex = currentPositionIssues / widthIssue;
                var currentPositionDates = parseInt($(settings.datesDiv).css('marginLeft').substring(0, $(settings.datesDiv).css('marginLeft').indexOf('px')));
                var currentIssueDate = currentPositionDates + widthDate;
                if (currentPositionIssues >= 0) {
                    $(settings.issuesDiv).stop();
                    $(settings.datesDiv + ' li:first-child a').click();
                } else {
                    if (!$(settings.issuesDiv).is(':animated')) {
                        // bugixed from 0.9.54: now the dates gets centered when there's too much dates.
                        $(settings.datesDiv + ' li').eq(currentIndex - 1).find('a').trigger('click');
                    }
                }
            } else if (settings.orientation == 'vertical') {
                var currentPositionIssues = parseInt($(settings.issuesDiv).css('marginTop').substring(0, $(settings.issuesDiv).css('marginTop').indexOf('px')));
                var currentIssueIndex = currentPositionIssues / heightIssue;
                var currentPositionDates = parseInt($(settings.datesDiv).css('marginTop').substring(0, $(settings.datesDiv).css('marginTop').indexOf('px')));
                var currentIssueDate = currentPositionDates + heightDate;
                if (currentPositionIssues >= 0) {
                    $(settings.issuesDiv).stop();
                    $(settings.datesDiv + ' li:first-child a').click();
                } else {
                    if (!$(settings.issuesDiv).is(':animated')) {
                        // bugixed from 0.9.54: now the dates gets centered when there's too much dates.
                        $(settings.datesDiv + ' li').eq(currentIndex - 1).find('a').trigger('click');
                    }
                }
            }
            // prev/next buttons now disappears on first/last issue | bugfix from 0.9.51: lower than 1 issue hide the arrows
            if (howManyDates == 1) {
                $(settings.prevButton + ',' + settings.nextButton).fadeOut('fast');
            } else if (howManyDates == 2) {
                if ($(settings.issuesDiv + ' li:first-child').hasClass(settings.issuesSelectedClass)) {
                    $(settings.prevButton).fadeOut('fast');
                    $(settings.nextButton).fadeIn('fast');
                }
                else if ($(settings.issuesDiv + ' li:last-child').hasClass(settings.issuesSelectedClass)) {
                    $(settings.nextButton).fadeOut('fast');
                    $(settings.prevButton).fadeIn('fast');
                }
            } else {
                if ($(settings.issuesDiv + ' li:first-child').hasClass(settings.issuesSelectedClass)) {
                    $(settings.prevButton).fadeOut('fast');
                }
                else if ($(settings.issuesDiv + ' li:last-child').hasClass(settings.issuesSelectedClass)) {
                    $(settings.nextButton).fadeOut('fast');
                }
                else {
                    $(settings.nextButton + ',' + settings.prevButton).fadeIn('slow');
                }
            }
        });
        // keyboard navigation, added since 0.9.1
        if (settings.arrowKeys == 'true') {
            if (settings.orientation == 'horizontal') {
                $(document).keydown(function (event) {
                    if (event.keyCode == 39) {
                        $(settings.nextButton).click();
                    }
                    if (event.keyCode == 37) {
                        $(settings.prevButton).click();
                    }
                });
            } else if (settings.orientation == 'vertical') {
                $(document).keydown(function (event) {
                    if (event.keyCode == 40) {
                        $(settings.nextButton).click();
                    }
                    if (event.keyCode == 38) {
                        $(settings.prevButton).click();
                    }
                });
            }
        }
        // default position startAt, added since 0.9.3
        $(settings.datesDiv + ' li').eq(settings.startAt - 1).find('a').trigger('click');
        // autoPlay, added since 0.9.4
        if (settings.autoPlay == 'true') {
            setInterval("autoPlay()", settings.autoPlayPause);
        }
    });
};

const TimelineHistory = () => {
    const timelineRef = useRef(null);

    useEffect(() => {
        let current = 5;
        const timeline = timelineRef.current;

        $(() => {
            $().timelinr({
                orientation: 'vertical',
                issuesSpeed: 300,
                datesSpeed: 100,
                arrowKeys: true,
                startAt: 3,
            });
        });

        if (timeline) {


            const issues = timeline.querySelectorAll('#issues li');
            const dates = timeline.querySelectorAll('#dates li a');

            const showIssue = (index) => {
                issues.forEach((issue) => issue.classList.remove('selected'));
                issues[index]?.classList.add('selected');

                dates.forEach((date) => date.classList.remove('selected'));
                dates[index]?.classList.add('selected');
            };

            const next = (e) => {
                e.preventDefault();
                current = (current + 1) % issues.length;
                showIssue(current);
            };

            const prev = (e) => {
                e.preventDefault();
                current = (current - 1 + issues.length) % issues.length;
                showIssue(current);
            };

            const nextButton = timeline.querySelector('#next');
            const prevButton = timeline.querySelector('#prev');

            if (nextButton && prevButton) {
                nextButton.addEventListener('click', next);
                prevButton.addEventListener('click', prev);
            }

            // Show initial issue
            showIssue(current);

            // Cleanup
            return () => {
                if (nextButton && prevButton) {
                    nextButton.removeEventListener('click', next);
                    prevButton.removeEventListener('click', prev);
                }
            };
        }
    }, []);


    return (
        <div id="timeline" ref={timelineRef} className='bg-dark'>
            <Container className='position-relative'>
                <ul id="dates">
                    <li><a href="#2019" >2019</a></li>
                    <li><a href="#2020">2020</a></li>
                    <li><a href="#2021" >2021</a></li>
                    <li><a href="#2022">2022</a></li>
                    <li><a href="#2023">2023</a></li>
                </ul>



                <ul id="issues">
                    <li id="2019" className="selected">
                        <span>
                            <h5>Giai đoạn năm 2019</h5>
                            <p>
                                &#8226; Mục tiêu năm 2019 của công ty: Mở rộng thêm lĩnh vực kinh doanh xây dựng về Sơn trang trí.
                                <br />
                                &#8226; Đánh dấu bước ngoặt đầu tiên là Dự án thi công Resort Cồn Khương hoàn thành trong 2 năm: 2017-2019.
                            </p>
                        </span>
                        <img src={img2019} alt='Giai đoạn năm 2019 Mỹ Kat' />
                    </li>
                    <li id="2020">
                        <span>
                            <h5>Giai đoạn năm 2020</h5>
                            <p>
                                &#8226; Năm 2020 về lĩnh vực kinh doanh công ty đã mở thêm một đại lý - Dũng Liêm về Sơn trang trí.
                                <br />
                                &#8226; Tư vấn thiết kế - nhà cao ốc khu dân cư Phú An, diện tích xây dựng 5.765m2, Cao ốc Phú An gồm 8 block căn hộ có chiều cao 25 tầng với tổng diện tích sàn xây dựng 141.598m2 với 456 căn hộ với dân số dự kiến là 1.800 dân.
                                <br />
                                &#8226; Hoàn thành dự án Thi công nhà ở Xã hội Nam Long, thời gian thi công 8 tháng: gồm 1 trệt 6 lầu với diện tích khoảng 2000m.
                            </p>
                        </span>
                        <img src={img2020} alt='Giai đoạn năm 2020 Mỹ Kat' />
                    </li>
                    <li id="2021">
                        <span>
                            <h5>Giai đoạn năm 2021</h5>
                            <p>
                                &#8226; Năm 2021, công ty nhận Thiết kế nhà chung cư bên Úc.
                                <br />
                                &#8226; Tiếp tục thi công dãy nhà liền kề, Shophouse thuộc dự án Nam Long 2 thời gian hoàn thành dự án từ năm 2021 - giữa năm 2022.
                            </p>
                        </span>
                        <img src={img2021} alt='Giai đoạn năm 2021 Mỹ Kat' />
                    </li>
                    <li id="2022">
                        <span>
                            <h5>Giai đoạn năm 2022</h5>
                            <p>
                                &#8226; Năm 2022, năm đánh dấu nhiều sự phát triển của công ty:
                                <br />
                                &#8226; Hoàn thành dự án nhà liền kề, shophouse thuộc dự án Nam Long 2.
                                <br />
                                &#8226; Nhà phố 4 tầng vượt nhịp.
                                <br />
                                &#8226; Dự án nhà Xã hội STK Khu Dân Cư An Bình.
                                <br />
                                &#8226; Ngoài dự án nhà xưởng công ty còn phát triển thêm về dự án xây dựng công ty, cao ốc,..
                            </p>
                        </span>
                        <img src={img2022} alt='Giai đoạn năm 2022 Mỹ Kat' />
                    </li>
                    <li id="2023">
                        <span>
                            <h5>Giai đoạn năm 2023</h5>
                            <p>
                                &#8226; Năm 2023, năm công ty hoàn thành nhiều dự án lớn và mở rộng thêm về lĩnh vực kinh doanh khác, ngoài Sơn trang trí.
                                <br />
                                &#8226; Thiết kế hệ thống nhà xe STK KDC An Bình.
                                <br />
                                &#8226; Kinh doanh phát triển thêm điện gia dụng - điện thông minh bên nhà phân phối Điện Quang.
                                <br />
                                &#8226; Thi công hoàn thiện nhà bên khu dân cư Nam Long 2.
                                <br />
                                &#8226; Thiết kế dãy nhà liền kề - Cồn Khương.
                                <br />
                                &#8226; Thi công hoàn thiện nhà máy trộn bột mì ( kết cấu thép vượt nhịp) thuộc hệ thống bột mì Đại Phong, hoàn thiện trong vòng 10 tháng với quy mô 22000m.
                            </p>
                        </span>
                        <img src={img2023} alt='Giai đoạn năm 2023 Mỹ Kat' />
                    </li>
                </ul>

                <a href="#" id="next" className='bg-dark'><Icon icon={'chevron-down'} /></a>
                <a href="#" id="prev" className='bg-dark'><Icon icon={'chevron-up'} /></a>
            </Container>

            <div id="grad_top"></div>
            <div id="grad_bottom"></div>


        </div>
    );
};

export default TimelineHistory;
