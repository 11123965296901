import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";

import TopicAddModal from "./TopicAddModal";
import TopicEditModal from "./TopicEditModal";
import Icon from "../../../components/Icon";
import Loading from "../../../components/Loading";
import DataTables from "../../../components/DataTables";
import DateFormat from "../../../components/DateFormat";

const Topic = () => {
  const [topics, setTopics] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [masterCateId, setMasterCateId] = useState({});

  useEffect(() => {
    document.title = "Chủ đề";
    getAllCategories();
  }, []);

  //#region API
  //#region Tìm id của master category
  const getAllCategories = () => {
    setIsLoading(true);
    axios
      .get(`/category/show`, {
        params: {
          isType: "cPost",
          isTree: 1,
        },
      })
      .then((res) => {
        if (res.data.errCode === 0) {
          const category = res.data.categories.find((element) => {
            return (
              element.cateSlug === process.env.REACT_APP_NEWS_CATEGORY_SLUG
            );
          });
          if (category === undefined) {
            createMasterCategory();
          } else {
            setMasterCateId(category.id);
            getAllTopics(category.id);
          }
          setIsLoading(false);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createMasterCategory = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("cateNameVi", "news");
    bodyFormData.append("cateSlug", process.env.REACT_APP_NEWS_CATEGORY_SLUG);
    bodyFormData.append("isType", "cPost");

    axios({
      method: "post",
      url: "category/create",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(async (res) => {
        if (res.data.errCode === 0) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });
          getAllCategories();
        } else {
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //#endregion

  const getAllTopics = async (parentId) => {
    setIsLoading(true);

    axios
      .get(`/category/show`, {
        params: {
          isType: "cPost",
          cateParentId: parentId,
        },
      })
      .then((res) => {
        setIsLoading(false);

        if (res.data.errCode === 0) {
          setTopics(
            res.data.categories.filter((category) => category.parentId !== null)
          );
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteTopic = async (topicIdId) => {
    await axios
      .delete(`/category/destroy`, {
        params: {
          id: topicIdId,
        },
      })
      .then((res) => {
        if (res.data.errCode === 0) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });

          getAllTopics(masterCateId);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //#endregion Api

  const handleDeleteTopic = (topicIdId) => {
    swal({
      title: `Bạn muốn xóa chủ đề này`,
      text: ` Lưu ý: Hành động không thể khôi phục lại`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        deleteTopic(topicIdId);
      }
    });
  };

  const handleCloseAddModal = (isReload) => {
    setShowAddModal(false);
    if (isReload) {
      getAllTopics(masterCateId);
    }
  };

  const handleShowAddModal = () => {
    setShowAddModal(true);
    setSelectedTopic();
  };

  const handleCloseEditModal = (isReload) => {
    setShowEditModal(false);
    if (isReload) {
      getAllTopics(masterCateId);
    }
  };
  const handleShowEditModal = (topicId) => {
    setShowEditModal(true);
    setSelectedTopic(topicId);
  };

  const columns = [
    {
      name: "Tên chủ đề",
      selector: (row) => row.cateNameVi,
      wrap: true,
    },
    {
      name: "Slug",
      selector: (row) => row.cateSlug,
    },
    {
      name: "Cập nhật",
      selector: (row) => <DateFormat date={row.updatedAt} />,
    },
    {
      name: "Phương thức",
      style: {
        display: "flex",
        justifyContent: "center",
      },
      selector: (row) => (
        <>
          <Button
            size="sm"
            variant="warning me-2"
            type="button"
            onClick={() => handleShowEditModal(row.id)}
          >
            <Icon icon="pencil" />
          </Button>

          <Button
            size="sm"
            variant="danger"
            type="button"
            onClick={() => handleDeleteTopic(row.id)}
          >
            <Icon icon="trash" />
          </Button>
        </>
      ),
    },
  ];

  return (
    <Container fluid>
      <div
        style={{
          backgroundColor: "#26B59C",
          fontSize: "1.5rem",
          color: "white",
        }}
        className="text-center w-100 mb-3 py-3 mt-3"
      >
        Quản lý chủ đề{" "}
      </div>

      <div className="d-block d-md-flex justify-content-between mb-3">
        <div className="mt-3 mt-md-0">
          <Button
            variant="primary"
            className="mt-2 mt-md-0"
            onClick={() => handleShowAddModal()}
          >
            <Icon icon="plus" /> {"Thêm mới"}
          </Button>
        </div>
      </div>

      {isLoading ? <Loading /> : <DataTables data={topics} columns={columns} />}

      {showAddModal ? (
        <TopicAddModal
          handleCloseModal={handleCloseAddModal}
          showModal={showAddModal}
          cateParentId={masterCateId}
        />
      ) : (
        ""
      )}

      {showEditModal ? (
        <TopicEditModal
          handleCloseModal={handleCloseEditModal}
          showModal={showEditModal}
          selectedTopic={selectedTopic}
          cateParentId={masterCateId}
        />
      ) : (
        ""
      )}
    </Container>
  );
};

export default Topic;
