import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "../../../utils/yupGlobal";
import { toast } from "react-toastify";

import { Button, Form, Modal } from "react-bootstrap";
import convertToSlug from "../../../utils/slugFormatter";

const CourseTypeEditModal = ({
  handleCloseModal,
  showModal,
  selectedCourseType,
  parentCate,
}) => {
  const [slug, setSlug] = useState("");
  const [isSendForm, setIsSendForm] = useState(false);
  const [errMessage, setErrMessage] = useState({
    cateSlug: undefined,
  });

  const {
    reset,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(Schema),
  });

  useEffect(() => {
    reset({
      name: selectedCourseType.name,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Api
  const updateNewCourseType = async (data) => {
    setIsSendForm(true);

    await axios
      .put(`/category-mk/update`, {
        categoryId: selectedCourseType.id,
        name: data.name,
        cateSlug: slug,
        type: process.env.REACT_APP_CATEGORY_TYPE_COURSE,
        cateParentId: parentCate?.id,
      })
      .then((res) => {
        setIsSendForm(false);

        if (res.status === 200) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });
          handleCloseModal(true);
        } else {
          setErrMessage(res.data.message);
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Api

  return (
    <Modal
      show={showModal}
      onHide={() => handleCloseModal(false)}
      backdrop="static"
    >
      <Modal.Header closeButton={!isSendForm}>
        <Modal.Title>Cập nhật loại khóa học</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          id="edit-courseType-hook-form"
          onSubmit={handleSubmit((data) => {
            updateNewCourseType(data);
          })}
          className="mb-5"
        >
          {parentCate?.id ? (
            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                placeholder={`Danh mục cha`}
                value={parentCate.name}
                disabled
              />

              <Form.Label>{`Danh mục cha`}</Form.Label>
            </Form.Floating>
          ) : (
            ""
          )}
          <div className="mb-3">
            <Form.Floating>
              <Form.Control
                type="text"
                placeholder={`Tên loại khóa học`}
                name={`name`}
                {...register(`name`, {
                  onChange: () => {
                    setSlug(convertToSlug(getValues("name")));
                  },
                })}
              />

              <Form.Label>
                {`Tên loại khóa học`}
                <i className="text-danger">*</i>
              </Form.Label>
            </Form.Floating>

            {errors[`name`] && (
              <p
                style={{
                  fontSize: 13,
                  color: "red",
                  marginTop: 6,
                }}
              >
                {errors[`name`].message}
              </p>
            )}
          </div>

          <div>
            <Form.Floating>
              <Form.Control
                type="text"
                placeholder="Slug"
                name="slug"
                disabled
                value={slug}
              />

              <Form.Label>
                Slug
                <i className="text-danger">*</i>
              </Form.Label>
            </Form.Floating>
            {errMessage.cateSlug && (
              <p
                style={{
                  fontSize: 13,
                  color: "red",
                  marginTop: 6,
                }}
              >
                {errMessage.cateSlug}
              </p>
            )}
          </div>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          variant="secondary"
          onClick={() => handleCloseModal(false)}
          disabled={isSendForm}
        >
          Đóng
        </Button>
        <Button
          type="submit"
          form="edit-courseType-hook-form"
          variant="primary"
          disabled={isSendForm}
        >
          Cập nhật
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Schema = yup.object().shape({
  name: yup
    .string()
    .required("Không được bỏ trống")
    .test(
      "len",
      "Tên loại giải pháp không dài quá 255 kí tự",
      (val) => val.length >= 0 && val.length <= 255
    ),
});

export default CourseTypeEditModal;
