import { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { PRODUCT_URL, ACTIVITY_URL } from "../../routes/routerUrl";
import { ShopContext } from "../../utils/contextGlobal";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Style.css";

import ImageURL from "../../components/ImageURL";
import Loading from "../../components/Loading";
import NoData from "../../components/NoData";
import News from "./News";
import ContactWithUs from "./ContactWithUs";

// image assets
import TopBanner from "../../assets/image/top-banner.jpg";
import ImgMyKat from "../../assets/image/Banner_MyKat_2583.jpg";

const responsiveTitle = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 468 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 468, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const Section = ({ title, description, children }) => {
  return (
    <div style={{ margin: "5rem 0rem" }}>
      <div className="mb-5">
        <div className="title-line" />
        <div className="d-flex justify-content-center">
          <div
            className="title-text px-2 px-md-5"
            style={{
              width: "fit-content",
              backgroundColor: "white",
              marginTop: "-1.4rem",
            }}
          >
            {title}
          </div>
        </div>
        <div className="d-flex justify-content-center">{description}</div>
      </div>

      {children}
    </div>
  );
};

const FieldActivityItem = ({ item }) => {
  const { imgURL, postTitleVi, postSlug } = item;
  return (
    <Link to={`${ACTIVITY_URL}/${postSlug}`} className="field-activity-title">
      <div
        className="field-activity-title hover-zoom"
        style={{
          backgroundSize: "cover",
          backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}${imgURL})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: "100%",
          aspectRatio: "3/2",
        }}
      >
        <div className="field-activity-fill">
          <h1>{postTitleVi}</h1>
        </div>
      </div>
    </Link>
  );
};

const OutstandingProjectItem = ({ item }) => {
  const { imgPaths, proNameVi, proSlug } = item;
  return (
    <Link to={`${PRODUCT_URL}/${proSlug}`} className="field-activity-title">
      <div className="hover-zoom" style={{ position: "relative" }}>
        <div
          style={{
            backgroundSize: "cover",
            backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}${imgPaths[0]})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "100%",
            height: 400,
          }}
        >
          <div className="fill outstanding-project-border" />
          <div className="outstanding-project-fill">
            <h4 className="outstanding-project-title">{proNameVi}</h4>
          </div>
        </div>
      </div>
    </Link>
  );
};

const Home = () => {
  const shop = useContext(ShopContext);

  const [banners, setBanners] = useState([]);
  const [isBannerLoading, setBannerIsLoading] = useState(false);

  const [activites, setActivites] = useState([]);
  const [isActivitesLoading, setIsActivitesLoading] = useState(false);

  const [showContactWithUs, setShowContactWithUs] = useState(false);

  const [news, setNews] = useState([]);
  const [isNewsLoading, setIsNewsLoading] = useState(false);

  const [outstandingProjects, setOutstandingProjects] = useState([]);
  const [isOutstandingProjectsLoading, setIsOutstandingProjectsLoading] =
    useState(false);

  const handleCloseContactWithUs = () => setShowContactWithUs(false);
  const handleShowContactWithUs = () => setShowContactWithUs(true);

  useEffect(() => {
    document.title = "Trang chủ";
    getAllBanners();
    getAllCategories();
    getOutstandingProjects();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // API
  const getAllBanners = async () => {
    setBannerIsLoading(true);
    await axios
      .get(`/image/list`, {
        params: {
          isType: "iHome",
        },
      })
      .then((res) => {
        setBannerIsLoading(false);

        if (res.data.errCode === 0) {
          setBanners(res.data.images);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCategories = async () => {
    setIsActivitesLoading(true);
    setIsNewsLoading(true);
    await axios
      .get(`/category/list`, {
        params: {
          isType: "cPost",
          isTree: 1,
        },
      })
      .then(async (res) => {
        if (res.data.errCode === 0) {
          // Hoat dong
          const activitiesCategory = res.data.categories.find((element) => {
            return (
              element.cateSlug ===
              process.env.REACT_APP_ACTIVITIES_CATEROGY_SLUG
            );
          });
          if (activitiesCategory !== undefined) {
            await getAllActivities("", activitiesCategory.id);
          }
          setIsActivitesLoading(false);
          // Tin tuc
          const newsCategory = res.data.categories.find((element) => {
            return (
              element.cateSlug === process.env.REACT_APP_NEWS_CATEGORY_SLUG
            );
          });
          if (newsCategory !== undefined) {
            await getAllNews(newsCategory.id);
          }
          setIsNewsLoading(false);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllActivities = async (keyword, parentId) => {
    setIsActivitesLoading(true);
    await axios
      .get(`/post/list`, {
        params: {
          cateParentId: parentId,
          keyword: keyword,
        },
      })
      .then((res) => {
        setIsActivitesLoading(false);
        if (res.data.errCode === 0) {
          setActivites(res.data.posts);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllNews = async (parentId) => {
    setIsNewsLoading(true);
    await axios
      .get(`/post/list`, {
        params: {
          cateParentId: parentId,
          page: 1,
          limit: 6,
        },
      })
      .then((res) => {
        setIsNewsLoading(false);

        if (res.data.errCode === 0) {
          setNews(res.data.posts);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOutstandingProjects = async () => {
    setIsOutstandingProjectsLoading(true);
    await axios
      .get(`/product/list`, {
        params: {
          proPriority: "1",
        },
      })
      .then((res) => {
        setIsOutstandingProjectsLoading(false);

        if (res.data.errCode === 0) {
          setOutstandingProjects(
            res.data.products.map((product) => {
              return {
                ...product,
                imgPaths: product.imgPaths ? product.imgPaths.split(",") : [],
              };
            })
          );
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // API

  return (
    <>
      <ContactWithUs
        show={showContactWithUs}
        handleClose={handleCloseContactWithUs}
      />
      {/* Top Banner */}
      {isBannerLoading ? (
        <Loading />
      ) : (
        <div
          style={{
            position: "relative",
            width: "100%",
            aspectRatio: "3/1",
          }}
        >
          <div
            style={{
              width: "100%",
              aspectRatio: "3/1",
              position: "absolute",
            }}
          >
            <Carousel
              responsive={responsiveTitle}
              autoPlay
              infinite={true}
              removeArrowOnDeviceType={["mobile"]}
            >
              {banners.map((item) => {
                return (
                  <ImageURL
                    key={item.id}
                    style={{
                      width: "100%",
                      aspectRatio: "3/1",
                      backgroundSize: "cover",
                    }}
                    imageURL={item.imgURL}
                  />
                );
              })}
            </Carousel>
          </div>
          <div className="fill top-banner-fill">
            {shop?.headerScript ? (
              <>
                <h1 className="white-text d-flex justify-content-center">
                  <strong>{JSON.parse(shop.headerScript)?.h1}</strong>
                </h1>
                <h3
                  className="white-text d-flex justify-content-center"
                  style={{ textAlign: "center" }}
                >
                  <strong>{JSON.parse(shop.headerScript)?.h2}</strong>
                </h3>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
      {/* Lĩnh vực hoạt động */}
      <Container className="container-wrapper justify-content-center">
        <Section
          title="Lĩnh vực hoạt động"
          description="Công trình của công ty thực hiện trên khắp cả nước luôn là những công trình nổi bật, phức tạp, đòi hỏi công nghệ xây dựng cập nhật nhất hiện nay."
        >
          {isActivitesLoading ? (
            <Loading />
          ) : activites?.length > 0 ? (
            <Row>
              {activites.map((item) => {
                return (
                  <Col xs="12" md="6" className={"py-3"}>
                    <FieldActivityItem item={item} />
                  </Col>
                );
              })}
            </Row>
          ) : (
            <NoData />
          )}
        </Section>
      </Container>

      {/* Những công trình nổi bật */}
      <Container className="container-wrapper justify-content-center">
        <Section
          title="Những công trình nổi bật"
          description="Công trình của công ty thực hiện trên khắp cả nước luôn là những công trình nổi bật, phức tạp, đòi hỏi công nghệ xây dựng cập nhật nhất hiện nay."
        >
          {isOutstandingProjectsLoading ? (
            <Loading />
          ) : outstandingProjects?.length > 0 ? (
            <Row>
              {outstandingProjects.map((item, index) => {
                const mod = index % 4;
                return (
                  <Col
                    xs="12"
                    md={mod === 0 || mod === 3 ? "8" : "4"}
                    className={"py-3"}
                  >
                    <OutstandingProjectItem item={item} />
                  </Col>
                );
              })}
            </Row>
          ) : (
            <NoData />
          )}
        </Section>
      </Container>

      {/* Hợp tác cùng Mỹ Kat */}
      <div
        style={{
          backgroundSize: "cover",
          backgroundImage: `url(${TopBanner})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="delta-fill">
          <Container className="container-wrapper justify-content-center">
            <Row
              className="align-self-center"
              style={{
                paddingTop: "100px",
                paddingBottom: "100px",
              }}
            >
              <Col xs="12" md="6" className={"py-2 align-self-center"}>
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      backgroundSize: "cover",
                      backgroundImage: `url(${ImgMyKat})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      width: "100%",
                      height: 400,
                    }}
                  >
                    <div className="fill delta-image-background" />
                  </div>
                </div>
              </Col>
              <Col xs="12" md="6" className={"py-2 align-self-center"}>
                <h1 className="white-text d-flex justify-content-center">
                  Hợp tác cùng Mỹ Kat
                </h1>
                <p className="white-text">
                  Chúng tôi liên kết với rất nhiều các đối tác trong cả nước,
                  tận dụng hiệu quả mạng lưới các nhà cung ứng, thầu phụ chuyên
                  môn cao để đạt được mục tiêu công việc một cách hiệu quả nhất.
                </p>
                <p className="white-text">
                  Mỹ Kat mong muốn xây dựng những mối quan hệ hợp tác chiến lược
                  để cùng nhau tiến xa hơn, tiến mạnh mẽ hơn. Hãy nhanh chóng
                  tham gia cùng với chúng tôi !
                </p>
                <div className="d-flex">
                  <Link
                    className="button white is-outline lowercase"
                    style={{
                      borderRadius: "99px",
                    }}
                    onClick={handleShowContactWithUs}
                  >
                    <span>Giới thiệu năng lực ngay</span>
                    <i class="icon-angle-right" aria-hidden="true" />
                  </Link>
                  <Link
                    to="lien-he"
                    className="button white lowercase"
                    style={{
                      borderRadius: "99px",
                    }}
                  >
                    <span>Liên hệ Mỹ Kat</span>
                    <i class="icon-angle-right" aria-hidden="true" />
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      {/* Tin tức */}
      <Container className="container-wrapper justify-content-center">
        <Section
          title="Tin tức"
          description="Cập nhật những thông tin mới nhất"
        >
          {isNewsLoading ? (
            <Loading />
          ) : news?.length > 0 ? (
            <Row className={"px-2"}>
              {news.map((item, index) => {
                const { postTitleVi, time, imgURL, postDescVi, postSlug } =
                  item;
                const mod = index % 6;
                const isLarge = mod === 1 || mod === 5;
                return (
                  <Col xs="12" md={isLarge ? "6" : "3"} className={"py-2"}>
                    <News
                      imageUrl={imgURL}
                      title={postTitleVi}
                      url={postSlug}
                      time={time}
                      expcert={postDescVi}
                    />
                  </Col>
                );
              })}
            </Row>
          ) : (
            <NoData />
          )}
        </Section>
      </Container>

      {/* Bottom Banner */}
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "400px",
        }}
      >
        <div
          style={{
            backgroundSize: "cover",
            backgroundImage: `url(${TopBanner})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
        />
        <p className="fill bottom-banner-fill">
          {shop?.footerScript ? (
            <>
              <h1 className="white-text d-flex justify-content-center">
                <strong>{JSON.parse(shop.footerScript)?.h1}</strong>
              </h1>
              <h2 className="white-text d-flex justify-content-center">
                <strong>{JSON.parse(shop.footerScript)?.h2}</strong>
              </h2>
            </>
          ) : (
            ""
          )}
        </p>
      </div>
    </>
  );
};

export default Home;
