import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { Button, Container } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DocumentAddModal from "./DocumentAddModel";
import DocumentEditModal from "./DocumentEditModel";
import Icon from "../../../components/Icon";
import Loading from "../../../components/Loading";
import DataTables from "../../../components/DataTables";
import DateFormat from "../../../components/DateFormat";
import ImageURL from "../../../components/ImageURL";
import StudentDocumentModal from "./StudentDocumentModal";
import { ACCESS_PERMISSIONS } from "../../../constants";

import moment from "moment";
import "moment/locale/vi";
moment.locale("vi");

const Document = () => {
  const [documents, setDocuments] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showStudentModal, setShowStudentModal] = useState(false);
  const [documentId, setDocumentId] = useState(null);
  const [isAddModal, setIsAddModal] = useState(false);
  useEffect(() => {
    document.title = "Tài liệu";
    getAllDocuments();
  }, []);

  //#region API
  const getAllDocuments = async () => {
    setIsLoading(true);
    await axios
      .get(`/document-mk/list`)
      .then((res) => {
        setIsLoading(false);

        if (res.status === 200) {
          setDocuments(res.data.data);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
          type: "error",
          autoClose: 1000,
        });
        console.log(err);
      });
  };

  const deleteDocument = async (documentId) => {
    await axios
      .delete(`/document/destroy/${documentId}`)
      .then((res) => {
        if (res.status === 200) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });

          getAllDocuments("");
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //#endregion

  const handleDeleteDocument = (newId) => {
    swal({
      title: `Bạn muốn xóa tài liệu này`,
      text: ` Lưu ý: Hành động không thể khôi phục lại`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        deleteDocument(newId);
      }
    });
  };

  const handleCloseAddModal = (isReload) => {
    setShowAddModal(false);
    if (isReload) {
      getAllDocuments("");
    }
  };
  const handleShowAddModal = () => {
    setShowAddModal(true);
    setSelectedDocument();
  };

  const handleCloseEditModal = (isReload) => {
    setShowEditModal(false);
    if (isReload) {
      getAllDocuments("");
    }
  };
  const handleShowEditModal = (document) => {
    setShowEditModal(true);
    setSelectedDocument(document);
  };

  const columns = [
    {
      name: "Quyền truy cập",
      selector: (row) =>
        row.type === ACCESS_PERMISSIONS.VIP
          ? ACCESS_PERMISSIONS.VIP
          : row.type === ACCESS_PERMISSIONS.PREMIUM
            ? ACCESS_PERMISSIONS.PREMIUM
            : ACCESS_PERMISSIONS.NORMAL,
      wrap: true,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Ảnh",
      style: {
        display: "flex",
        justifyContent: "center",
      },
      selector: (row) => (
        <ImageURL
          style={{
            aspectRatio: "9/6",
            height: "4rem",
            backgroundSize: "cover",
          }}
          imageURL={`/${row.image}`}
        />
      ),
      wrap: true,
    },
    {
      name: "Tên tài liệu",
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Link tài liệu",
      selector: (row) => row.link,
      wrap: true,
    },
    {
      name: "Cập nhật",
      selector: (row) => row.updatedAt,
      sortable: true,
      cell: (row) => <DateFormat date={row.updatedAt} />
    },
    {
      name: "Phương thức",
      center: true,
      cell: (row) => (
        <>
          <Button
            size="sm"
            variant="warning me-2"
            type="button"
            onClick={() => handleShowEditModal(row)}
          >
            <Icon icon="pencil" />
          </Button>

          <Button
            size="sm"
            variant="danger me-2"
            type="button"
            onClick={() => handleDeleteDocument(row.id)}
          >
            <Icon icon="trash" />
          </Button>

          <Dropdown>
            <Dropdown.Toggle
              variant="success me-2"
              id="dropdown-basic"
              size="sm"
            >
              Thêm
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleShowStudentModal(row.id)}>
                Danh sách học viên
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleShowStudentAddModal(row.id)}>
                Thêm học viên
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>
      ),
    },
  ];
  const handleShowStudentModal = (documentId) => {
    setDocumentId(documentId);
    setIsAddModal(false);
    setShowStudentModal(true);
  };
  const handleCloseStudentModal = (isReload) => {
    setShowStudentModal(false);
    if (isReload) getAllDocuments();
  };

  const handleShowStudentAddModal = (documentId) => {
    setDocumentId(documentId);
    setIsAddModal(true);
    setShowStudentModal(true);
  };
  return (
    <Container fluid>
      <div
        style={{
          backgroundColor: "#26B59C",
          fontSize: "1.5rem",
          color: "white",
        }}
        className="text-center w-100 mb-3 py-3 mt-3"
      >
        Danh sách tài liệu
      </div>

      <div className="d-block d-md-flex justify-content-between mb-3">
        <div className="mt-3 mt-md-0">
          <Button
            variant="primary"
            className="mt-2 mt-md-0"
            onClick={() => handleShowAddModal()}
          >
            <Icon icon="plus" /> {"Thêm mới"}
          </Button>
        </div>
      </div>

      {isLoading ? (
        <Loading />
      ) : (
        <DataTables data={documents} columns={columns} />
      )}

      {showAddModal ? (
        <DocumentAddModal
          handleCloseModal={handleCloseAddModal}
          showModal={showAddModal}
        />
      ) : (
        ""
      )}

      {showEditModal ? (
        <DocumentEditModal
          handleCloseModal={handleCloseEditModal}
          showModal={showEditModal}
          selectedDocument={selectedDocument}
        />
      ) : (
        ""
      )}
      {showStudentModal ? (
        <StudentDocumentModal
          closeModal={handleCloseStudentModal}
          showModal={handleShowStudentModal}
          documentId={documentId}
          isAdd={isAddModal}
        />
      ) : (
        ""
      )}
    </Container>
  );
};

export default Document;
