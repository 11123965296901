import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

const ApplyModal = ({ show, handleClose }) => {
  return (
    <>
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Body>
          <Form>
            <h3
              className="d-flex justify-content-center"
              style={{
                color: "var(--primary-color)",
                fontSize: "26px",
                padding: "15px",
              }}
            >
              Tuyển dụng Mỹ Kat
            </h3>
            <div className="d-flex justify-content-center gap-4">
              <Form.Control
                name="ho_ten"
                type="text"
                placeholder="Họ và tên"
                className="mb-3 input"
              />
              <Form.Control
                name="ma_so_thue"
                type="text"
                className="mb-3 input"
                placeholder="Tình trạng hôn nhân"
              />
            </div>
            <div className="d-flex  justify-content-center gap-4">
              <Form.Control
                name="nguoi_dai_dien"
                type="text"
                className="mb-3 input"
                placeholder="Email"
              />
              <Form.Control
                name="so_dien_thoai"
                type="text"
                className="mb-3 input"
                placeholder="Chỗ ở hiện tại"
              />
            </div>
            <div className="d-flex  justify-content-center gap-4">
              <Form.Control
                name="dia_chi_cong_ty"
                type="text"
                className="mb-3 input"
                placeholder="Số điện thoại"
              />
              <Form.Control
                name="hang_muc_thi_cong"
                type="text"
                className="mb-3 input"
                placeholder="Mức lương mong muốn"
              />
            </div>
            <div className="d-flex  justify-content-center gap-4">
              <Form.Control
                name="khu_vuc_thi_cong"
                type="text"
                className="mb-3 input"
                placeholder="Ngày sinh"
              />
              <Form.Control
                name="doanh_thu"
                type="text"
                className="mb-3 input"
                placeholder="Thời gain có thể làm việc tại công ty"
              />
            </div>

            <div className="d-flex  justify-content-center gap-4">
              <Form.Control
                name="cac_du_an"
                type="text"
                className="mb-3 input"
                placeholder="Bạn có sẵn sàng công tác xa ?"
              />
              <Form.Control
                name="tong_thau"
                type="text"
                className="mb-3 input"
                placeholder="Chuyên ngành đào tạo"
              />
            </div>
            <Form.Control
              name="tong_thau"
              type="text"
              className="mb-3 input"
              placeholder="Tên trường Đại học/ Cao đẳng"
            />

            <Form.Control
              name="tong_thau"
              as={"textarea"}
              className="mb-3 input"
              rows={3}
              placeholder="Link CV ( Tải CV của bạn lên google drive sau đó chia sẻ, Quyền truy cập bạn chọn bất kì ai, sau đó nhấn sao chép liên kết và dán vào đây)"
            />
            <Form.Label>Trả lời câu hỏi : 10+40=?</Form.Label>
            <Form.Control name="tong_thau" type="text" className="mb-3 input" />
          </Form>
          <div className="d-flex  justify-content-center">
            <Form.Control
              type="submit"
              onClick={handleClose}
              className="submit-button mt-3 mb-3"
              value="GỬI ĐI"
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ApplyModal;
