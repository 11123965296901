import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { UserContext } from "../utils/contextGlobal";
import UserLoginModal from "../pages/user/Auth/UserLoginModal";
import Icon from "./Icon";

const handleLogout = () => {
  if (localStorage.getItem("user_access_token")) {
    localStorage.removeItem("user_access_token");
    window.location.href = "/";
  }
};

const DropdownMenu = () => {
  return (
    <Col className="menu-dropdown">
      <Row>
        <Link
          onClick={handleLogout}
          className="navbar-text text-uppercase menu-dropdown-item"
        >
          Đăng xuất
        </Link>
      </Row>
    </Col>
  );
};

const UserButton = ({ setShowSideBar }) => {
  const user = useContext(UserContext);

  const [showModal, setShowModal] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };
  if (!user) {
    return (
      <>
        {showModal && (
          <UserLoginModal show={showModal} handleClose={handleCloseModal} />
        )}
        <React.Fragment key={99}>
          <div className="mx-3" />
          <Link
            onClick={handleShowModal}
            className={"navbar-text text-uppercase"}
          >
            Đăng nhập
          </Link>
        </React.Fragment>
      </>
    );
  }
  if (setShowSideBar) {
    return (
      <>
        <div
          style={{
            fontWeight: 600,
            textTransform: "uppercase",
          }}
          className="d-flex justify-content-between align-items-center mb-2"
        >
          <Link style={{ textDecoration: "none" }}>
            <div className="navbar-text">{user.name}</div>
          </Link>
          <Icon
            icon={"right-from-bracket"}
            onClick={() => {
              setShowSideBar(false);
              handleLogout();
            }}
          />
        </div>
      </>
    );
  }
  return (
    <React.Fragment key={99}>
      <div className="mx-3" />
      <Link
        className={"navbar-text text-uppercase"}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {user.name}
        {isDropdownVisible && <DropdownMenu />}
      </Link>
    </React.Fragment>
  );
};

export default UserButton;
