import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Col, Container, Row, Breadcrumb, Button } from "react-bootstrap";
import Carousel from "react-multi-carousel";

import ListDocument from "../../../components/ListDocument";
import ImageURL from "../../../components/ImageURL";
import Icon from "../../../components/Icon";
import TextEditor from "../../../components/TextEditor";

import UserLoginModal from "../Auth/UserLoginModal";
import { UserContext } from "../../../utils/contextGlobal";
import { ACCESS_PERMISSIONS, DocumentOrCourse } from "../../../constants";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import "moment/locale/vi";
import RegisterDocumentOrCourse from "../RegisterDocumentOrCourse";
moment.locale("vi");

const responsiveDocument = {
  desktop: {
    breakpoint: { max: 3000, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const DocumentDetail = () => {
  let { slug } = useParams();

  const user = useContext(UserContext);

  const [selectedDocument, setSelectedDocument] = useState({ imgPaths: [] });
  const [relatedDocuments, setRelatedDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showUserLoginModal, setShowUserLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);

  const handleCloseUserLoginModal = () => setShowUserLoginModal(false);
  const handleShowUserLoginModal = () => setShowUserLoginModal(true);

  const handleCloseRegisterModal = () => setShowRegisterModal(false);
  const handleShowRegisterModal = () => setShowRegisterModal(true);

  useEffect(() => {
    getDocument(slug);
  }, [slug]); // eslint-disable-line react-hooks/exhaustive-deps

  // API
  const getDocument = async (slug) => {
    setIsLoading(true);

    await axios
      .get(`/document-mk/show`, {
        params: {
          slug: slug,
        },
      })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          const document = res.data.data;
          setSelectedDocument(document);
          getAllDocuments(document.categoryId, document.id);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllDocuments = async (cateId, documentId) => {
    await axios
      .get(`/document-mk/list`, {
        params: {
          categoryId: cateId,
          limit: 11,
        },
      })
      .then((res) => {
        setIsLoading(false);

        if (res.status === 200) {
          setRelatedDocuments(
            res.data.data.filter((document) => document.id !== documentId)
          );
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const ButtonVip = () => {
    return (
      <Button onClick={handleVipButtonClick} className="button-vip">
        <Icon icon="star" /> Dành cho thành viên VIP
      </Button>
    );
  };

  const ButtonPremium = ({ price }) => {
    return (
      <Button onClick={handleVipButtonClick} className="button-vip">
        <Icon icon="star" /> Đăng ký{" "}
        <CurrencyFormat
          thousandSeparator={true}
          value={price}
          suffix=" ₫"
          displayType="text"
        />
      </Button>
    );
  };

  const ButtonDownload = () => {
    return <Button onClick={handleDownload}>Download</Button>;
  };

  const ButtonDownloadOrVip = () => {
    const isDocumentVip =
      selectedDocument.type === ACCESS_PERMISSIONS.VIP ||
      selectedDocument.type === ACCESS_PERMISSIONS.PREMIUM;
    if (!isDocumentVip) {
      return <ButtonDownload />;
    }
    if (user) {
      const accessibleDocumentIds = user.accessibleDocs
        ? user.accessibleDocs?.split(",")
        : [];
      const canAccess = accessibleDocumentIds.find((id) => {
        return selectedDocument.id === Number(id);
      });
      if (canAccess) {
        return <ButtonDownload />;
      }
    }
    if (selectedDocument.type === ACCESS_PERMISSIONS.PREMIUM) {
      return <ButtonPremium price={selectedDocument.price} />;
    }
    return <ButtonVip />;
  };

  // API
  const handleDownload = () => {
    if (user) {
      const canDownloadUrl = selectedDocument.link.startsWith(
        "https://drive.google.com/file/d/"
      )
        ? `https://drive.usercontent.google.com/u/0/uc?id=${
            selectedDocument.link.replace("https://", "").split("/")[3]
          }&export=download`
        : selectedDocument.link;
      window.open(canDownloadUrl, "_blank");
    } else {
      handleShowUserLoginModal();
    }
  };

  const handleVipButtonClick = () => {
    if (user) {
      handleShowRegisterModal();
    } else {
      handleShowUserLoginModal();
    }
  };

  return (
    <>
      {showUserLoginModal && (
        <UserLoginModal
          show={showUserLoginModal}
          handleClose={handleCloseUserLoginModal}
        />
      )}

      {showRegisterModal && (
        <RegisterDocumentOrCourse
          type={DocumentOrCourse.Document}
          show={showRegisterModal}
          handleClose={handleCloseRegisterModal}
        />
      )}
      <Container className="container-wrapper mb-5">
        <Breadcrumb
          style={{
            backgroundColor: "var(--mute-bg-color)",
          }}
          className="p-3 pb-1 mb-3"
        >
          <Breadcrumb.Item active>Trang chủ</Breadcrumb.Item>
          <Breadcrumb.Item active>Tài liệu</Breadcrumb.Item>
          <Breadcrumb.Item active>{selectedDocument.name}</Breadcrumb.Item>
        </Breadcrumb>

        <Row className="mb-5">
          <Col xs="12" sm="12" md="10">
            <h4 className="mb-3 ">{selectedDocument.name}</h4>
            <i>
              <Icon icon="rotate" />
              {`Cập nhật: ${moment(selectedDocument.updatedAt * 1000).format(
                "dddd DD/MM/YYYY"
              )}`}
            </i>
            <hr></hr>
            <ButtonDownloadOrVip />
            <hr></hr>
            <TextEditor
              disable
              hideToolbar={true}
              defaultValue={selectedDocument.content}
              onChange={() => {}}
              style={{ border: "none" }}
            />
            <Carousel
              responsive={responsiveDocument}
              infinite={true}
              className="mb-3 mt-3"
            >
              <ImageURL
                style={{ height: "30rem" }}
                imageURL={`/${selectedDocument.image}`}
              />
            </Carousel>
          </Col>
        </Row>
        <hr></hr>
        {/* Tai lieu cung chuyen muc */}
        <div className="mt-5">
          <h3 className="mb-3 fst-italic">Các tài liệu khác</h3>

          <ListDocument
            showRow={4}
            documents={relatedDocuments}
            isLoading={isLoading}
            isPagiantion={false}
          />
        </div>
      </Container>
    </>
  );
};

export default DocumentDetail;
