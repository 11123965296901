import axios from "axios";
import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import Loading from "../../../components/Loading";
import ImageURL from "../../../components/ImageURL";
import DefaultBanner from "../../../assets/image/banner-qhdt.png";
import { ACTIVITY_URL } from "../../../routes/routerUrl";
import { Link } from "react-router-dom";

const Activity = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    document.title = "Giới thiệu";
    setImgUrl(DefaultBanner);
    getAllPosts(process.env.REACT_APP_ACTIVITIES_SUB_CATEROGY_SLUG, 1);
  }, []);

  const getAllPosts = async (cateSlug, page) => {
    setIsLoading(true);
    await axios
      .get(`/post/list`, {
        params: {
          cateSlug: cateSlug,
          page: page,
          limit: 10,
        },
      })
      .then((res) => {
        setIsLoading(false);
        if (res.data.errCode === 0) {
          setActivities(res.data.posts);
          if (res.data.posts.length > 0) {
            setImgUrl(res.data.posts[0].imgURL);
          }
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {imgUrl !== DefaultBanner ? (
        <ImageURL
          style={{
            height: "var(--top-banner-height)",
            backgroundSize: "cover",
          }}
          imageURL={imgUrl}
        />
      ) : (
        <div
          style={{
            height: "var(--top-banner-height)",
            backgroundSize: "cover",
            backgroundImage: `url(${imgUrl})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "100%",
          }}
        />
      )}
      <Container className="container-wrapper my-5">
        {isLoading ? (
          <Loading />
        ) : activities ? (
          <div>
            {activities.map((item) => {
              console.log(item.imgURL);
              return (
                <Row className="mb-5">
                  <Col xs="12" md="3">
                    <ImageURL
                      style={{
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        with: "100%",
                        height: "100%",
                      }}
                      imageURL={item.imgURL}
                    />
                  </Col>
                  <Col xs="12" md="9">
                    <Link
                      to={`/${ACTIVITY_URL}/${item.postSlug}`}
                      style={{ textDecoration: "none" }}
                    >
                      <h3>{item.postTitleVi}</h3>
                    </Link>
                    <div>{item.postDescVi}</div>
                  </Col>
                </Row>
              );
            })}
          </div>
        ) : (
          ""
        )}

      </Container>
    </div>
  );
};

export default Activity;
