import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "../../../components/Loading";
import NoData from "../../../components/NoData";
import DateFormat from "../../../components/DateFormat";
import ImageURL from "../../../components/ImageURL";

const Topic = () => {
  const [topics, setTopics] = useState([{ id: 0, posts: [] }]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = "Tin tức";
    getAllTopics();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // API
  const getAllTopics = async () => {
    setIsLoading(true);

    axios
      .get(`/category/list`, {
        params: {
          isType: "cPost",
          isTree: 1,
        },
      })
      .then((res) => {
        if (res.data.errCode === 0) {
          const newsCategory = res.data.categories.find((element) => {
            return (
              element.cateSlug === process.env.REACT_APP_NEWS_CATEGORY_SLUG
            );
          });
          if (newsCategory !== undefined) {
            getAllNews(
              newsCategory.children.filter(
                (category) =>
                  category.parentId !== null &&
                  category.parentId === newsCategory.id
              )
            );
          }
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllNews = async (topics) => {
    await axios
      .get(`/post/list`, {
        params: {
          cateId: topics.map((topic) => topic.id),
          limit: 20,
        },
      })
      .then((res) => {
        setIsLoading(false);

        if (res.data.errCode === 0) {
          setTopics(
            topics.map((topic) => {
              return {
                ...topic,
                posts: res.data.posts.filter(
                  (post) => post.categoryId === topic.id
                ),
              };
            })
          );
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // API

  return (
    <Container className="container-wrapper my-5">
      <Row>
        {isLoading ? (
          <Loading />
        ) : topics?.length > 0 ? (
          topics?.map((topic) => {
            return (
              <Col key={topic.id} xs="12" md="6">
                <Link to={`${topic.cateSlug}`} className="text-black">
                  <div className="topic-item-header d-flex justify-content-center align-items-center  mb-3">
                    <h2>{topic.cateNameVi}</h2>
                  </div>
                </Link>
                {topic.posts?.length > 0 ? (
                  <Card className="border-0">
                    <Link
                      to={`${topic.cateSlug}/${topic.posts[0]?.postSlug}`}
                      className="text-black"
                    >
                      <ImageURL
                        style={{
                          height: "15rem",
                          backgroundSize: "contain",
                        }}
                        className="my-3"
                        imageURL={topic.posts[0]?.imgURL}
                      />
                    </Link>
                    <Card.Body className="text-start">
                      <div
                        style={{ fontSize: "0.8rem" }}
                        className="text-end fst-italic"
                      >
                        <DateFormat date={topic.posts[0]?.createdAt} />
                      </div>
                      <Link
                        to={`${topic.cateSlug}/${topic.posts[0]?.postSlug}`}
                        className="text-black mt-3"
                      >
                        <Card.Title className="fw-bold text-uppercase">
                          {topic.posts[0]?.postTitleVi}
                        </Card.Title>
                      </Link>
                      <Card.Text className="mt-3">
                        {topic.posts[0]?.postDescVi}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                ) : (
                  <NoData />
                )}
              </Col>
            );
          })
        ) : (
          <NoData />
        )}
      </Row>
    </Container>
  );
};

export default Topic;
