import { useEffect } from "react";
import ListMedia from "../../../components/ListMedia";
import { Container } from "react-bootstrap";

const Media = () => {
	useEffect(() => {
		document.title = "Media";
	}, []);

	return (
		<Container className="container-wrapper my-5">
			<ListMedia isPagiantion={true} />
		</Container>
	);
};

export default Media;
