import axios from "axios";
import { useEffect, useState } from "react";

function UseCourses() {
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`/training-course/list`);
        const response = [{ id: 0, name: "Tất cả khóa học" }, ...res.data.data];
        setCourses(response);
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
  return { courses, isLoading, error };
}

export default UseCourses;
