import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import Select from "react-select";

import { Button, Col, Form, Modal, Row } from "react-bootstrap";

import yup from "../../../utils/yupGlobal";
import convertToSlug from "../../../utils/slugFormatter";
import Icon from "../../../components/Icon";

const addProductFormItems = [
  {
    id: 1,
    placeholder: "Tên dự án",
    name: "name",
    required: true,
  },
  {
    id: 2,
    placeholder: "Chủ đầu tư",
    name: "investor",
    required: false,
  },
  {
    id: 3,
    placeholder: "Giá trị",
    name: "scale",
    required: false,
  },
  {
    id: 4,
    placeholder: "Gói thầu",
    name: "description",
    required: false,
  },
  {
    id: 5,
    placeholder: "Khởi công",
    name: "time",
    required: false,
  },
  {
    id: 6,
    placeholder: "Địa điểm",
    name: "location",
    required: false,
  },
];

const ProductAddModal = ({ handleCloseModal, showModal }) => {
  const [slug, setSlug] = useState("");

  const [imageId, setImageId] = useState(0);
  const [productImages, setProductImages] = useState("");
  const [selectedCategory, setSelectedCategory] = useState();
  const [categoryOptions, setCategoryOptions] = useState([
    { value: 0, label: "" },
  ]);

  const [isSendForm, setIsSendForm] = useState(false);
  const [errMessage, setErrMessage] = useState({
    proSlug: undefined,
  });

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(Schema),
  });

  useEffect(() => {
    getAllOptionCategories();
  }, []);

  // Api
  const getAllOptionCategories = () => {
    axios
      .get(`/category/show`, {
        params: {
          isType: "cPro",
          isTree: 1,
        },
      })
      .then((res) => {
        if (res.data.errCode === 0) {
          const categoryOptions = [];
          const categories = res.data.categories[0].children;

          categories.map((category) => {
            categoryOptions.push(category);

            return category.children.map((categorySub) => {
              categoryOptions.push({
                ...categorySub,
                cateNameVi: "- " + categorySub.cateNameVi,
              });

              return categorySub.children.map((categorySenSub) => {
                categoryOptions.push({
                  ...categorySenSub,
                  cateNameVi: "- - " + categorySenSub.cateNameVi,
                });

                return categorySenSub;
              });
            });
          });

          setCategoryOptions(categoryOptions);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createNewProduct = async (data) => {
    setIsSendForm(true);

    var bodyFormData = new FormData();
    bodyFormData.append("categoryId", selectedCategory);
    bodyFormData.append("proNameVi", data.name);
    bodyFormData.append("proDescVi", data.description);
    bodyFormData.append("proInfoVi", data.investor);
    bodyFormData.append("proInfoEn", data.scale);
    bodyFormData.append("proContentVi", data.location);
    bodyFormData.append("proContentEn", data.time);
    bodyFormData.append("proSlug", slug);
    if (productImages.length !== 0) {
      productImages.forEach((imageFile) => {
        bodyFormData.append("proImages", imageFile.src);
      });
    }

    await axios({
      method: "post",
      url: "product/create",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setIsSendForm(false);

        if (res.data.errCode === 0) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });
          handleCloseModal(true);
        } else {
          setErrMessage(res.data.message);
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Api

  const handleUploadImage = (event) => {
    setImageId(imageId + 1);

    try {
      if (
        !event.target.files[0].type.match(/image.*/) ||
        event.target.files[0].size > 2097152
      ) {
        toast("File ảnh phải nhỏ hơn bằng 2MB", {
          type: "error",
          autoClose: 1000,
        });

        return;
      }

      setProductImages([
        ...productImages,
        {
          id: imageId,
          src: event.target.files[0],
        },
      ]);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={() => handleCloseModal(false)}
      backdrop="static"
    >
      <Modal.Header closeButton={!isSendForm}>
        <Modal.Title>Thêm dự án</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="mb-3">
          <Form.Label>
            Chọn danh mục<i className="text-danger">*</i>
          </Form.Label>
          <Select
            options={categoryOptions}
            placeholder={`Chọn danh mục`}
            noOptionsMessage={() => "Không có lựa chọn"}
            getOptionLabel={(option) => option.cateNameVi}
            getOptionValue={(option) => option.id}
            value={categoryOptions.filter(
              (category) => category.id === selectedCategory
            )}
            onChange={(choice) => {
              setSelectedCategory(choice.id);
            }}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
          />
        </div>

        <Form
          id="add-product-hook-form"
          onSubmit={handleSubmit((data) => {
            if (selectedCategory === undefined) {
              toast.error("Hãy chọn danh mục");
              return;
            }
            if (productImages.length === 0) {
              toast.error("Hãy thêm ảnh bối cảnh");
              return;
            }
            createNewProduct(data);
          })}
          className="mb-3"
        >
          {addProductFormItems?.map((formItem) => {
            return (
              <div key={formItem.id} className="mb-3">
                <Form.Floating>
                  <Form.Control
                    type="text"
                    placeholder={formItem.placeholder}
                    name={formItem.name}
                    {...register(formItem.name, {
                      onChange: () => {
                        setSlug(convertToSlug(getValues("name")));
                      },
                    })}
                  />
                  <Form.Label>
                    {formItem.placeholder}
                    {formItem.required ? <i className="text-danger">*</i> : ""}
                  </Form.Label>
                </Form.Floating>

                {errors[formItem.name] && (
                  <p
                    style={{
                      fontSize: 13,
                      color: "red",
                      marginTop: 6,
                    }}
                  >
                    {errors[formItem.name].message}
                  </p>
                )}
              </div>
            );
          })}

          <Row className="mb-3">
            <Col xs="12" md="12" className="mb-3 mb-md-0">
              <Form.Floating>
                <Form.Control
                  type="text"
                  placeholder="Slug"
                  name="slug"
                  disabled
                  value={slug}
                />

                <Form.Label>
                  Slug
                  <i className="text-danger">*</i>
                </Form.Label>
              </Form.Floating>
              {errMessage.proSlug && (
                <p
                  style={{
                    fontSize: 13,
                    color: "red",
                    marginTop: 6,
                  }}
                >
                  {errMessage.proSlug}
                </p>
              )}
            </Col>
          </Row>
        </Form>

        {/* Images */}
        <Form.Label className="mb-3">
          Ảnh phối cảnh<i className="text-danger">*</i>
        </Form.Label>
        <div>
          <div className="d-flex justify-content-evenly flex-wrap">
            {productImages.length !== 0
              ? productImages?.map((imageItem) => {
                  return (
                    <div
                      key={imageItem.id}
                      style={{
                        height: 450,
                        width: 300,
                        backgroundImage: `url("${URL.createObjectURL(
                          imageItem.src
                        )}")`,
                        backgroundSize: "cover",
                      }}
                      className="image-default position-relative mb-3"
                    >
                      <button
                        style={{
                          height: "1.5rem",
                          width: "1.5rem",
                          border: "none",
                        }}
                        className="position-absolute top-0 start-100 translate-middle badge rounded-circle bg-danger"
                        onClick={() => {
                          setProductImages(
                            productImages.filter(
                              (item) => item.id !== imageItem.id
                            )
                          );
                        }}
                      >
                        <Icon icon="x" />
                      </button>
                    </div>
                  );
                })
              : ""}
          </div>

          <input
            type="file"
            accept="image/x-png,image/gif,image/jpeg"
            onChange={(e) => handleUploadImage(e)}
          />
          <div>
            <div className="d-inline fw-bold">Note: </div>
            Chọn ảnh với tỉ lệ 900:600 để được chất lượng tốt nhất
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          variant="secondary"
          onClick={() => handleCloseModal(false)}
          disabled={isSendForm}
        >
          Đóng
        </Button>
        <Button
          type="submit"
          form="add-product-hook-form"
          variant="primary"
          disabled={isSendForm}
        >
          Lưu
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Schema = yup.object().shape({
  name: yup
    .string()
    .required("Không được bỏ trống")
    .test(
      "len",
      "Tiêu đề không dài quá 255 kí tự",
      (val) => val.length >= 0 && val.length <= 255
    ),
});

export default ProductAddModal;
