import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Col, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";

import ListDocument from "../../../components/ListDocument";
// import Select from "react-select";

const itemsPerPage = 16;

const Document = () => {
  let { typeId } = useParams();

  const [documentTypes, setDocumentTypes] = useState([
    { id: 0, name: "Tất cả", slug: "tat-ca" },
  ]);

  const [documents, setDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = "Tài liệu";
    getAllDocuments(typeId ?? 0, "", 1);
    getAllDocumentTypes();
  }, [typeId]); // eslint-disable-line react-hooks/exhaustive-deps

  // API
  const getAllDocuments = async (id, keyword, page) => {
    setIsLoading(true);
    await axios
      .get(`/document-mk/list`, {
        params: {
          categoryId: id === 0 ? 0 : id,
          page: page,
          limit: itemsPerPage,
          keyword: keyword,
        },
      })
      .then((res) => {
        setIsLoading(false);

        if (res.status === 200) {
          setTotalItems(res.data.itemCount);
          setDocuments(res.data.data);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllDocumentTypes = async () => {
    setIsLoading(true);
    axios
      .get(`/category-mk/list`)
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.status === 200) {
          setDocumentTypes(
            [{ id: 0, name: "Tất cả", slug: "tat-ca" }].concat(
              res.data.data.filter(
                (category) =>
                  category.type === process.env.REACT_APP_CATEGORY_TYPE_DOCUMENT
              )
            )
          );
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // API
  return (
    <Container className="container-wrapper">
      <div className="d-block d-md-flex justify-content-between my-3">
        <h3>
          {typeId
            ? documentTypes.find((element) => element.id == typeId)?.name
            : "Tất cả tài liệu"}
        </h3>
      </div>

      <ListDocument
        showRow={4}
        documents={documents}
        isLoading={isLoading}
        itemsCount={totalItems}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isPagiantion={false}
      />
    </Container>
  );
};

export default Document;
