import { Button, Container, Form } from "react-bootstrap";
import DataTables from "../../../components/DataTables";

import Icon from "../../../components/Icon";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DateFormat from "../../../components/DateFormat";
import ImageURL from "../../../components/ImageURL";

import swal from "sweetalert";

import ProductAddModal from "./ProductAddModal";
import ProductEditModal from "./ProductEditModal";
import Loading from "../../../components/Loading";

const Product = () => {
  const [products, setProducts] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    getAllProducts("");
  }, []);

  // Api
  const getAllProducts = async (keyword) => {
    setIsLoading(true);
    await axios
      .get(`/product/show`, {
        params: { keyword: keyword },
      })
      .then((res) => {
        setIsLoading(false);

        if (res.data.errCode === 0) {
          setProducts(
            res.data.products.map((product) => {
              return {
                ...product,
                imgPaths: product.imgPaths ? product.imgPaths.split(",") : [],
              };
            })
          );
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteProduct = async (productId) => {
    await axios
      .delete(`/product/destroy`, {
        params: {
          id: productId,
        },
      })
      .then((res) => {
        if (res.data.errCode === 0) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });

          getAllProducts("");
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateHighlight = async (product) => {
    setIsLoading(true);

    var bodyFormData = new FormData();
    bodyFormData.append("id", product.id);
    bodyFormData.append("shopId", product.shopId);
    bodyFormData.append(
      "isHighlight",
      product.proPriority === null || product.proPriority === 0 ? 1 : 0
    );

    await axios({
      method: "put",
      url: "product/update-highlight",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setIsLoading(false);

        if (res.data.errCode === 0) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });
          getAllProducts();
        } else {
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Api

  const handleDeleteProduct = (productId) => {
    swal({
      title: `Bạn muốn xóa dự án này`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        deleteProduct(productId);
      }
    });
  };

  const handleCloseAddModal = (isReload) => {
    setShowAddModal(false);
    if (isReload) {
      getAllProducts("");
    }
  };
  const handleShowAddModal = () => {
    setShowAddModal(true);
    setSelectedProduct();
  };

  const handleCloseEditModal = (isReload) => {
    setShowEditModal(false);
    if (isReload) {
      getAllProducts("");
    }
  };
  const handleShowEditModal = (productId) => {
    setShowEditModal(true);
    setSelectedProduct(productId);
  };

  const handleUpdateHighlightProduct = (product) => {
    const title =
      product.proPriority === null || product.proPriority === 0
        ? "Bạn muốn đặt dự án này làm dự án nổi bật"
        : "Bạn muốn bỏ dự án này khỏi danh sách nổi bật";
    swal({
      title: title,
      icon: "warning",
      buttons: ["Đóng", "Dồng ý"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        updateHighlight(product);
      }
    });
  };

  const columns = [
    {
      name: "Nổi bật",
      selector: (row) => row.proPriority,
      center: true,
      sortable: true,
      cell: (row) => <>
        {row.proPriority === null || row.proPriority === 0 ? (
          <Button
            className="text-muted"
            variant="link"
            type="button"
            onClick={() => handleUpdateHighlightProduct(row)}
            title="Chọn làm dự án nổi bật"
            style={{ textDecoration: 'unset' }}
          >
            &#9744;
          </Button>
        ) : (
          <Button
            className="text-success"
            size="sm"
            variant="link"
            type="button"
            onClick={() => handleUpdateHighlightProduct(row)}
            title="Bỏ khỏi dự án nổi bật"
          >
            <Icon icon="square-check" />
          </Button>
        )}
      </>
    },
    {
      name: "Hình ảnh",
      style: {
        display: "flex",
        justifyContent: "center",
      },
      selector: (row) => (
        <div className="d-flex justify-content-centers">

          <ImageURL
            style={{
              aspectRatio: "9/6",
              height: "4rem",
              backgroundSize: "cover",
            }}
            imageURL={row.imgPaths[0]}
          />
        </div>
      ),
    },
    {
      name: "Tên dự án",
      selector: (row) => row.proNameVi,
      wrap: true,
      sortable: true,
    },
    {
      name: "Cập nhật",
      selector: (row) => row.updatedAt,
      sortable: true,
      cell: (row) => <DateFormat date={row.updatedAt} />,
    },
    {
      name: "Phương thức",
      style: {
        display: "flex",
        justifyContent: "center",
      },
      selector: (row) => (
        <>
          <Button
            size="sm"
            variant="warning me-2"
            type="button"
            onClick={() => handleShowEditModal(row.id)}
            title="Chi tiết"
          >
            <Icon icon="pencil" />
          </Button>

          <Button
            size="sm"
            variant="danger me-2"
            type="button"
            onClick={() => handleDeleteProduct(row.id)}
            title="Xóa"
          >
            <Icon icon="trash" />
          </Button>

        </>
      ),
    },
  ];

  return (
    <Container fluid>
      <div
        style={{
          backgroundColor: "#26B59C",
          fontSize: "1.5rem",
          color: "white",
        }}
        className="text-center w-100 mb-3 py-3 mt-3"
      >
        Dự án
      </div>

      <div className="d-block d-md-flex justify-content-between mb-3">
        <Form
          className="d-flex"
          onSubmit={(e) => {
            e.preventDefault();
            getAllProducts(keyword);
          }}
        >
          <Form.Control
            type="search"
            placeholder={"Nhập tên dự án"}
            className="me-2"
            aria-label="Search"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
          <Button variant="outline-secondary" type="submit">
            <Icon icon="magnifying-glass" />
          </Button>
        </Form>
        <div className="mt-3 mt-md-0">
          <Button
            variant="primary"
            className="mt-2 mt-md-0"
            onClick={() => handleShowAddModal()}
          >
            <Icon icon="plus" /> Thêm mới
          </Button>
        </div>
      </div>

      {isLoading ? (
        <Loading />
      ) : (
        <DataTables data={products} columns={columns} />
      )}

      {showAddModal ? (
        <ProductAddModal
          handleCloseModal={handleCloseAddModal}
          showModal={showAddModal}
        />
      ) : (
        ""
      )}

      {showEditModal ? (
        <ProductEditModal
          handleCloseModal={handleCloseEditModal}
          showModal={showEditModal}
          selectedProduct={selectedProduct}
        />
      ) : (
        ""
      )}
    </Container>
  );
};

export default Product;
