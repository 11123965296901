import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "../../../utils/yupGlobal";
import { toast } from "react-toastify";

import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";

import TextEditor from "../../../components/TextEditor";
import convertToSlug from "../../../utils/slugFormatter";
import { ACCESS_PERMISSIONS } from "../../../constants";
import Icon from "../../../components/Icon";

const editCourseFormItems = [
  {
    id: 1,
    title: "Tên khóa học",
    placeholder: "Nhập tên khóa học",
    name: "title",
  },
];

const CourseEditModal = ({ handleCloseModal, showModal, selectedCourse }) => {
  const [slug, setSlug] = useState("");
  const [content, setContent] = useState("");
  const [isSendForm, setIsSendForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errMessage, setErrMessage] = useState({
    postSlug: undefined,
  });
  const [selectedType, setSelectedType] = useState();
  const [courseTypes, setCourseTypes] = useState([{ id: 0, cateNameVi: "" }]);
  const [selectedAccessType, setSelectedAccessType] = useState(
    ACCESS_PERMISSIONS.NORMAL
  );
  const accessTypes = [
    { id: 1, name: ACCESS_PERMISSIONS.NORMAL },
    { id: 2, name: ACCESS_PERMISSIONS.VIP },
  ];

  const [bannerImage, setBannerImage] = useState("");

  const {
    register,
    reset,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(Schema),
  });

  useEffect(() => {
    getAllCourseTypes();
    getDetailCourse();
    console.log();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Api
  const getAllCourseTypes = async () => {
    setIsLoading(true);
    axios
      .get(`/category-mk/list`)
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          const listTypes = [];
          const courseTypes = res.data.data.filter(
            (category) =>
              category.type === process.env.REACT_APP_CATEGORY_TYPE_COURSE
          );
          courseTypes.map((type) => {
            listTypes.push(type);
            return type.children.map((categorySub) => {
              listTypes.push({
                ...categorySub,
                name: "- " + categorySub.name,
              });
            });
          });
          setCourseTypes(listTypes);
          setSelectedType(selectedCourse.categoryId);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDetailCourse = async () => {
    reset({
      title: selectedCourse.name,
    });
    setContent(selectedCourse.description);
    setSlug(selectedCourse.slug);
    setSelectedAccessType(selectedCourse.type ?? ACCESS_PERMISSIONS.NORMAL);

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/${selectedCourse.image}`
    );
    const blob = await response.blob();
    const file = new File([blob], "image.jpg", {
      type: blob.type,
    });
    setBannerImage(file);
  };

  const updateCourse = async (data) => {
    setIsSendForm(true);

    var bodyFormData = new FormData();

    bodyFormData.append("courseId", selectedCourse.id);
    bodyFormData.append("categoryId", selectedType);
    bodyFormData.append("name", data.title);
    bodyFormData.append("description", content);
    bodyFormData.append("content", selectedCourse.content);
    bodyFormData.append("type", selectedAccessType);
    bodyFormData.append("postSlug", slug);
    bodyFormData.append("isStatus", "S1");
    bodyFormData.append("image", bannerImage);

    await axios({
      method: "put",
      url: "course-mk/update",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setIsSendForm(false);

        if (res.status === 200) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });
          handleCloseModal(true);
        } else {
          setErrMessage(res.data.message);
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
          type: "error",
          autoClose: 1000,
        });
        setIsSendForm(false);
        console.log(err);
      });
  };

  const handleUploadImage = (event) => {
    try {
      if (
        !event.target.files[0].type.match(/image.*/) ||
        event.target.files[0].size > 2097152
      ) {
        toast("File ảnh phải nhỏ hơn bằng 2MB", {
          type: "error",
          autoClose: 1000,
        });

        return;
      }

      setBannerImage(event.target.files[0]);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={() => handleCloseModal(false)}
      backdrop="static"
    >
      <Modal.Header closeButton={!isSendForm}>
        <Modal.Title>Cập nhật khóa học</Modal.Title>
      </Modal.Header>


      <Form
        id="edit-new-hook-form"
        onSubmit={handleSubmit((data) => {
          if (content.length <= 0) {
            toast("Vui lòng ghi mô tả khóa học", {
              type: "error",
              autoClose: 1000,
            });
            return;
          }
          updateCourse(data);
        })}
      >
        <Modal.Body>
          <Row >
            <Col md={6}>
              <div className="fw-bold mb-2">
                Loại khóa học
                <i className="text-danger">*</i>
              </div>
              <Select
                options={courseTypes}
                placeholder={`Chọn loại khóa học`}
                noOptionsMessage={() => "Không có lựa chọn"}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                value={courseTypes.filter((category) => category.id === selectedType)}
                onChange={(choice) => {
                  setSelectedType(choice.id);
                }}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                className="mb-3"
              />
            </Col>
            <Col md={6}>
              <div className="fw-bold mb-2">
                Quyền truy cập
                <i className="text-danger">*</i>
              </div>
              <Select
                options={accessTypes}
                placeholder={`Chọn quyền truy cập`}
                noOptionsMessage={() => "Không có lựa chọn"}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.name}
                value={accessTypes.filter((type) => type.name === selectedAccessType)}
                onChange={(choice) => {
                  setSelectedAccessType(choice.name);
                }}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                className="mb-3"
              />
            </Col>

            <Col md={12} className="mb-3">
              <Form.Label className="fw-bold">
                Tên khóa học<i className="text-danger">*</i>
              </Form.Label>

              <Form.Control
                placeholder="Tên khóa học"
                name={"title"}
                rows={3}
                {...register("title")}
              />
              {errors["title"] && (
                <p
                  style={{
                    fontSize: 13,
                    color: "red",
                    marginTop: 6,
                  }}
                >
                  {errors["title"].message}
                </p>
              )}
            </Col>

            <Col md={12} className="mb-3">
              <Form.Label>
                Slug<i className="text-danger">*</i>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Slug"
                name="slug"
                disabled
                value={slug}
              />
              {errMessage.newSlug && (
                <p
                  style={{
                    fontSize: 13,
                    color: "red",
                    marginTop: 6,
                  }}
                >
                  {errMessage.newSlug}
                </p>
              )}
            </Col>

            <Col md={12} className="mb-3">
              <Form.Label className="fw-bold">
                Mô tả<i className="text-danger">*</i>
              </Form.Label>
              <TextEditor
                setContents={content}
                onChange={(value) => setContent(value)}
                placeholder={"Viết mô tả về khóa học"}
                style={{ border: "1px solid #dadada", padding: 16 }}
              />
            </Col>

            <Col md={12}>
              <Form.Label className="mb-3 fw-bold">
                Ảnh
                <i className="text-danger">*</i>
              </Form.Label>
              <br></br>
              {bannerImage ? (
                <div className="d-flex justify-content-start">
                  <div
                    style={{
                      aspectRatio: "3/2",
                      backgroundImage: `url("${URL.createObjectURL(bannerImage)}")`,
                      backgroundSize: "cover",
                      width: "30%",
                    }}
                    className="image-default position-relative mb-3"
                  />
                </div>
              ) : (
                ""
              )}

              <input
                type="file"
                accept="image/x-png,image/gif,image/jpeg"
                onChange={(e) => handleUploadImage(e)}
              />
              <div>
                <div className="d-inline fw-bold">Note: </div>
                Chọn ảnh với tỉ lệ 1600:500 để được chất lượng tốt nhất.
              </div>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            type="button"
            variant="secondary"
            onClick={() => handleCloseModal(false)}
            disabled={isSendForm}
          >
            <Icon icon="xmark" /> Đóng
          </Button>
          <Button
            type="submit"
            form="edit-new-hook-form"
            variant="primary"
            disabled={isSendForm || isLoading}
          >
            <Icon icon="pencil" /> Cập nhật
          </Button>
        </Modal.Footer>
      </Form>
    </Modal >
  );
};

const Schema = yup.object().shape({
  title: yup
    .string()
    .required("Không được bỏ trống")
    .test(
      "len",
      "Tiêu đề không dài quá 255 kí tự",
      (val) => val.length >= 0 && val.length <= 255
    ),
  //   description: yup.string().required("Không được bỏ trống"),
});

export default CourseEditModal;
