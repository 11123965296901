import { Card, Col, Row } from "react-bootstrap";
import { EDUCATION_URL } from "../routes/routerUrl";
import { Link } from "react-router-dom";
import Loading from "./Loading";
import NoData from "./NoData";
import MyPagination from "./MyPagination";
import { ACCESS_PERMISSIONS } from "../constants";

import "./Style.css";
import ImageURL from "./ImageURL";

const ListCourse = ({
  showRow,
  isLoading,
  types,
  itemsCount,
  itemsPerPage,
  currentPage,
  setCurrentPage,
  isPagiantion,
}) => {
  return (
    <>
      {isLoading
        ? <Loading />
        : types?.length > 0
          ? (
            types?.map((type) => {
              return (
                <>
                  {type.type.cateParentId ? (
                    <h3
                      id={`course-${type.type.cateParentId}-${type.type.id}`}
                      className="mt-3"
                      style={{ scrollMargin: "var(--nav-height)" }}
                    >
                      {`- ${type.type.name}`}
                    </h3>
                  ) : (
                    <h3
                      id={`course-${type.type.id}`}
                      className="mt-3"
                      style={{ scrollMargin: "var(--nav-height)" }}
                    >
                      {type.type.name}
                    </h3>
                  )}
                  <Row>
                    {type.courses?.length > 0 ? (
                      type.courses?.map((course) => {
                        return (
                          <Col xs={12} sm={6} md={3} key={course.id} className="mb-3" >
                            <Link
                              to={`/${EDUCATION_URL}/${course.slug}`}
                              style={{
                                textDecoration: "none",
                                color: "black",
                              }}
                            >
                              <Card className="product-border">
                                <ImageURL
                                  style={{ height: "12rem" }}
                                  imageURL={course.image}
                                />

                                <Card.Body className="d-flex flex-column justify-content-between">
                                  <Card.Title>{course.name}</Card.Title>
                                </Card.Body>

                                <Card.Footer>
                                  {course.type === ACCESS_PERMISSIONS.VIP ? (
                                    <div
                                      className="px-4 py-1"
                                      style={{
                                        backgroundColor: "orange",
                                        color: "white",
                                        width: "fit-content",
                                        borderRadius: "16px",
                                      }}
                                    >
                                      Vip
                                    </div>
                                  ) : (
                                    <div
                                      className="px-4 py-1"
                                      style={{
                                        backgroundColor: "blue",
                                        color: "white",
                                        width: "fit-content",
                                        borderRadius: "16px",
                                      }}
                                    >
                                      Free
                                    </div>
                                  )}
                                </Card.Footer>
                              </Card>
                            </Link>
                          </Col>
                        );
                      })
                    ) : type.type.children ? (
                      ""
                    ) : (
                      <NoData />
                    )}
                  </Row>
                </>
              );
            })
          ) : (
            <NoData />
          )}

      {isPagiantion && (
        <MyPagination
          itemsCount={itemsCount}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          alwaysShown={false}
        />
      )}
    </>
  );
};

export default ListCourse;
