import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Breadcrumb, Button, Container, Form } from "react-bootstrap";
import TextEditor from "../../../components/TextEditor";
import ActivityAddModal from "./ActivityAddModal";
import ActivityEditModal from "./ActivityEditModal";
import Icon from "../../../components/Icon";
import Loading from "../../../components/Loading";
import DataTables from "../../../components/DataTables";
import DateFormat from "../../../components/DateFormat";
import ImageURL from "../../../components/ImageURL";

const Activity = () => {
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSendForm, setIsSendForm] = useState(false);
  const [postIntroId, setPostIntroId] = useState(null);
  const [masterIntroCateId, setMasterIntroCateId] = useState({});
  const [masterActiviesCateId, setMasterActiviesCateId] = useState({});
  const [bannerImage, setBannerImage] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [activites, setActivites] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState(0);

  useEffect(() => {
    document.title = "Lĩnh vực hoạt động";
    getAllCategories();
  }, []);

  //#region API
  const getAllCategories = async () => {
    setIsLoading(true);
    await axios
      .get(`/category/show`, {
        params: {
          isType: "cPost",
          isTree: 1,
        },
      })
      .then(async (res) => {
        if (res.data.errCode === 0) {
          // Introduction
          // const introCategory = res.data.categories.find((element) => {
          //   return (
          //     element.cateSlug === process.env.REACT_APP_INTRO_CATEROGY_SLUG
          //   );
          // });
          // if (introCategory === undefined) {
          //   await createMasterIntroCategory();
          // } else {
          //   setMasterIntroCateId(introCategory.id);
          //   getPostIntro();
          // }
          // ACtivities
          const activitiesCategory = res.data.categories.find((element) => {
            return (
              element.cateSlug ===
              process.env.REACT_APP_ACTIVITIES_CATEROGY_SLUG
            );
          });
          if (activitiesCategory === undefined) {
            createMasterActivitiesCategory();
          } else {
            setMasterActiviesCateId(activitiesCategory.id);
            getAllActivities("", activitiesCategory.id);
          }
          setIsLoading(false);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createMasterIntroCategory = async () => {
    var bodyFormData = new FormData();
    bodyFormData.append("cateNameVi", "intro");
    bodyFormData.append("cateSlug", process.env.REACT_APP_INTRO_CATEROGY_SLUG);
    bodyFormData.append("isType", "cPost");

    await axios({
      method: "post",
      url: "category/create",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(async (res) => {
        if (res.data.errCode === 0) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });
          await getAllCategories();
        } else {
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPostIntro = () => {
    setIsLoading(true);
    axios
      .get(`/post/list`, {
        params: {
          postSlug: process.env.REACT_APP_ACTIVITY_POST_SLUG,
        },
      })
      .then(async (res) => {
        setIsLoading(false);

        if (res.data.errCode === 0) {
          if (res.data.post !== null) {
            setPostIntroId(res.data.post.id);
            setContent(res.data.post.postContentVi);
            const response = await fetch(
              `${process.env.REACT_APP_BACKEND_URL}${res.data.post.imgURL}`
            );
            const blob = await response.blob();
            const file = new File([blob], "image.jpg", {
              type: blob.type,
            });
            setBannerImage(file);
          }
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateIntro = async () => {
    setIsSendForm(true);

    var bodyFormData = new FormData();

    bodyFormData.append("id", postIntroId);
    bodyFormData.append("categoryId", masterIntroCateId);
    bodyFormData.append("postTitleVi", "Lĩnh vực hoạt động");
    bodyFormData.append("postDescVi", "");
    bodyFormData.append("postContentVi", content);
    bodyFormData.append("postSlug", process.env.REACT_APP_ACTIVITY_POST_SLUG);
    bodyFormData.append("isStatus", "S1");
    bodyFormData.append("postImage", bannerImage);

    await axios({
      method: "put",
      url: "post/update",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setIsSendForm(false);
        if (res.data.errCode === 0) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });
        } else {
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createIntro = async () => {
    setIsSendForm(true);

    var bodyFormData = new FormData();
    bodyFormData.append("categoryId", masterIntroCateId);
    bodyFormData.append("postTitleVi", "Lĩnh vực");
    bodyFormData.append("postDescVi", "");
    bodyFormData.append("postContentVi", content);
    bodyFormData.append("postSlug", process.env.REACT_APP_ACTIVITY_POST_SLUG);
    bodyFormData.append("isStatus", "S1");
    bodyFormData.append("postImage", bannerImage);

    await axios({
      method: "post",
      url: "post/create",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setIsSendForm(false);
        if (res.data.errCode === 0) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });
          getPostIntro();
        } else {
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createMasterActivitiesCategory = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("cateNameVi", "activities");
    bodyFormData.append(
      "cateSlug",
      process.env.REACT_APP_ACTIVITIES_CATEROGY_SLUG
    );
    bodyFormData.append("isType", "cPost");

    axios({
      method: "post",
      url: "category/create",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(async (res) => {
        if (res.data.errCode === 0) {
          getAllCategories();
        } else {
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllActivities = async (keyword, parentId) => {
    setIsLoading(true);
    await axios
      .get(`/post/show`, {
        params: {
          cateParentId: parentId,
          keyword: keyword,
        },
      })
      .then((res) => {
        setIsLoading(false);
        if (res.data.errCode === 0) {
          setActivites(res.data.posts);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteActivity = async (newIdId) => {
    await axios
      .delete(`/post/destroy`, {
        params: {
          id: newIdId,
        },
      })
      .then((res) => {
        if (res.data.errCode === 0) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });

          getAllActivities("", masterActiviesCateId);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //#endregion

  const handleUploadImage = (event) => {
    try {
      if (
        !event.target.files[0].type.match(/image.*/) ||
        event.target.files[0].size > 2097152
      ) {
        toast("File ảnh phải nhỏ hơn bằng 2MB", {
          type: "error",
          autoClose: 1000,
        });

        return;
      }

      setBannerImage(event.target.files[0]);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCloseAddModal = (isReload) => {
    setShowAddModal(false);
    if (isReload) {
      getAllActivities("", masterActiviesCateId);
    }
  };

  const handleShowAddModal = () => {
    setShowAddModal(true);
    setSelectedActivity();
  };

  const handleCloseEditModal = (isReload) => {
    setShowEditModal(false);
    if (isReload) {
      getAllActivities("", masterActiviesCateId);
    }
  };

  const handleShowEditModal = (newId) => {
    setShowEditModal(true);
    setSelectedActivity(newId);
  };

  const columns = [
    {
      name: "Ảnh header",
      style: {
        display: "flex",
        justifyContent: "center",
      },
      selector: (row) => (
        <ImageURL
          style={{
            aspectRatio: "900/600",
            height: "4rem",
            backgroundSize: "contain",
          }}
          imageURL={row.imgURL}
        />
      ),
      wrap: true,
    },
    {
      name: "Lĩnh vực",
      selector: (row) => row.postTitleVi,
      wrap: true,
    },
    {
      name: "Mô tả",
      selector: (row) => {
        return row.postDescVi;
      },
      wrap: true,
    },
    {
      name: "Người đăng",
      selector: (row) => row.creatorName,
      wrap: true,
    },
    {
      name: "Cập nhật",
      selector: (row) => <DateFormat date={row.updatedAt} />,
    },
    {
      name: "Phương thức",
      style: {
        display: "flex",
        justifyContent: "center",
      },
      selector: (row) => (
        <>
          <Button
            size="sm"
            variant="warning me-2"
            type="button"
            onClick={() => handleShowEditModal(row.id)}
          >
            <Icon icon="pencil" />
          </Button>

          <Button
            size="sm"
            variant="danger"
            type="button"
            onClick={() => deleteActivity(row.id)}
          >
            <Icon icon="trash" />
          </Button>
        </>
      ),
    },
  ];

  return (
    <Container fluid>
      {showAddModal ? (
        <ActivityAddModal
          handleCloseModal={handleCloseAddModal}
          showModal={showAddModal}
        />
      ) : (
        ""
      )}
      {showEditModal ? (
        <ActivityEditModal
          handleCloseModal={handleCloseEditModal}
          showModal={showEditModal}
          selectedActivity={selectedActivity}
        />
      ) : (
        ""
      )}
      <div className="d-block d-md-flex justify-content-between mb-3">
        <Breadcrumb className="mt-2">
          <Breadcrumb.Item active>Tổng quan</Breadcrumb.Item>
          <Breadcrumb.Item active>Lĩnh vực hoạt động</Breadcrumb.Item>
        </Breadcrumb>
        <div className="mt-3">
          <Button
            variant="primary"
            className="mt-2 mt-md-0"
            onClick={handleShowAddModal}
          >
            <Icon icon="plus" /> {"Thêm lĩnh vực"}
          </Button>
        </div>
      </div>
      {/* Images */}
      {/* <div className="mb-3">
        <Form.Label className="mb-3 fw-bold">Ảnh header</Form.Label>
        <br></br>
        {bannerImage ? (
          <div className="d-flex justify-content-center">
            <div
              style={{
                aspectRatio: "1600/500",
                backgroundImage: `url("${URL.createObjectURL(bannerImage)}")`,
                backgroundSize: "cover",
                width: "100%",
              }}
              className="image-default position-relative mb-3"
            />
          </div>
        ) : (
          ""
        )}

        <input
          type="file"
          accept="image/x-png,image/gif,image/jpeg"
          onChange={(e) => handleUploadImage(e)}
          required
        />
        <div>
          <div className="d-inline fw-bold">Note: </div>
          Chọn ảnh với tỉ lệ 1600:500 để được chất lượng tốt nhất.
        </div>
      </div> */}
      {/* Button cập nhật ảnh header */}
      {/* <Form
        onSubmit={(e) => {
          e.preventDefault();
          if (postIntroId === null) {
            createIntro();
          } else {
            updateIntro();
          }
        }}
      >
        <Button
          type="submit"
          variant="primary"
          className="mt-3 mb-3"
          disabled={isSendForm || isLoading}
        >
          Cập nhật
        </Button>
      </Form> */}
      {/* End Images */}
      {/* Danh sách các lĩnh vực */}
      {isLoading ? (
        <Loading />
      ) : (
        <DataTables data={activites} columns={columns} />
      )}

      {/* TODO: Vì mỗi lĩnh vực có 1 bài riêng nên sẽ comment lại, nếu dùng chung 1 bài post thì hãy mở ra */}
      {/* <Form
        onSubmit={(e) => {
          e.preventDefault();
          if (postIntroId === null) {
            createIntro();
          } else {
            updateIntro();
          }
        }}
      >
        <Form.Label className="mt-3 fw-bold">Nội dung</Form.Label>
        <TextEditor
          setContents={content}
          onChange={(value) => setContent(value)}
          placeholder={"Nội dung"}
          style={{ border: "1px solid #dadada", padding: 16 }}
        />

        <Button
          type="submit"
          variant="primary"
          className="mt-3"
          disabled={isSendForm || isLoading}
        >
          Cập nhật
        </Button>
      </Form> */}
    </Container>
  );
};

export default Activity;
