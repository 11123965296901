import React from "react";
import Icon from "../../components/Icon";

import SidebarItem from "../../components/SidebarItem";
import UserButton from "../../components/UserButton";

const Sidebar = ({ setShowSideBar, showSideBar, shop, navbarItems }) => {
  return (
    <nav
      style={{
        fontSize: "0.9rem",
      }}
      className={`${showSideBar ? "sidebar-menu active" : "sidebar-menu"} p-4`}
    >
      <div className="pb-3 my-3 border-bottom">
        {navbarItems?.map((item) => {
          return (
            <SidebarItem
              item={item}
              setShowSideBar={setShowSideBar}
              depthLevel={0}
            />
          );
        })}
        <UserButton setShowSideBar={setShowSideBar} />
      </div>

      <div className="mt-4">
        <div className="mb-2">
          <Icon icon="phone" /> Số điện thoại: {shop.shopPhone}
          <div>
            <Icon icon="envelope" /> Email: {shop.shopEmail}
          </div>
        </div>
      </div>

      <div
        style={{ marginLeft: "-0.8rem" }}
        className="d-flex justify-content-between mt-3"
      ></div>
    </nav>
  );
};

export default Sidebar;
