import React from "react";
import { Link } from "react-router-dom";
import Icon from "../../components/Icon";
import UserProfile from "../../components/UserProfile";
import Logo from "../../assets/image/logo.png";

const Navbar = () => {
  const sidenavToggle = (event) => {
    document
      .getElementById("sb-nav-fixed")
      .classList.toggle("sb-sidenav-toggled");
  };

  return (
    <nav className="sb-topnav navbar navbar-expand">
      <Link className="navbar-brand ps-3" to="/admin">
        <img src={Logo} alt="Logo Mỹ Kat" style={{ height: "3rem" }} />
      </Link>

      <button
        style={{
          backgroundColor: "#145f38",
          fontSize: "1rem",
          borderRadius: 4,
          color: "white",
          border: "none",
        }}
        className="order-1 order-lg-0 me-2"
        onClick={() => sidenavToggle()}
      >
        <Icon id="sidebarToggle" icon="bars" />
      </button>

      <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0"></form>

      <UserProfile />
    </nav>
  );
};

export default Navbar;
