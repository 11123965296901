import { Link } from "react-router-dom";

import "./Style.css";

import moment from "moment";
import "moment/locale/vi";
moment.locale("vi");

const News = ({ title, time, expcert, imageUrl, url }) => {
  return (
    <div style={{ position: "relative" }} className="link-news">
      <div
        style={{
          backgroundSize: "cover",
          backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}/${imageUrl})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          height: "250px",
        }}
      >
        <div className="news-fill fill">
          <div className="news-title">{title}</div>
        </div>
        <Link to={`tin-tuc/tat-ca/${url}`} className="fill fig-link-news">
          <div
            style={{
              position: "relative",
            }}
          >
            <div className="fig-hover-news-title">{title}</div>
            <div className="fig-hover-news-time">
              {moment(time).format("DD.MM.YYYY")}
            </div>
            <div className="fig-hover-news-expcert">{expcert}</div>
          </div>
        </Link>
      </div>
    </div>
  );
};
export default News;
